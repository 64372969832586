import React, {useState} from 'react'
import {Accordion, Button} from 'react-bootstrap';
import {RuleRow} from "../../DetailUtility/RuleRow";
import {saveCustomRule} from "../../../api_methods";
import {notify} from "../../../../utils/index_tagging_tool";
import {Card, CardHeader} from "reactstrap";
import _ from 'lodash'


export default function DimensionsList(
  {
    dimensions,
    badgeWidth= 60,
    tagWidth= 200,
    regexWidth= 300,
    refreshRules,
  }) {
  /**
   * List of rules, divided in dimensions.
   * All changes will be set for review and then saved with the dedicated button for each dimension.
   */
  const [loading, setLoading] = useState(false)

  const saveRule = async (rule, dim) => {
    const data = {}
    data['headers'] = {
      save: true,
      dim_pk: dim,
    }

    await saveCustomRule(rule.id, data)
  }

  const saveRules = async (dim) => {
    setLoading(true)
    await Promise.all(
      dim.rules.map(async (rule) => {
        // Scorro i selezionati e cancello tutto
        if (rule.id !== -1) {
          try {
            await saveRule(rule, dim.dimension_id)
          } catch (e) {
            notify(e.message, 'error')
          }
        }
      })
    )
    setLoading(false)
    refreshRules()
  }

  return (
    <div className="py-2 px-3 my-4">
      {dimensions.map((d, i) => (
        <Accordion key={'accordion' + i}>
          <Card key={'card' + i}>
            <CardHeader>
              <Accordion.Toggle as={Button} variant="link" eventKey={d.dimension + i} className="w-100 text-left shadow-none">
                {d.dimension}
              </Accordion.Toggle>
            </CardHeader>

            <Accordion.Collapse eventKey={d.dimension + i} key={i}>
              <div className="pb-2 px-3 my-4">
                <div className="pb-4 d-flex justify-content-center">
                  {loading ? <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                    : <Button variant="success" onClick={() => saveRules(d)}>Salva Regole</Button>}
                </div>
                <div
                  className="d-flex align-items-center"
                >
                  <div style={{ width: `${tagWidth}px`}} className="pl-1">
                    <b>Tag IT</b>
                  </div>
                  <div style={{ width: `${regexWidth}px` }} className="pl-1">
                    <b>Contiene</b>
                  </div>
                  <div style={{ width: `${regexWidth}px` }} className="pl-1">
                    <b>Non Contiene</b>
                  </div>
                </div>
                {_.orderBy(d.rules, 'rule.value').map((r, i) => (
                  <RuleRow
                    rule={r}
                    key={d.dimension + '-' + i}
                    regexWidth={regexWidth}
                    tagWidth={tagWidth}
                    badgeWidth={badgeWidth}
                    editRegex={false}
                    editTag={false}
                  />
                ))}
              </div>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ))}
    </div>
  )
}
