import React, { useState, useEffect, useContext } from 'react'
import { Form, Col } from 'react-bootstrap'
import { WButton } from '../../components/atoms'
import { AlertsFilterDataContext } from '../../HOC/alertsFiltersContext'
import {
	Card,
	CardHeader,
	CardBody,
	Row,
  FormGroup,
  Input
} from "reactstrap";
import Select from 'react-select'

export const AlertEventDetailFiltersForm = () => {
	const [url, setUrl] = useState('')

	const [alertType, setAlertType] = useState('')

  const [alertTypes, setAlertTypes] = useState([])
  const [alertTypesChoosed, setAlertTypesChoosed] = useState([])
  
	const [filtersParams, setFiltersParams] = useContext(AlertsFilterDataContext)

	const handleClearFilters = () => {
		setUrl('')
		setAlertType('')
    setAlertTypesChoosed([])
		setFiltersParams({})
	}

	const handleApplyFilters = () => {
		let params = {
			...(alertType.length > 0 && { alert_type: alertType }),
			...(url.length > 0 && { url }),
		}
    setFiltersParams(params)
	}

  useEffect(() => {
    setAlertTypes([
      'ALERT_TYPE_UNSPECIFIED',
      'INDEXING_CHANGED',
      'CANONICAL_CHANGED',
      'HTTP_STATUS_CHANGED',
      'BLOCKED_BY_ROBOTS',
      'TITLE1_IN_PAGE_CHANGED',
      'META_DESC_IN_PAGE_CHANGED',
      'H1_IN_PAGE_CHANGED',
      'INDEXABLE_NOT_IN_SITEMAP',
      'NOT_INDEXABLE_IN_SITEMAP',
    ])
  }, [])

  useEffect(() => {
    if (alertTypesChoosed.length > 0)
      setAlertType(alertTypesChoosed.reduce((acc, el) => acc + ',' + el))
    else
      setAlertType('')
  }, [alertTypesChoosed])
  

	return (
    <>
      {alertTypes.length > 0 && (
      <div className="header pb-4 pt-5 pt-md-8">
        <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <h1 className="mb-0">Filtra gli alert</h1>
            </CardHeader>
            <CardBody className="pt-0">
              <Form>
                <Row className="justify-content-center" style={{marginLeft:"80px", marginRight:"80px"}}>
                  <Col>
                    <FormGroup>
                      <label htmlFor="url" className="form-control-label">
                        Url
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="url"
                        type={'text'}
                        value={url}
                        onChange={event => {
                          setUrl(event.target.value)
                        }}
                      >
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label htmlFor="url" className="form-control-label">
                        Tipo di errore
                      </label>
                      <Select
                        options={
                          alertTypes.map((g) => {
                            return { label: g, value: g }
                          })
                        }
                        placeholder={'Seleziona un tipo di alert'}
                        className="single-select"
                        classNamePrefix="react-select"
                        value={
                          alertTypesChoosed.map((g) => {
                            return { label: g, value: g }
                          })
                        }
                        onChange={(e) => {
                          if (e.length === 0) 
                            setAlertTypesChoosed([])
                          else {
                            const values = e.map((el) => el.value)
                            setAlertTypesChoosed(values)
                          }
                        }}
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              <Row style={{display: "flex", justifyContent: "flex-end", marginRight: "92px"}}>
                <WButton label="Clear Filters" variant="light" onClick={handleClearFilters} />
                <WButton label="Apply" variant="primary" onClick={handleApplyFilters} />
              </Row>
            </CardBody>
          </Card>
        </div>
        </Row>
      </div>
      )}
    </>
	)
}
