import React, { useEffect, useState, useContext } from 'react'
import {Spinner} from 'react-bootstrap'
import {
	Col,
	FormGroup,
	Form,
	FormText,
	CardBody,
	Row,
	Button,
	Card,
	CardHeader,
	Input,
	Container
} from "reactstrap";

import { ToastContainer, toast } from 'react-toastify'
import { notify } from '../../utils/notify'
import TagsInput from '../../components/TagsInput'
import { api } from '../../utils/index'

const BulkIndexingForm = () => {
  const [file, setFiles] = useState(null)
  const [textUrls, setUrls] = useState([])
	const [loading, setLoading] = useState(false)
  const [secrets, setSecrets] = useState([])
  const [selectedSecret, setSelectedSecret] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()
    let formData = new FormData()
    formData.append('file', file)
    formData.append('textUrls', textUrls)
    formData.append('name', selectedSecret)

    setLoading(true)
    api
      .post('bulk-indexing/', formData, {
      })
      .then((response) => {
        if (typeof response.data === 'undefined') {
          notify(
            'Could not retrieve datas.',
            'success'
          )
          console.warn('Could not retrieve datas')
        }else{
          notify(
            "Indexing request sent.",
            'success'
          )
        }
        setUrls([])
        setFiles(null)
        setLoading(false)
      })
      .catch(err => {
        notify(
          err.response.data,
          'error'
        )
        setUrls([])
        setFiles(null)
        setLoading(false)
      })
  }
  
  const selectedUrls = urls => {
    setUrls(urls)
  }

  const validateUrls = urls => {
    const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;    
    return regex.test(urls);
  }

  useEffect(() => {
    api
		.get('get_indexing_secret/')
		.then(res => {
      for(let x in res.data){
        res.data[x] = res.data[x].split('/')[res.data[x].split('/').length-1]
      }
			setSecrets(res.data)
      setSelectedSecret(res.data[0])
		})
		.catch(err => console.error(err))
	}, [])

  return (
    <>
      {loading ? (
        <Spinner animation="grow" />
      ) : (
      <div className="col pb-5">
			<Card className="bg-secondary shadow">
					<CardHeader className="bg-white border-0">
						<Row style={{ marginLeft: "0px" }}>
							<Col md={10}>
								<h1 className="mb-0">Bulk Indexing</h1>
							</Col>
						</Row>
					</CardHeader>
        <CardBody>
        <Form onSubmit={handleSubmit} id="idea-form" action="">
          <Row>
            <Col md={6}>
              <FormGroup>
                <label htmlFor="file" style={{ display: 'block' }}>
                  File
                </label>
                <input
                  id={'file'}
                  type={'file'}
                  style={{ border: '0' }}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={event => {
                    setFiles(event.target.files[0])
                  }}
                />
                <FormText color="muted" align={'left'}>
                  Carica un file contenente i tuoi url. <br />
                  <b>Il file deve contenere almeno una colonna "Url"</b>
                </FormText>
              </FormGroup>
              <FormGroup>
                <label htmlFor="url" style={{ display: 'block' }}>
                  Url
                </label>
                <TagsInput
                  id={'urls'}
                  selectedTags={selectedUrls}
                  validator={validateUrls}
                  tags={textUrls}
                />
                <FormText color="muted" align={'left'}>
                  Inserisci eventuali url aggiuntivi. <br />
                  <b>Premi invio dopo ogni nuovo inserimento.</b>
                </FormText>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label htmlFor="location" style={{ display: 'block' }}>
                  Credenziali
                </label>
                <Input
                  type="select"
                  className="form-control-alternative"
                  id="location"
                  value={selectedSecret}
                  required
                  style={{ height: '44px' }}
                  onChange={event => {
                    setSelectedSecret(event.target.value)
                  }}
                >
                  {secrets.map(secret => {
                    return (
                      <option key={secret} value={secret}>
                        {secret}
                      </option>
                    )
                  })}
                </Input>
							</FormGroup>
            </Col>
          </Row>
          <Row style={{display: "flex", justifyContent: "flex-end", marginRight:"50px"}}>
            <Button color="info" size="lg" onClick={handleSubmit} >Submit Report</Button>
          </Row>
        </Form>
        </CardBody>
			</Card>
			</div>
      )}
      <ToastContainer />
    </>
  )
}

export default BulkIndexingForm
