import React, { useContext } from 'react'
import { useState } from 'react';
import {FormControl} from "react-bootstrap";
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function Brand({ context }) {
  const { brand, setBrand, allBrand } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Brand
      </label>
      <input 
        list='clientList' 
        id='brandSelection'
        value={brand}
        onChange={e => setBrand(e.target.value)}>
      </input>
        <datalist id='clientList'>
          {allBrand.map((b, i) => (
            <option key={i} value={b}></option>
          ))}
        </datalist>
    </FormGroup>
  )
}
