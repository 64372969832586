import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import {
  api,
  loginRedirect,
  csrftoken,
} from '../../../utils/index_tagging_tool'

// COMPONENTE VECCHIO: dava alcune statistiche su una regola, non più usato ma magari lo richiedono in futuro
export default function DetailStats({ rule, id }) {
  const [match, setMatch] = useState(null)
  const [percentage, setPercentage] = useState(null)

  const stats = async () => {
    let formData = new FormData()
    formData.append('rule', JSON.stringify(rule))
    formData.append('id', id)

    fetch(api + `simulate-rule/`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'X-CSRFTOKEN': csrftoken,
      },
      body: formData,
    })
      .then(loginRedirect)
      .then((data) => {
        setMatch(data.match)
        setPercentage(parseFloat(data.percentage).toFixed(3))
      })
  }

  useEffect(() => {
    stats()
  }, [rule, [...rule.conditions]])

  return (
    <Fragment>
      {match != null && percentage != null ? (
        <Row className={'pt-3'}>
          <Col xs={6}>
            <strong>Keyword che verificano la regola: </strong>
            {match}
          </Col>
          <Col xs={6}>
            <strong>Percentuale sul totale: </strong>
            {percentage}%
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} className={'m-auto'}>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}
