import React from 'react'

function Footer() {
  return (
    <div id="footer">
      <div className="footer-content">
        <div className="logo_box">
          <a href="https://www.webranking.it">
            <img
              className="logo"
              src= {require("../assets/img/brand/webranking.png")}
              alt="Webranking"
              title="Webranking"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
