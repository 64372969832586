import React, { useState, Fragment, useEffect } from 'react'
import ReactDOM from 'react-dom'
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useSortBy,
  useFilters,
  usePagination,
  useExpanded
} from 'react-table'
import { useSticky } from 'react-table-sticky'
import { Row, Col, Container, Button, Form } from 'react-bootstrap'
import { prefix, notify, loginRedirect } from '../../../utils/index_tagging_tool'
import { api } from '../../../utils/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExpand,
  faCompress,
  faSort,
} from '@fortawesome/fontawesome-free-solid'
import { StickyStyles } from '../StickyStyles'
import { saveAs } from 'file-saver'
import Select from 'react-select'

const TableContext = React.createContext()
const DataContext = React.createContext()

// Copiato da documentazione react table
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length
  const {
    setInSearch,
  } = React.useContext(DataContext)

  return (
    <input
      value={filterValue || ''}
      className={'reactTableSearch'}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        setInSearch(e.target.value.length != 0)          
      }}
      placeholder={`Filtra per..`}
    />
  )
}

// Componente per la paginazione
function PageSelector({}) {
  const {
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
    pageOptions,
    pageSize,
  } = React.useContext(DataContext)

  return (
    <div className="paging">
      <button
        onClick={(e) => {
          e.preventDefault()
          gotoPage(0)
        }}
        disabled={!canPreviousPage}
      >
        {'<<'}
      </button>{' '}
      <button
        onClick={(e) => {
          e.preventDefault()
          previousPage()
        }}
        disabled={!canPreviousPage}
      >
        {'<'}
      </button>{' '}
      <button
        onClick={(e) => {
          e.preventDefault()
          nextPage()
        }}
        disabled={!canNextPage}
      >
        {'>'}
      </button>{' '}
      <button
        onClick={(e) => {
          e.preventDefault()
          gotoPage(pageCount - 1)
        }}
        disabled={!canNextPage}
      >
        {'>>'}
      </button>
      <span>
        {' '}
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>{' '}
      </span>
      <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value))
        }}
      >
        {[100, 200, 300, 400, 500].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Righe per pagina: {pageSize}
          </option>
        ))}
      </select>
    </div>
  )
}

// Piccolo componentito che stampa una serie di selettori di colonne, usato per nascondere alcune di queste
function ColumnSelector({}) {
  const { allColumns } = React.useContext(DataContext)

  return (
    <div style={{ maxWidth: '100vw' }}>
      {allColumns.map((column) => (
        <div
          key={column.id}
          style={{ display: 'inline-block', marginLeft: '2%' }}
        >
          <label>
            <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
            {column.id}
          </label>
        </div>
      ))}
      <br />
    </div>
  )
}

// Componente che consente all'utente di definire range di keywords e tipo di integrazione. Avvia la simulazione o l'export
function InputRow({}) {
  const {
    loading,
    exporting,
    togglePopup,
    popup,
    handleSubmit,
    handleOccurrencySubmit,
    handleExport,
    rangeStart,
    setRangeStart,
    rangeEnd,
    setRangeEnd,
    integration,
    setIntegration,
    error,
    setError,
    dimensions,
    excludedColumns,
    setExcludedColumns,
    occurrences,
    setOccurrences,
    couples,
    setCouples
  } = React.useContext(TableContext)

  // Devo impostare valori di md diversi per le colonne, a seconda di se mi trovo in forma compressa o espansa
  // per mantenere il "rapporto" costante (Ancora una volta CSS God)
  const mdCols = popup ? [2, 2, 4, 1, 1, 2] : [3, 3, 4, 1, 1, 0]

  const handleSelect = (e) => {
    // Handler della select dell'integrazione
    occurrences ? setCouples(e.target.value) : setIntegration(e.target.value)
  }

  const changeExcludedColumns = (value) => {
    const values = value.map((v) => v.label)

    if (excludedColumns === values) return
    setExcludedColumns(values)
    //saveGroups(values);
  }

  return (
    <>
      <Row className={popup ? 'm-auto' : ''}>
        <Col xs={12} md={mdCols[2]} className={'mx-auto mb-4'}>
          <label>Colonne da escludere per la simulazione</label>
          <Select
            inputId={`metrics`}
            options={dimensions
              .filter((d) => !excludedColumns.includes(d))
              .map((d) => {
                return { label: d.display_name, value: d.display_name }
              })}
            placeholder={'Inserisci una dimensione'}
            className="single-select"
            classNamePrefix="react-select"
            value={excludedColumns.map((g) => {
              return { label: g, value: g }
            })}
            onChange={changeExcludedColumns}
            isMulti={true}
            isClearable={true}
            isSearchable={true}
          />
          <label style={{ fontSize: '10px' }}>
            Avviare la simulazione per salvare
          </label>
        </Col>
      </Row>
      <Row className={popup ? 'm-auto' : ''}>
        <Col xs={12} md={mdCols[2]} className='mx-auto mb-3'>
          <label style={{ fontSize: '20px' }}>Modalità: {occurrences ? 'Conta Occorrenze':'Simulazione'}</label>
        </Col>
      </Row>
      <Row className={popup ? 'm-auto' : ''}>
        <Col xs={12} md={mdCols[2]} className='mx-auto mb-3'>
          <Button
            onClick={(e) => setOccurrences(!occurrences)}
            variant="danger"
            disabled={loading || error}
            className="mx-auto"
          >
            Cambia Modalità
          </Button>
        </Col>
      </Row>
      <Row className={popup ? 'm-auto' : ''}>
        <Col xs={12} md={mdCols[0]} className={'m-auto'}>
          <label>Da keyword</label>
          <Form.Control
            type="number"
            value={rangeStart}
            min={0}
            required
            onChange={(e) => {
              // Controlliamo che non mettano un range di start maggiore di quello di fine, che non si sa mai
              e.target.value >= rangeEnd
                ? setError('Range non valido.')
                : setError('')
              setRangeStart(e.target.value)
            }}
          />
        </Col>
        <Col xs={12} md={mdCols[1]} className={'m-auto'}>
          <label>A keyword</label>
          <Form.Control
            type="number"
            value={rangeEnd}
            min={0}
            required
            onChange={(e) => {
              // Controlliamo che non mettano un range di end minore di quello di start, che non si sa mai
              e.target.value <= rangeStart
                ? setError('Range non valido.')
                : setError('')
              setRangeEnd(e.target.value)
            }}
          />
        </Col>
        {!occurrences && <Col xs={12} md={mdCols[2]} className={'m-auto text-left'}>
          <label>Integrazione</label>
          <Form.Control
            as="select"
            style={{ display: 'inline', width: '90%' }}
            defaultValue={integration}
            onChange={handleSelect}
          >
            {/* Opzioni opportunamente hardcodate */}
            <option value={'all'}>Tutte</option>
            <option value={true}>Solo integrate</option>
            <option value={false}>Solo non integrate</option>
          </Form.Control>
        </Col>}
        {occurrences && <Col xs={12} md={mdCols[2]} className={'m-auto text-left'}>
          <label>Raggruppamento</label>
          <Form.Control
            as="select"
            style={{ display: 'inline', width: '90%' }}
            defaultValue={couples}
            onChange={handleSelect}
          >
            {/* Opzioni opportunamente hardcodate */}
            <option value={true}>Coppie di parole</option>
            <option value={false}>Parole singole</option>
          </Form.Control>
        </Col>}
        {!occurrences && <Col xs={12} md={mdCols[3]} className={'mt-auto'}>
          <Button
            onClick={(e) => handleExport(e, rangeStart, rangeEnd)}
            variant="warning"
            disabled={exporting || error}
            className={'float-right'}
          >
            Esporta
          </Button>
        </Col>}
        {!occurrences && <Col xs={12} md={mdCols[4]} className={'mt-auto'}>
          <Button
            onClick={(e) => handleSubmit(e, rangeStart, rangeEnd)}
            variant="success"
            disabled={loading || error}
            className={'float-right'}
          >
            Simula
          </Button>
        </Col>}
        {occurrences && <Col xs={12} md={mdCols[4]} className='mt-auto'>
          <Button
            onClick={(e) => handleOccurrencySubmit(e, rangeStart, rangeEnd)}
            variant="success"
            disabled={loading || error}
            className={'float-right'}
          >
            Calcola
          </Button>
        </Col>}
        {/* Se sono fuori dal popup devo avere d-none qua */}
        <Col
          xs={12}
          md={mdCols[5]}
          className={'m-auto' + (mdCols[5] !== 0 ? '' : ' d-none')}
        >
          <FontAwesomeIcon
            title={'Comprimi tabella'}
            className={'float-right mr-4'}
            size={'lg'}
            icon={faCompress}
            onClick={() => {
              togglePopup()
            }}
          />
        </Col>
      </Row>
      <Row className={popup ? 'mb-3' : ''}>
        <Col>
          {error && (
            <p style={{ color: 'red' }} className={'m-auto'}>
              {error}
            </p>
          )}
        </Col>
      </Row>
    </>
  )
}

// Componente padre della tabella, fa uso di react-table
function PreviewTable() {
  const { data, columns, popup } = React.useContext(TableContext)

  // Tramite una libreria react-table-sticky, rendo keyword [0] e volume [1] sticky (quindi quando scorri a destra rimangono fisse lì)
  columns[0]['sticky'] = 'left'
  columns[1]['sticky'] = 'left'

  // Setto filtro testo per tutte le colonne
  columns.forEach((x) => (x['Filter'] = DefaultColumnFilter))
  columns.forEach((x) => (x['id'] === 'expander' ? x['id'] = x['Header'] : ''))

  // Usato per gestire la paginazione dalla libreria
  const [defaultPageIndex, setDefaultPageIndex] = useState(0)

  const table = useTable(
    {
      columns,
      data,
      // Usato per gestire la paginazione dalla libreria
      initialState: { pageIndex: defaultPageIndex, pageSize: 100 },
    },
    useBlockLayout,
    useResizeColumns, // Libreria per allungare o accorciare le colonne, necessario questo hook
    useFilters, // Hook per filtrare
    useSortBy, // Hook per ordinare le colonne
    usePagination, // Hook per la paginazione
    useSticky // Libreria per rendere alcune colonne sticky, necessario questo hook
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // Paging, alcune sono solo utilities
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    state: { pageIndex, pageSize },
  } = table

  const [lastPage, setLastPage] = useState(null)
  const [inSearch, setInSearch] = useState(false)

  useEffect(() => {
    if (inSearch && !lastPage) {
      setLastPage(pageIndex)
    }
    else {
      gotoPage(lastPage)
      setLastPage(null)
    }
  }, [inSearch])

  return (
    <DataContext.Provider
      value={{
        page: page,
        canPreviousPage: canPreviousPage,
        canNextPage: canNextPage,
        pageOptions: pageOptions,
        pageCount: pageCount,
        gotoPage: gotoPage,
        nextPage: nextPage,
        previousPage: previousPage,
        setPageSize: setPageSize,
        pageIndex: pageIndex,
        pageSize: pageSize,
        allColumns: allColumns,
        inSearch: inSearch,
        setInSearch: setInSearch,
        lastPage: lastPage,
        setLastPage: setLastPage,
      }}
    >
      <StickyStyles>
        <ColumnSelector />
        <PageSelector />
        <div
          style={
            // Imposto stili diversi a seconda dell'apertura o chiusura del "popup", l'obiettivo
            // è avere sempre la tabella chiusa in un container e che scrolli da sola, lasciando il selettore
            // di pagina e delle colonne bello fermo
            popup
              ? { overflow: 'auto', maxWidth: '100vw', maxHeight: '79vh' }
              : { overflow: 'auto', maxHeight: '800px' }
          }
        >
          {/* Gran parte del codice qui sotto è rubato allegramente dalla wiki di react table*/}
          <table {...getTableProps()} className={'table sticky'}>
            <thead className={'header'}>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <tr className={'tr'} {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column, i) => (
                        <th className={'th'} {...column.getHeaderProps()}>
                          {column.pk ? (
                            // Se presente una pk aggiungo il link ad una custom dimension con quel pk
                            <a
                              href={`/r/reporting/custom-dimensions/${column.pk}`}
                              target="_blank"
                            >
                              {column.render('Header')}
                            </a>
                          ) : (
                            <>{column.render('Header')}</>
                          )}{' '}
                          <FontAwesomeIcon
                            icon={faSort}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={
                              // Sovrascrivo lo style data da getSortBy perchè poco personalizzabile
                              {
                                boxShadow: 'none',
                                cursor: 'pointer',
                              }
                            }
                          />
                          <div>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                          {/* Use column.getResizerProps to hook up the events correctly */}
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${
                              column.isResizing ? 'isResizing' : ''
                            }`}
                          />
                        </th>
                      ))
                    }
                  </tr>
                ))
              }
            </thead>
            {/* Apply the table body props */}
            <tbody className={'body'} {...getTableBodyProps()}>
              {
                // Loop over the table rows
                page.map((row) => {
                  // Prepare the row for display
                  prepareRow(row)
                  return (
                    // Apply the row props
                    <tr className={'tr'} {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td className={'td'} {...cell.getCellProps()}>
                              {
                                // Render the cell contents
                                cell.render('Cell')
                              }
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </StickyStyles>
    </DataContext.Provider>
  )
}

function OccurrenceTable() {
  /***
   * Table to visualize occurrences report. Similar to the PreviewTable, but it adds an expasion row to see
   * subrows of the report
   */
  const { data, columns, popup } = React.useContext(TableContext)

  // colonna di espansione presa dagli esempi della documentazione:
  // https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/expanding?file=/src/App.js
  const expanderColumn = {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? '➖' : '➕'}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({ style: { paddingLeft: `${row.depth * 2}rem`, },})}
            >
              {row.isExpanded ? '➖' : '➕'}
            </span>
          ) : null,
  }

  // a causa del DataContext (grazie Fede Mercalli) può essere che il componente venga reimpostato,
  // con la colonna di espansione già presente nei dati, per evitare errori faccio il check
  if (columns[0]['id'] !== 'expander')
      columns.unshift(expanderColumn)

  // rendo la colonna con il tasto di espansione sticky (quindi quando scorri a destra rimangono fisse lì)
  columns[0]['sticky'] = 'left'

  // Tramite una libreria react-table-sticky, rendo keyword [1] e volume [2] sticky (quindi quando scorri a destra rimangono fisse lì)
  columns[1]['sticky'] = 'left'
  columns[1]['Header'] = 'Word'
  columns[2]['sticky'] = 'left'

  // Setto filtro testo per tutte le colonne
  columns.forEach((x) => (x['Filter'] = DefaultColumnFilter))

  // Usato per gestire la paginazione dalla libreria
  const [defaultPageIndex, setDefaultPageIndex] = useState(0)

  const table = useTable(
    {
      columns,
      data,
      // Usato per gestire la paginazione dalla libreria
      initialState: {
        pageIndex: defaultPageIndex,
        pageSize: 100,
        expanded: true
      },
    },
    useBlockLayout,
    useResizeColumns, // Libreria per allungare o accorciare le colonne, necessario questo hook
    useFilters, // Hook per filtrare
    useSortBy, // Hook per ordinare le colonne
    useExpanded,
    usePagination, // Hook per la paginazione
    useSticky, // Libreria per rendere alcune colonne sticky, necessario questo hook
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // Paging, alcune sono solo utilities
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    state: {
      pageIndex,
      pageSize,
      expanded
    },
  } = table

  const [lastPage, setLastPage] = useState(null)
  const [inSearch, setInSearch] = useState(false)

  useEffect(() => {
    if (inSearch && !lastPage) {
      setLastPage(pageIndex)
    }
    else {
      gotoPage(lastPage)
      setLastPage(null)
    }
  }, [inSearch])

  return (
    <DataContext.Provider
      value={{
        page: page,
        canPreviousPage: canPreviousPage,
        canNextPage: canNextPage,
        pageOptions: pageOptions,
        pageCount: pageCount,
        gotoPage: gotoPage,
        nextPage: nextPage,
        previousPage: previousPage,
        setPageSize: setPageSize,
        pageIndex: pageIndex,
        pageSize: pageSize,
        allColumns: allColumns,
        inSearch: inSearch,
        setInSearch: setInSearch,
        lastPage: lastPage,
        setLastPage: setLastPage,
      }}
    >
      <StickyStyles>
        <ColumnSelector />
        <PageSelector />
        <div
          style={
            // Imposto stili diversi a seconda dell'apertura o chiusura del "popup", l'obiettivo
            // è avere sempre la tabella chiusa in un container e che scrolli da sola, lasciando il selettore
            // di pagina e delle colonne bello fermo
            popup
              ? { overflow: 'auto', maxWidth: '100vw', maxHeight: '79vh' }
              : { overflow: 'auto', maxHeight: '800px' }
          }
        >
          {/* Gran parte del codice qui sotto è rubato allegramente dalla wiki di react table*/}
          <table {...getTableProps()} className={'table sticky'}>
            <thead className={'header'}>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <tr className={'tr'} {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column, i) => (
                        <th className={'th'} {...column.getHeaderProps()}>
                          {column.pk ? (
                            // Se presente una pk aggiungo il link ad una custom dimension con quel pk
                            <a
                              href={`/r/reporting/custom-dimensions/${column.pk}`}
                              target="_blank"
                            >
                              {column.render('Header')}
                            </a>
                          ) : (
                            <>{column.render('Header')}</>
                          )}{' '}
                          <FontAwesomeIcon
                            icon={faSort}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={
                              // Sovrascrivo lo style data da getSortBy perchè poco personalizzabile
                              {
                                boxShadow: 'none',
                                cursor: 'pointer',
                              }
                            }
                          />
                          <div>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                          {/* Use column.getResizerProps to hook up the events correctly */}
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${
                              column.isResizing ? 'isResizing' : ''
                            }`}
                          />
                        </th>
                      ))
                    }
                  </tr>
                ))
              }
            </thead>
            {/* Apply the table body props */}
            <tbody className={'body'} {...getTableBodyProps()}>
              {
                // Loop over the table rows
                page.map((row) => {
                  // Prepare the row for display
                  prepareRow(row)
                  return (
                    // Apply the row props
                    <tr className={'tr'} {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td className={'td'} {...cell.getCellProps()}>
                              {
                                // Render the cell contents
                                cell.render('Cell')
                              }
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </StickyStyles>
    </DataContext.Provider>
  )
}

// Componente padre per la simulazione del report
function SeoPreview({ reportId, reportName, dimensions, ignoredColumns }) {
  // Parametri utente
  const [rangeStart, setRangeStart] = useState(0)
  const [rangeEnd, setRangeEnd] = useState(1000)
  // esclusivo del report simulato
  const [integration, setIntegration] = useState('all')
  // esclusivo del report occorrenze
  const [couples, setCouples] = useState(false)

  // Booleani per gestire il caricamento/errori vari
  const [loading, setLoading] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [error, setError] = useState('')

  // Booleano per aprire la tabella in fullscreen
  const [popup, setPopup] = useState(false)

  // Dati e colonne della simulazione, inizialmente vuote e riempite quando arriva la risposta da backend
  const [previewData, setPreviewData] = useState([])
  const [previewColumns, setPreviewColumns] = useState([])

  // Una volta ottenuti i risultati, questa lista decide quali colonne non mostrare nella tabella (gestisce una sorta di pulsante "nascondi colonna")
  const [hiddenColumns, setHiddenColumns] = useState([])

  // Lista delle colonne da escludere dal report simulato
  const [excludedColumns, setExcludedColumns] = useState(
    ignoredColumns ? ignoredColumns : []
  )

  // Lista delle colonne da escludere dal report simulato
  const [occurrences, setOccurrences] = useState(false)

  // Esegue la chiamata a backend secondo i parametri dell'utente e recupera il risultato per mostrarlo nella tabella
  const handleSubmit = (e, start, end) => {
    e.preventDefault()

    setLoading(true)

    var excludedString = excludedColumns.toString()

    api.get(
      prefix +
        `simulate-report/${reportId}?range_start=${start}&range_end=${end}&integration=${integration}&exclude=${excludedString}`
    )
      .then(loginRedirect)
      .then((data) => {
        const parsedData = JSON.parse(data)
        setPreviewData(parsedData)
        setPreviewColumns(
          parsedData.length > 0
            ? Object.keys(parsedData[0])
                .filter((x) => x !== 'tableData')
                .map((x, i) => {
                  // La colonna è convertita in un formato che piace a react-table
                  const col = {
                    Header: x,
                    accessor: x,
                  }

                  // Se la colonna è una dimensione, mantenere la primari key nella colonna per fornire un link in tabella
                  const dim = dimensions.filter((d) => d.display_name === x)
                  if (dim.length > 0) {
                    col['pk'] = dim[0].id
                  }

                  return col
                })
            : []
        )
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
        setLoading(false)
      })
  }

    // Esegue la chiamata a backend secondo i parametri dell'utente e recupera il risultato per mostrarlo nella tabella
  const handleOccurrencySubmit = (e, start, end) => {
    e.preventDefault()

    setLoading(true)

    var excludedString = excludedColumns.toString()

    api.get(
      prefix +
        `simulate-occurrences/${reportId}?range_start=${start}&range_end=${end}&exclude=${excludedString}&couple=${couples}`
    )
      .then(loginRedirect)
      .then((data) => {
        const parsedData = JSON.parse(data)

        parsedData.forEach((x) => x['subRows'] = JSON.parse(x['subRows']))
        setPreviewData(parsedData)
        setPreviewColumns(
          parsedData.length > 0
            ? Object.keys(parsedData[0])
              .filter((x) => x !== 'tableData' && x !== 'subRows')
              .map((x, i) => {
                // La colonna è convertita in un formato che piace a react-table
                const col = {
                  Header: x,
                  accessor: x,
                }

                // Se la colonna è una dimensione, mantenere la primari key nella colonna per fornire un link in tabella
                const dim = dimensions.filter((d) => d.display_name === x)
                if (dim.length > 0) {
                  col['pk'] = dim[0].id
                }

                return col
              })
              : []
        )
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
        setLoading(false)
      })
  }

  // Funzione simile a quella sopra, ma anzichè portare i dati in tabella, riceve un file e lo scarica
  const handleExport = async (e, start, end) => {
    e.preventDefault()
    setExporting(true)

    notify(
      "Stiamo generando l'export, potrebbe volerci qualche minuto. 'Keep calm and sing soft kitty' - Claudia Caldara",
      'warning'
    )

    api(
      prefix +
        `export-report/${reportId}?range_start=${start}&range_end=${end}&integration=${integration}`,
      { responseType: 'blob' }
    )
      .then(loginRedirect)
      .then((data) => {
        saveAs(data, `${reportName}_${start}_${end}_export.xlsx`)
        notify('File generato con successo', 'success')
      })
      .catch((e) => {
        notify(e.message, 'error')
      })
      .finally(() => setExporting(false))
  }

  // Modifica Popup e rende coerente lo stile per visualizzare o meno in fullscreen il componente della tabella (CSS God mi chiamavano)
  const togglePopup = () => {
    setPopup(!popup)
    const all = document.getElementById('main-content')
    const sidebar = document.getElementById('sidenav-main')
    // Quando creo il portale, voglio nascondere tutto quello che c'è dietro
    all.style.display = popup ? 'block' : 'none'
    sidebar.style.display = popup ? 'block' : 'none'
  }

  // Se cambia modalità con il bottone allora resetta i dati ricevuti
  const changeMod = (value) => {
    setOccurrences(value)
    setPreviewData([])
    setPreviewColumns([])
  }

  return (
    <Fragment>
      <TableContext.Provider
        value={{
          columns: previewColumns,
          hiddenColumns: hiddenColumns,
          setHiddenColumns: setHiddenColumns,
          data: previewData,
          title: reportName,
          loading: loading,
          exporting: exporting,
          popup: popup,
          togglePopup: togglePopup,
          handleSubmit: handleSubmit,
          handleOccurrencySubmit: handleOccurrencySubmit,
          handleExport: handleExport,
          rangeStart: rangeStart,
          setRangeStart: setRangeStart,
          rangeEnd: rangeEnd,
          setRangeEnd: setRangeEnd,
          excludedColumns: excludedColumns,
          setExcludedColumns: setExcludedColumns,
          dimensions: dimensions,
          integration: integration,
          setIntegration: setIntegration,
          error: error,
          setError: setError,
          occurrences: occurrences,
          setOccurrences: changeMod,
          couples: couples,
          setCouples: setCouples,
        }}
      >
        <div className="Content-box">
          <Row className="pb-3">
            <Col>
              <h2>Anteprima</h2>
            </Col>
            {
              // La possibilità di estendere la tabella viene data solo se c'è la tabella, ovviamente
              previewData.length > 0 && (
                <Col className="text-right">
                  <FontAwesomeIcon
                    title={'Espandi tabella'}
                    size={'lg'}
                    icon={faExpand}
                    onClick={() => {
                      // Quando si apre il componente fullpage voglio che l'utente si ritrovi in alto a sinistra
                      window.scrollTo(0, 0)
                      togglePopup()
                    }}
                  />
                </Col>
              )
            }
          </Row>
          {!occurrences ? <p className="pb-3">
            Da qui puoi simulare un'esecuzione del report con le regole,
            dimensioni e metriche impostate fino ad ora. E' necessario definire
            un range di keyword sulle quali effettuare la simulazione. Si
            consiglia di definirlo in maniera inversamente proporzionale al
            numero di dimensioni e regole del report, per{' '}
            <strong>evitare di sovraccaricare la pagina</strong>. E' anche
            possibile selezionare una lista di dimensioni da escludere dalla
            simulazione, in modo da ridurre i tempi di elaborazione (se si
            escude una dimensione associata ad una regola dipendente,
            quest'ultima non sarà calcolata).
            <br /> Una volta simulato, si avrà la possibilità di estendere la
            visualizzazione all'intera pagina.{' '}
          </p> : <p className="pb-3">
            Da qui puoi calcolare le occorrenze delle singole parole nelle keyword.
            Le parole ottenute saranno taggate come avviene durante la simulazione, applicando le regole,
            le dimensioni e le metriche impostate fino ad ora nel report.
            Ogni parola, inoltre, permette di visualizzare le kw più importanti che la contengono
            e i tag che ad esse vengono associati. E' necessario definire
            un range di parole sulle quali effettuare il calcolo, più precisamente indica il numero di parole
            da considerare in ordine di occorrenza, dalla più frequente alla meno. Si
            consiglia di definirlo in maniera inversamente proporzionale al
            numero di dimensioni e regole del report, per{' '}
            <strong>evitare di sovraccaricare la pagina</strong>. E' anche
            possibile selezionare una lista di dimensioni da escludere dalla
            simulazione, in modo da ridurre i tempi di elaborazione (se si
            esclude una dimensione associata ad una regola dipendente,
            quest'ultima non sarà calcolata).
            <br /> Una volta simulato, si avrà la possibilità di estendere la
            visualizzazione all'intera pagina.{' '}
          </p>}

          <Form onSubmit={(e) => handleSubmit(e, rangeStart, rangeEnd)}>
            <InputRow />
            <Row className={'pt-5'}>
              <Col xs={12} className={'m-auto'}>
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    {!popup ? (
                      <>
                        {
                          // Se ho dati e colonne, mostro una tabella
                          previewData.length > 0 &&
                            previewColumns.length > 0 && (occurrences ? <OccurrenceTable /> : <PreviewTable />)
                        }
                      </>
                    ) : (
                      <p>Aperta in un'altra finestra</p>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </div>
        {
          // Createportal mi permette di copiare il componente in un div, presente in index appositamente per lo scopo
          popup &&
            ReactDOM.createPortal(
              <div
                style={{
                  maxHeight: '100vh',
                  maxWidth: '100vw',
                  overflow: 'hidden',
                }}
              >
                <InputRow />
                {occurrences ? <OccurrenceTable /> : <PreviewTable />}
              </div>,
              document.getElementById('modal-portal')
            )
        }
      </TableContext.Provider>
    </Fragment>
  )
}

export default SeoPreview
