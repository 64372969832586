import React, { useEffect, useState, useContext } from 'react'
import { api } from '../../utils'
import { notify } from '../../utils/notify'
import { Badge, Button, Table, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import PageSelector from '../../components/PageSelector'
import Loading from '../../tagging_tool/components/Loading'
import { AlertsFilterDataContext } from '../../HOC/alertsFiltersContext'
import { useParams, useHistory } from 'react-router-dom'
import {
  Col,
  CardBody,
  Row,
  Card,
  CardHeader
} from "reactstrap";

const TableContext = React.createContext()
const PageContext = React.createContext()
const pageSize = 50

function ModalIspezioni({alert}) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [inspections, setInspections] = useState([])

  const getInspectionsApi = () => {
    setInspections([])
    api.post(`/seo-monitoring/inspections/from_alert/`, alert)
      .then(response => {
        setInspections(response.data?.results)
      })
      .catch(err => {
        console.error(err)
      })
  }
  
  useEffect(() => {
    if (alert?.site_url_id && show)
      getInspectionsApi(alert)
  }, [alert, show])

  const getInspections = () => {
    if (inspections && inspections.length > 0) {
      return inspections.map((inspection, y) => {
        return (
          <div key={y}>
            {(inspection.type == 'actual') ? <h2>Ispezione attuale</h2> : null}
            {(inspection.type == 'previous') ? <h2>Ispezione precedente</h2> : null}
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  {Object.keys(inspection.items[0]).map((k, thi) => (
                    <th key={thi}>{k}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {inspection.items.map((d, i) => (
                  <tr key={i}>
                    {Object.keys(d).map((k, ii) => {
                      return (<td key={ii}>{d[k]}</td>)
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
            <br/>
          </div>
        )
      })
    }
    return null
  }

  return (
    <>
      <Button variant="primary" size="sm" onClick={handleShow}>
        Ispezioni
      </Button>

      <Modal dialogClassName="modal-90w" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ispezioni</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(inspections && inspections.length > 0) ? getInspections() : <Loading/>}
        </Modal.Body>
      </Modal>
    </>
  )
}

function TableAlerts() {
  const {
    data, 
    history
  } = React.useContext(TableContext)

  const excluded_cols = [
    'site_url',
    'site_url_id',
    'alert_type_description',
    'previous_url',
    'previous_crawl_timestamp',
    'crawl_timestamp'
  ]

  const copyToClipboard = (value) => {
    if (value !== '')
      navigator.clipboard.writeText(value)
      notify(
        'Url copiato negli appunti',
        'success'
      )
    return ''
  }

  if (data && data.length > 0) {
    return (
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            {Object.keys(data[0]).map((k, thi) => (
              (excluded_cols.includes(k)) ? null : <th key={thi}>{k}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              {Object.keys(d).map((k, ii) => {
                if (k == 'url') {
                  return (
                    <td style={{cursor: "pointer", }} key={ii} onClick={() => {copyToClipboard(d[k])}}>
                      {(d[k].length > 120) ? d[k].slice(0, 120) + '...' : d[k]}
                    </td>
                  )
                }
                else if (k == 'alert_type' && d[k]) {
                  return (
                    <td key={ii}>
                      <OverlayTrigger
                        key={'tooltip'+ii}
                        placement={'top'}
                        overlay={
                          <Tooltip>
                            {(d['alert_type_description']) ? d['alert_type_description'] : '...'}
                          </Tooltip>
                        }
                      >
                        <Badge key={ii} pill variant="info">{d[k]}</Badge>
                      </OverlayTrigger>
                      
                    </td>
                  )
                }
                else if (k == 'actions') {
                  return (
                    <td key={ii}>
                      <ModalIspezioni alert={d} />
                    </td>
                  )
                }
                return ((excluded_cols.includes(k)) ? null : <td key={ii}>{d[k]}</td>)
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
  return null
}

function AlertEventDetailTable() {
  const history = useHistory()
  const params = useParams()
  const [filtersParams] = useContext(AlertsFilterDataContext)
  const [downloadLink, setDownloadLink] = useState(null)

  const [alertEvents, setAlertEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [siteUrl, setSiteUrl] = useState({
    url: '',
    secret_name: '',
    notification_emails: '',
    active: false,
  })

  const [canPreviousPage, setCanPreviousPage] = useState(false)
  const [canNextPage, setCanNextPage] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [pageIndex, setPageIndex] = useState(1)

  const updateDownloadlink = () => {
    const baseDownloadUrl = new URL(`${api.defaults.baseURL}seo-monitoring/alert-events/`);

    baseDownloadUrl.searchParams.append("site_url_id", params.siteUrlId);
    baseDownloadUrl.searchParams.append("day_date", params.dayDate);
    Object.keys(filtersParams).forEach((key) => {
      baseDownloadUrl.searchParams.append(key, filtersParams[key]);
    })
    baseDownloadUrl.searchParams.append("download", "")
    setDownloadLink(baseDownloadUrl.href)
  }

  const downloadData = () => {
    api.get(downloadLink, {responseType: 'blob'})
      .then(response => {
        const url = window.URL.createObjectURL(response.data) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.request.getResponseHeader('download-filename'))
        document.body.appendChild(link)
        link.click()
      })
  }

  const getAlertEventsByDate = (page = null) => {
    updateDownloadlink()
    api.get(`/seo-monitoring/alert-events/`, { 
      params: {
        site_url_id: params.siteUrlId,
        day_date: params.dayDate,
        ...(Object.keys(filtersParams).length > 0 && filtersParams),
        page,
      }
    })
      .then(response => {
        setCanPreviousPage(response.data?.previous != null)
        setCanNextPage(response.data?.next != null)
        setPageCount((response.data?.count) ? Math.ceil(response.data.count/pageSize) : 1)
        let responseData = (response.data?.results) ? response.data.results : response.data
        if (responseData.length > 0)
          responseData = responseData.map((obj) => {
            return {
              ...obj,
              'actions': ''
            }
          })
        setAlertEvents(responseData)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
        notify(
          err,
          'error'
        )
      })
  }

  const getSiteUrl = (id) => {
    api.get(`/seo-monitoring/site-urls/${id}/`)
      .then(response => {
        setSiteUrl(response.data)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const gotoPage = (page) => {
    setPageIndex(page)
  }
  const previousPage = () => {
    if (pageIndex > 1) {
      setPageIndex(pageIndex - 1)
    }
    else {
      setPageIndex(1)
    }
  }
  const nextPage = () => {
    setPageIndex(pageIndex+1)
  }

  useEffect(() => {
    setLoading(true)
    if (params?.siteUrlId && params?.dayDate) {
      getSiteUrl(params.siteUrlId)
      getAlertEventsByDate()
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    if (params?.siteUrlId && params?.dayDate) {
      getAlertEventsByDate(pageIndex)
    }
  }, [pageIndex])

  useEffect(() => {
    if (pageIndex != 1)
      setPageIndex(1)
    else if (params?.siteUrlId && params?.dayDate) {
      setLoading(true)
      getAlertEventsByDate(pageIndex)
    }
  }, [filtersParams])

  return (
    <>
    {(params?.siteUrlId) ? (
    <Row style={{marginBottom: "100px"}}>
      <div className="col pb-5">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
          <Row style={{ marginLeft: "0px" }}>
            <Col md={10}>
              <h1 className="mb-0">
                Alerts per {siteUrl?.url || '...'} nel giorno {params?.dayDate}
                
                {(downloadLink) ? (
                  <a onClick={downloadData} className="download-link">
                    <i className="fa fa-download"></i>
                  </a>
                ) : null}
              </h1>
              
            </Col>
          </Row>
          </CardHeader>
          <CardBody>
            <TableContext.Provider
              value={{
                data: alertEvents,
                history: history
              }}
            >
              <PageContext.Provider
                value={{
                  gotoPage,
                  previousPage,
                  nextPage,
                  canPreviousPage,
                  canNextPage,
                  pageCount,
                  pageIndex,
                }}
              >
                <PageSelector context={PageContext} />
              </PageContext.Provider>
              { (loading) ? (
              <Loading />
              ) : (
                <TableAlerts />
              )}
            </TableContext.Provider>
          </CardBody>
        </Card>
      </div>
    </Row>
    )
    : null}
  </>)
}
export default AlertEventDetailTable