import React, { useContext } from 'react'
import {
  prefix,
  loginRedirect,
  csrftoken,
  notify,
} from '../../../../utils/index_tagging_tool'
import {
  api,
} from '../../../../utils/index'
import _ from 'lodash'

export function DisableOperation({ context }) {
  const { operationsPossible, checkedRules, dimension, setLoading } =
    useContext(context)

  const disableRule = async (rule) => {
    return api.post(`${prefix}disabled-rules/`, { rule: rule.id, custom: dimension })
  }

  const disableRules = async (e) => {
    e.preventDefault()
    setLoading(true)
    const ruleList = JSON.parse(JSON.stringify(checkedRules))
    await Promise.all(
      ruleList.map(async (rule) => {
        // Scorro i selezionati e cancello tutto
        if (rule.id !== -1) {
          try {
            const response = await disableRule(rule)
            loginRedirect(response)
          } catch (e) {
            notify(e.message, 'error')
          }
        }
      })
    )
    window.location.reload()
  }

  return (
    <>
      {operationsPossible.includes('Disabilita') && (
        <div className={'operationsDiv'} onClick={disableRules}>
          Disabilita
        </div>
      )}
    </>
  )
}
