import React from 'react'
import { Form } from 'react-bootstrap'

const FormSelectFilter = ({ label, value, onChange, data }) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control as="select" value={value} onChange={(e) => onChange(e.target.value)} required>
        <option value=""></option>
        {data?.map((item, idx) => (
          <option value={item} key={idx}>
            {item}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default FormSelectFilter
