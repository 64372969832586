import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function ScheduleType({ context }) {
  const { setScheduleType, scheduleType } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Schedule Type:
      </label>
      <Input
        className="form-control-alternative"
        type="select"
        id="mese"
        value={scheduleType}
        onChange={(e) => setScheduleType(e.target.value)}
      >
        <option value="M">Mensile</option>
        <option value="W">Settimanale</option>
        <option value="D">Giornaliero</option>
      </Input>
    </FormGroup>
  )
}
