//import "./indexTaggingTool.css";
import './AppTaggingTool.scss'
import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import Listing from './tagging_tool/containers/Listing/Listing'
import CreateReport from './tagging_tool/containers/CreateReport/CreateReport'
import DetailReport from './tagging_tool/containers/DetailReport/DetailReport'
import DetailRules from './tagging_tool/containers/DetailRules/DetailRules'
import SeoReport from './tagging_tool/containers/SeoReport/SeoReport'
import DimensionGroups from './tagging_tool/containers/DimensionGroups/DimensionGroupsAdmin'
import DimensionGroupInfo from "./tagging_tool/containers/DimensionGroups/DimensionGroupInfo/DimensionGroupInfo";
import UserHeader from "./components/Headers/ReportingHeader";

class App extends Component {
  render() {
    return (
      <div className="App AppTaggingTool">
        <div className="w-main" id="all">
          <Router>
            <UserHeader/>
              <Switch>
                <Route
                  path="/reporting/reports/seo/:id"
                  exact
                  render={(props) => <SeoReport {...props} />}
                />
                <Route
                  path="/reporting/dimension-groups/info/:name"

                  render={(props) => <DimensionGroupInfo {...props} />}
                />
                <Route
                  path="/reporting/dimension-groups"
                  render={() => <DimensionGroups />}
                />
                <Route
                  path="/reporting/saved-dimensions/:id"
                  exact
                  render={(props) => <DetailReport {...props} />}
                />
                <Route
                  path="/reporting/custom-dimensions/:id"
                  exact
                  render={(props) => <DetailReport {...props} />}
                />
                <Route
                  path="/reporting/custom-metrics/:id"
                  exact
                  render={(props) => <DetailReport {...props} />}
                />
                <Route
                  path="/reporting/custom-rules/:id"
                  exact
                  render={(props) => <DetailRules {...props} />}
                />
                <Route
                  path="/reporting/create"
                  render={() => <CreateReport />}
                />
                <Route path="/reporting/" component={() => <Listing />} />
                <Route
                  path="/"
                  render={() => <Redirect to="/reporting/" />}
                />
              </Switch>
          </Router>
          {/*<Footer />*/}
        </div>
        <div id="modal-portal" />
      </div>
    )
  }
}

export default App
