import React, {useEffect, useState} from 'react'
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Toast,
  ToastBody,
  Spinner,
  UncontrolledTooltip
} from 'reactstrap'
import {cloneReport, getReportNames} from "../../api_methods";
import {notify} from "../../../utils/notify";
import {ReportName} from "../ReportField/ReportField";
import {isValidForBQ} from "../../../utils/validators";


export default function CloneReport({id}) {
  const [show, setShow] = useState(false)
  const [reports, setReports] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [name, setName] = useState('')
  const [nameValid, setNameValid] = useState(false)
  const [newId, setNewId] = useState(null)

  const toggle = () => {
    setShow(!show)
    setNewId(null)
    setShowResult(false)
    setName('')
  };

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      &times;
    </button>
  );

  const createNewReport = async () => {
    // reset previous results
    setNewId(null)
    setShowResult(false)
    // if the name is invalid stops
    if(!nameValid){
      return
    }
    // the name is valid, sends the request
    setLoading(true)

    let data = {name: name}
    await cloneReport(id, data).then(
      (res) => {
        if(res.status === 201){
          notify("Report clonato con successo", "success")
          setNewId(res.data?.id)
          setShowResult(true)
        } else if(res.status === 208) {
          notify("Esiste già un report con questo nome", "warning")
          setNewId(res.data?.id)
          setShowResult(true)
        } else if(res.status === 404) {
          notify("Non è stato possibile clonare il report, errore nella richiesta", "error")
        } else {
          notify("Errore inaspettato, contatta un Innovation se persiste", "error")
        }
      }
    )

    setLoading(false)
  }

  //fetch the list of report names, used to check if the new report name already exists
  const fetchReports = () => {
    getReportNames().then((data) => setReports(data))
  }

  useEffect(() => {
    fetchReports()
  }, [])

  // every time the name changes, check validity (if not valid the submit button is disabled)
  useEffect(() => {
    setNameValid(isValidForBQ(name))
  }, [name])

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggle}
        size="sm"
        centered={true}
        scrollable={true}
      >
        <ModalHeader toggle={toggle} tag="h3" close={closeBtn} className="border align-items-center"  id="clone-header">
          Clona il Report <i className="fa-solid fa-question-circle fa-xs"></i>
        </ModalHeader>
        <UncontrolledTooltip
          placement={'top'}
          target={'clone-header'}
        >
          Questa funzionalità permette di creare un nuovo report identico a quello clonato. Avrà le stesse dimensioni e le stesse regole.
        </UncontrolledTooltip>

        <ModalBody>
          <Form onSubmit={''}>
            <Row>
              <Col xs={12} className={'pb-3'}>
                <ReportName
                  names={reports}
                  name={name}
                  setName={setName}
                  create={true}
                />
              </Col>
            </Row>
          </Form>
          <Toast isOpen={showResult && newId !== null} className="p-3 bg-success my-2 rounded">
            <ToastBody>
              <b>Clicca <a href={'/reporting/reports/seo/' + newId}>qui</a> per aprire il report</b>
            </ToastBody>
          </Toast>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="primary" disabled={!nameValid || loading} onClick={() => createNewReport()}>
            {!loading ? "Crea il nuovo report" : <Spinner size="sm"> </Spinner>}
          </Button>
        </ModalFooter>
      </Modal>

      <Button color="warning" onClick={() => setShow(true)}>
        Clone report
      </Button>
    </>
  )
}
