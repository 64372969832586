import React from 'react'
import SiteUrlListTable from './SiteUrlListTable'
import { BASE_URL } from '../../utils/index'
import SetTitle from '../../utils/set_title'

export default function SiteUrlList() {
  SetTitle('Seo Sites')
	return (
    <>
      <div className="bg-gradient-info secondary-page-title" >
        <div md={12} className="form-title">
          <i className="ni ni-compass-04 text-white" style={{color: "white", fontSize: "35px"}}></i>
          <h1 className="page-title">
            Seo Sites
            <a href={BASE_URL + '/docs/seo_monitoring_tool/seo_monitoring-setup/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>
          </h1>
        </div>
      </div>
      <div className="page-description">
        Lista di siti da monitorare.
      </div>
      <SiteUrlListTable />
    </>
	)
}