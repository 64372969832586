import React from 'react'
import { HomeFilterForm } from '../containers/HomeFiltersForm'
import { WLinkButton } from '../components/molecules'
import { WSection } from '../containers/WSection'
import StudiKWListing from '../containers/StudiKwListing'
import { FilterDataProvider } from '../HOC/studiKwFiltersContext'
import { BASE_URL } from '../utils/index'
import SetTitle from '../utils/set_title'

export default function Home() {
	SetTitle('Ranking Reports')
	return (
		<FilterDataProvider>
			<div className="bg-gradient-info main-page-title" >
				<div md={12} className="form-title">
					<i className="fa-solid fa-house-chimney fa-xl" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">
						Ranking Reports
						<a href={BASE_URL + '/docs/ranking_tools/studi-keywords/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>
					</h1>
				</div>
				<HomeFilterForm />
			</div>
			<StudiKWListing />
		</FilterDataProvider>
	)
}
