import React, { Component, Fragment } from 'react'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import { prefix, loginRedirect, notify } from '../../../utils/index_tagging_tool'
import { api } from '../../../utils/index'
import DetailSeoForm from './DetailSeoForm'
import DetailSave from './DetailSave'
import RegexpSeoHelper from '../DetailUtility/RegexpSeoHelper'
import _ from 'lodash'
import {Button, Card, CardHeader, Row} from "reactstrap";
import UserHeader from "../../../components/Headers/ReportingHeader";


// Questo era qui da prima di me, se avete rimostranze mi dispiace, le avevo anch'io
class DetailReport extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: this.props.match.params.id,
      saved: this.props.location.pathname.includes('saved'),
      detail_type: this.props.location.pathname.split('/')[2],
      other_dimensions: [],
      linked: null,
      rules: [],
      deleted_rules: [],
      operators: [],
      name: '',
      displayName: '',
      seo: true,
      reportName: '',
      rulePages: 1,
      sourceId: null,
      operatori_logici: [
        { id: 0, name: 'OR', display_name: 'OR' },
        { id: 1, name: 'AND', display_name: 'AND' },
      ],
      is_saving: false,
      presentiPage: 1,
      aggiuntePage: 1,
      showRegexHelper: false,
      listRef: null,
      loading: true,
      loader: false,
    }

    document.title = 'Tagging tool'
  }

  listRef = React.createRef()

  handleFirstRule() {
    this.setState({
      rules: [
        {
          id: -1,
          // Sarà una regola salvata se sono su un'interfaccia salvata, altrimenti una regola custom
          report_id: this.state.saved ? null : 'fakeid',
          index: 0,
          priority: this.state.rules.length,
          rule: {
            conditions: [
              {
                field: this.state.seo ? 'Keyword' : '',
                operator: this.state.operators[1].name,
                operand: '',
                next: '',
              },
            ],
            value: '',
          },
        },
      ],
    })
  }

  handleRule(e, values, type = 'custom') {
    this.setState(
      {
        rules: values.rules.concat([
          {
            id: -1,
            // Serve a rendere coerente il colore della regola, in base al tipo
            report_id: type === 'custom' ? 'fakeid' : null,
            index: values.rules.length,
            priority: this.state.rules.length,
            rule: {
              conditions: [
                {
                  field: this.state.seo ? 'Keyword' : '',
                  operator: this.state.operators[1].name,
                  operand: '',
                  next: '',
                },
              ],
              value: '',
            },
          },
        ]),
      },
      () => {
        // scroll alla nuova riga
        this.listRef.current.scrollToItem(this.state.rules.length)
      }
    )
  }

  removeRule(index, values) {
    if (values.rules.length > 1) {
      const rules = [...values.rules]
      rules[index].id &&
      this.setState({
        deleted_rules: [...this.state.deleted_rules, rules[index].id],
      })
      rules.splice(index, 1)
      // Una volta tolto un elemento, devo rendere gli indici nuovamente coerenti
      rules.forEach((v, i) => (v['index'] = i))
      this.setState({ rules: rules })
    } else {
      alert('Deve esistere almeno una regola')
    }
  }

  reorderRules(order) {
    const sortedRules = _.orderBy(
      this.state.rules,
      [order.field],
      [order.order]
    )
    sortedRules.forEach((v, i) => (v['index'] = i))
    this.setState({
      rules: sortedRules,
    })
  }

  fetchCurrentDimensionRules(page, querySearch, queryParams) {
    api.get(`${prefix}custom-rules/?pk=${this.state.id}&page=${page}&saved=${this.state.saved}${querySearch}`)
      .then((res) => {
        let data = res.data
        let rules = data.results
        rules.forEach((x, i) => (x['index'] = i))
        // Questo 500 va aggiornato in base al page size
        this.setState({
          rules: rules,
          rulePages: Math.ceil(data.count / 500),
        })

        if (rules.length === 0 && !queryParams) {
          // Non ho regole quindi creo una lista con una regola
          this.handleFirstRule()
        }

        if (rules.length === 0 && queryParams) {
          // Non ho regole dopo aver filtrato
          notify("Non vi sono regole", "warning")
        }

      })
  }

  fetchRules(res1, page, queryParams=null) {
    // I figli passano null perchè non hanno res1, quindi lo ricostruisco dallo stato
    if (res1 == null) res1 = { seo: this.state.seo }
    // Fetcho le regole partendo da una pagina

    // Preparo il querystring per la ricerca
    let querySearch = ''
    if (queryParams) {
      if (queryParams?.query) {
        querySearch += '&query='+queryParams.query
      }
      if (queryParams?.queryContiene) {
        querySearch += '&querycontiene='+queryParams.queryContiene
      }
      if (queryParams?.queryNonContiene) {
        querySearch += '&querynoncontiene='+queryParams.queryNonContiene
      }
    }

    if (this.state.detail_type === 'saved-dimensions') {
      this.setState({loader: true})
      api.get(`${prefix}find-group-dims/${this.state.id}`)
        .then(loginRedirect)
        .then((data) => {
          this.setState({
            loader: false,
            other_dimensions: data.filter((d) => d.id !== res1.id),
          })
        })

    } else {
      this.setState({loader: true})
      api.get(`${prefix}find-report/${this.state.id}`)
        .then(loginRedirect)
        .then((data) => {
          this.setState({
            loader: false,
            other_dimensions: data.filter((d) => d.id !== res1.id),
          })
        })
    }

    this.fetchCurrentDimensionRules(page, querySearch, queryParams)
  }

  componentDidMount() {
    Promise.all([
      api.get(`${prefix}${this.state.detail_type}/${this.state.id}/`),
      api.get(prefix + 'list-operators/'),
    ])
      .then(([res1, res2]) => {
        return Promise.all([loginRedirect(res1), loginRedirect(res2)])
      })
      .then(([res1, res2]) => {
        this.setState({
          // Per i seo filtro le regole dipendenti, non le voglio vedere qui (a prova di Lucio)
          // rules: res1.rules,
          operators: res2.operators,
          name: res1.name,
          displayName: res1.display_name,
          id: res1.id,
          seo: res1.seo,
          reportName: res1.report,
          sourceId: res1.source_id,
          linked: res1.linked,
        })

        document.title = this.state.name

        // Fetcho le regole partendo da una pagina
        this.fetchRules(res1, 1)
      })
  }

  render() {
    // TODO form da rivedere può essere semplificato evitando di ciclare sugli initialValues e modificandoli
    const removeRule = this.removeRule.bind(this)
    const handleRule = this.handleRule.bind(this)
    const reorderRules = this.reorderRules.bind(this)
    const fetchRules = this.fetchRules.bind(this)

    const otherDimensionLink = (d) => {
      // Se sono in una saved dim posso avere link solo a una saved dim
      if (this.state.detail_type === 'saved-dimensions')
        return `/reporting/saved-dimensions/${d.id}`
      // Lo stesso vale per custom dim
      return `/reporting/custom-dimensions/${d.id}`
    }

    return this.state.operators &&
    this.state.operatori_logici.length > 0 ? (
      <>
        <UserHeader/>
        <div className={'AppTaggingTool ml-3 mr-3'} style={{marginBottom: '100px'}}>
          <Card className="shadow bg-secondary">
            <CardHeader className="bg-white">
              <PageTitle
                title={this.state.name}
                link={
                  this.state.reportName
                    ? `/reporting/reports/seo/${this.state.sourceId}`
                    : null
                }
                linkText={'Torna al report'}
              />
            </CardHeader>

            <>
              <Row className="ml-5">
                <h4>
                  Altre dimensioni
                </h4>
              </Row>
              <Row  className="ml-5 mr-5">
                {this.state.other_dimensions.map((d) => (
                  <Button className="btn-white mb-3" size="sm" id={d.name}>
                    <a href={otherDimensionLink(d)} className={'mx-2'}>
                      {d.name}
                    </a>
                  </Button>
                ))}
              </Row>
              {this.state.linked ? (
                <p className={'text-left ml-5 mt-3 mb-3'}>
                  <strong>
                    <a
                      href={`/reporting/saved-dimensions/${this.state.linked.id}`}
                      title={'Dimensione salvata collegata'}
                    >
                      🔗{' '}
                      {this.state.linked.display_name}
                    </a>
                  </strong>
                </p>
              ) : (
                <>
                  {this.state.detail_type !== 'saved-dimensions' && (
                    <DetailSave
                      origin={this.state.id}
                      name={this.state.name}
                      reportName={this.state.reportName}
                    />
                  )}
                </>
              )}
              <RegexpSeoHelper
                show={this.state.showRegexHelper}
                closeRegexModal={() =>
                  this.setState({ showRegexHelper: false })
                }
              />
              <DetailSeoForm
                values={{ rules: this.state.rules }}
                loader={this.state.loader}
                removeRule={removeRule}
                handleRule={handleRule}
                fetchRules={fetchRules}
                pages={this.state.rulePages}
                detailId={this.state.id}
                detailLinkedId={this.state.linked ? this.state.linked.id : null}
                detailReport={this.state.reportName}
                detailName={this.state.name}
                detailType={this.state.detail_type}
                otherDimensions={this.state.other_dimensions}
                reorderRules={reorderRules}
                showRegexModal={() => this.setState({ showRegexHelper: true })}
                listRef={this.listRef}
              />
            </>

          </Card>
        </div>
      </>
    ) : (
      <Loading />
    )
  }
}

export default DetailReport
