import React, {useState} from 'react'
import {Accordion, Badge, Button} from 'react-bootstrap';
import {TagRow} from "./TagRow";
import {translateDimension, saveDimensionTranslations} from "../../api_methods";
import {notify} from "../../../utils/index_tagging_tool";
import {Card, CardHeader} from "reactstrap";


export default function TranslationsRow({ dimensions, tagWidth= 200, badgeWidth= 60, refreshRules, group}) {
  /**
   * List of rules, divided in dimensions, to be translated manually or automatically.
   * All changes will be set for review and then saved with the dedicated button for each dimension.
   */
  const [loading, setLoading] = useState(false)

  const translateRules = (id) => {
    setLoading(true)
    translateDimension(id, group)
      .then(() => refreshRules()).catch(() => notify("Errore nella traduzione"))
    setLoading(false)
  }

  const saveTranslations = (id) => {
    let data = {saved: group}
    saveDimensionTranslations(id, data)
      .then(() => refreshRules()).catch(() => notify("Errore nel salvataggio"))
  }

  return (
    <div className="py-2 px-3 my-4">
      {dimensions.map((d, i) => (
        <Accordion key={'accordion' + i}>
          <Card key={'card' + i}>
            <CardHeader>
              <Accordion.Toggle as={Button} variant="link" eventKey={d.dimension + i} className="w-100 text-left shadow-none">
                {d.dimension}
                {d.to_review && <Badge pill variant="warning" className="float-right">
                Da revisionare
              </Badge>}
              </Accordion.Toggle>
            </CardHeader>

            <Accordion.Collapse eventKey={d.dimension + i} key={i}>
              <div className="pb-2 px-3 my-4">
                <div className="pb-4 d-flex justify-content-center">
                  {loading ?
                    <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                    : <Button onClick={() => translateRules(d.dimension_id)}>Traduzione Automatica</Button>
                  }
                  <Button variant="success" onClick={() => saveTranslations(d.dimension_id)}>Salva modifiche</Button>
                </div>
                <div
                  className="d-flex align-items-center"
                >
                  <div style={{ width: `${badgeWidth}px` }}>
                    <b>Tipo</b>
                  </div>
                  <div style={{ width: `${tagWidth}px` }}>
                    <b>Tag IT</b>
                  </div>
                  <div style={{ width: `${tagWidth}px` }}>
                    <b>Tag EN</b>
                  </div>
                </div>
                {d.rules.map((r, i) => (
                  <TagRow rule={r} key={d.dimension + '-' + i} tagWidth={tagWidth} badgeWidth={badgeWidth}/>
                ))}
              </div>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ))}
    </div>
  )
}
