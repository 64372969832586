import React from 'react'
import HistoricalReportForm from './HistoricalReportForm'
import { BASE_URL } from '../../utils/index'
import SetTitle from '../../utils/set_title'

export default function HistoricalReport() {
	SetTitle('Historical Retrieve')
	return (
        <>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="ni ni-compass-04 text-white" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">
						Historical Retrieve
						<a href={BASE_URL + '/docs/ranking_tools/historical-retrieve/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>
					</h1>
				</div>
			</div>
			<div className="page-description">E' necessario fornire un range di date 
            e un motore di ricerca di interesse. Infine, caricare un file contente le 
            colonne "Keywords" e "Volume", e inserire un mail. Terminata l'esecuzione 
            verrà inviato un report alla mail specificata, contenente tutti i risultati
            delle serp presenti nel nostro database, filtrati usando i parametri forniti 
            in input.
            </div>
			<HistoricalReportForm />
        </>
	)
}