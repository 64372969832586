import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button, Modal } from 'react-bootstrap'

// Qui sa gabri
export default function RegexpSeoHelper({ show, closeRegexModal }) {
  const [combinazioni, setCombinazioni] = useState({
    first: '',
    second: '',
  })

  const [regex, setRegex] = useState('')

  const wordPlaceholder = 'inserisci una parola'

  /**
   * Builds a regex based on the words given
   */
  const buildRegex = (words) => {
    let regex = ''

    if (combinazioni['first'] !== '' && combinazioni['second'] !== '')
      regex =
        '(?:\\b(' +
        combinazioni['first'] +
        ')\\b.*\\b(' +
        combinazioni['second'] +
        ')\\b|\\b(' +
        combinazioni['second'] +
        ')\\b.*\\b(' +
        combinazioni['first'] +
        ')\\b|\\b(' +
        combinazioni['first'] + combinazioni['second'] +
        ')\\b)'

    setRegex(regex)
  }

  useEffect(() => buildRegex(combinazioni), [combinazioni])

  return (
    <Modal
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h4>Regex helper</h4>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={''}>
          <Row>
            <Col xs={4} className={'pb-3'}>
              <label>Combinazione 1</label>
              <input
                type="text"
                className="form-control"
                required
                placeholder={wordPlaceholder}
                value={combinazioni['first']}
                onChange={(e) =>
                  setCombinazioni({ ...combinazioni, first: e.target.value })
                }
              />
            </Col>
            <Col xs={4} className={'pb-3'}>
              <label>Combinazione 2</label>
              <input
                type="text"
                className="form-control"
                required
                placeholder={wordPlaceholder}
                value={combinazioni['second']}
                onChange={(e) =>
                  setCombinazioni({ ...combinazioni, second: e.target.value })
                }
              />
            </Col>
            <Col xs={4} className={'pb-3'}>
              <label>Regex</label>
              <input
                type="text"
                className="form-control"
                required
                value={regex}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() =>
            regex !== '' ? navigator.clipboard.writeText(regex) : ''
          }
        >
          Copia Regex
        </Button>
        <Button
          variant="danger"
          onClick={() => setCombinazioni({ first: '', second: '' })}
        >
          Reset
        </Button>
        <Button variant="secondary" onClick={() => closeRegexModal()}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
