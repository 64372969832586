import React from 'react'
import LazyLoadForm from './LazyLoadForm'
import { BASE_URL } from '../utils/index'
import SetTitle from '../utils/set_title'

export default function LazyLoad() {
	SetTitle('Lazy Load')
	return (
        <>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="ni ni-button-pause text-white" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">
						Lazy Load
						<a href={BASE_URL + '/docs/ranking_tools/lazy-load/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>
					</h1>
				</div>
			</div>
			<div className="page-description">Controlla che Google veda tutti i contenuti 
			della tua pagina, assicurati che la tua implementazione di caricamento lazy 
			carichi tutti i contenuti pertinenti ogni volta che sono visibili nell'area visibile.
            </div>
			<LazyLoadForm />
        </>
	)
}