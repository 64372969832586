import React, {useState} from 'react'
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/fontawesome-free-solid'
import {updateLanguageGroup} from "../../../api_methods";
import {notify} from "../../../../utils/index_tagging_tool";

export default function DimensionsInfo({groupInfo}) {
  let dimensions = groupInfo.dimensions
  let configuration_options = groupInfo.configuration_options ? groupInfo.configuration_options : groupInfo
  const [cloneDimensions, setCloneDimensions] = useState(
    configuration_options.clone_dimensions ? configuration_options.clone_dimensions : []
  )
  const [loading, setLoading] = useState(false)
  const [collapse, setCollapse] = useState(true)

  const changeCloneDimensions = (e) => {

    const values = e.map((v) => v.label)

    if (cloneDimensions === values) return
    setCloneDimensions(values)
  }

  const updateCloneDimensions = (e) => {
    e.preventDefault()

    const answer = window.confirm(
      'Sei sicuro/a? Tutte le regole delle dimensioni selezionate saranno clonate nei sotto gruppi, ' +
      'sovrascrivendo eventuali differenze'
    )
    if (!answer) return

    const answer2 = window.confirm(
      'Ultima chance per ripensarci...'
    )
    if (!answer2) return

    let data = {'clone_dimensions': cloneDimensions}

    setLoading(true)

    updateLanguageGroup(groupInfo.name, JSON.stringify(data))
      .then(() => setLoading(false))
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
        setLoading(false)
      })
  }

  return (
    <Card className="mt-3">
      <CardHeader className={collapse ? '' : 'pb-3'}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mb-0 ml-2">Stato sincronizzazione dimensioni</h2>
          <button
            type="button"
            className="p-2 ml-4"
            onClick={() => setCollapse(!collapse)}
          >
            <FontAwesomeIcon icon={collapse ? faChevronDown : faChevronUp} />
          </button>
        </div>
      </CardHeader>
      {!collapse && (
        <>
          <Row className="ml-3 mb-2">
            <label>Dimensioni da clonare in ogni gruppo</label>
          </Row>
          <Row className="ml-2 mb-1">
            <Col xs={6}>
              <Select
                id={`clone-dimensions-list`}
                options={dimensions
                  .filter((d) => !cloneDimensions.includes(d.name))
                  .map((d) => {
                    return { label: d.name, value: d.name }
                  })}
                placeholder={'Inserisci una dimensione'}
                className="single-select"
                classNamePrefix="react-select"
                value={cloneDimensions.map((g) => {
                  return { label: g, value: g }
                })}
                onChange={changeCloneDimensions}
                isMulti={true}
                isClearable={true}
                isSearchable={true}
              />
              <UncontrolledPopover
                placement="left"
                target="clone-dimensions-list"
                trigger="focus"
              >
                <PopoverHeader>
                  Clonazione
                </PopoverHeader>
                <PopoverBody>
                  Le dimensioni clonate hanno le regole uguali (sia tag che condizioni) in tutti i linguaggi.
                </PopoverBody>
              </UncontrolledPopover>
            </Col>
            <Col xs={6}>
              {!loading ? <Button color="success" onClick={updateCloneDimensions}>
                  Salva
                </Button>
                : <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>}
            </Col>
          </Row>
          <Row className="ml-4">
            <label style={{ fontSize: '10px' }}>
              Cliccare il tasto per salvare la scelta
            </label>
          </Row>
        </>
      )}

    </Card>
  )

}
