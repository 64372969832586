import React from 'react'
import HtmlCleanerForm from './HtmlCleanerForm'
import { BASE_URL } from '../../utils/index'
import SetTitle from '../../utils/set_title'

export default function HtmlCleaner() {
	SetTitle('Html Cleaner')
	return (
        <>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="fa fa-paint-brush" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">
						Html Cleaner
						<a href={BASE_URL + '/docs/ranking_tools/html-cleaner/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>
					</h1>
				</div>
			</div>
			<div className="page-description">
                Una volta caricati i file html verranno eliminate le prime 10 e ultime 3 righe
				di ognuno, inoltre tutti i tag h diventeranno h2.
            </div>
			<HtmlCleanerForm />
        </>
	)
}