import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import {updateLanguageRule} from "../../../api_methods";

// Campo testo che consente l'impostazione del valore di una lingua per una regola
function LanguageField({ language, rule, disabled, ratio }) {
  const [val, setVal] = useState(rule.rule[language] ? rule.rule[language] : '')
  const [invalidTag, setInvalidTag] = useState(false)

  useEffect(() => {
    // Quando cambia l'indice sincronizzo
    setVal(rule.rule[language] ? rule.rule[language] : '')
  }, [rule.id])

  const handleBlur = () => {
    // Creo un piccolo dict per mandare i dati a backend
    const data = {}
    data[language] = val

    // Aggiorno il valore per il frontend
    rule.rule[language] = val

    // Salva a backend il valore in un campo della json_rule che ha come chiave il nome della lingua, sull'onblur
    if(!invalidTag) updateLanguageRule(rule.id, data)
  }

  const handleVal = (event) => {
    // Tags should not have "," inside
    if (event.target.value.includes(',')){
      setInvalidTag(true);
    } else if(val.includes(',') && !event.target.value.includes(',')){
      setInvalidTag(false);
    }
    setVal(event.target.value)
  }

  return (
    <div style={{ display: 'inline-block', width: ratio.tag }}>
      <fieldset className="w-seo-control-field">
        <Form.Control
          type="text"
          title={'nome'}
          value={val}
          disabled={disabled}
          onChange={handleVal}
          onBlur={handleBlur}
          isInvalid={invalidTag}
          className="form-control"
        />
      </fieldset>
    </div>
  )
}

// Componente per le singole righe di una lingua (sono in pratica una traduzione del value di una regola in un'altra lingua)
// Crea tanti field quante sono le lingue selezionati
export function LanguageRow({
  showLanguages,
  selectedLanguages,
  rule,
  disabled,
  ratio,
}) {
  return (
    <>
      {showLanguages && (
        <>
          {selectedLanguages.map((lang) => (
            <LanguageField language={lang} rule={rule} disabled={disabled} ratio={ratio} />
          ))}
        </>
      )}
    </>
  )
}
