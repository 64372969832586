import React, {useEffect, useState} from "react";
import {api, csrftoken, loginRedirect, notify} from "../../../utils/index_tagging_tool";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/fontawesome-free-solid";
import {updateSavedDimension} from "../../api_methods";

// Componente per la gestione della modifica del nome della dimensione, SOLO IL NOME E NON IL DISPLAY NAME (che causerebbe problemi coi bq già esistenti)
export function DimensionName({ dimension, is_sub=false }) {
  // La possibilità di modificare va espressa dall'utente
  const [edit, setEdit] = useState(false)

  // Mantengo il nome corrente e il nome modificato dall'utente
  const [name, setName] = useState(dimension.name)

  const saveNome = () => {
    // La copia la faccio per gestire la presenza del gruppo nel serializer, non voglio che si salvi a backend, ma voglio che rimanga visibile a frontend
    dimension.name = name
    const dimCopy = JSON.parse(JSON.stringify(dimension))
    dimCopy.name = name.replace(` (${dimension.group.name})`, '')

    updateSavedDimension(dimension, JSON.stringify(dimCopy))
      .then((data) => {
        if (typeof data === 'string') {
          notify('Esiste già una dimensione con questo nome', 'warning')
          return
        }
        console.log(data)
        setEdit(false)
      })
      .catch((e) => {
        notify(e.message, 'error')
      })
  }

  useEffect(() => {
    // Quando il nome nelle props cambia, devo rendere coerente il campo
    setName(dimension.name)
  }, [dimension.name])

  return (
    <>
      {edit ? (
        <>
          <input
            type="text"
            className="form-control"
            style={{ width: '80%', display: 'inline' }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />{' '}
          <span
            style={{
              color: 'green',
              cursor: 'pointer',
            }}
            onClick={() => saveNome()}
          >
            ✔
          </span>
        </>
      ) : (
        <span>
          <Link to={`/reporting/saved-dimensions/${dimension.id}`}>
            {dimension.name}
          </Link>{' '}
          {!is_sub ? <FontAwesomeIcon icon={faEdit} onClick={() => setEdit(true)} /> : ''}
        </span>
      )}
    </>
  )
}