import React, { useEffect, useState } from 'react'
import {api, formatDateTime} from '../../utils'
import { notify } from '../../utils/notify'
import { Badge, Button, Table } from 'react-bootstrap'
import PageSelector from '../../components/PageSelector'
import Loading from '../../tagging_tool/components/Loading'
import { useParams, useHistory } from 'react-router-dom'
import {
	Col,
	CardBody,
	Row,
	Card,
	CardHeader
} from "reactstrap";

const TableContext = React.createContext()
const PageContext = React.createContext()
const pageSize = 50

function TableAlerts() {
  const {
    data, 
    history,
    siteUrl
  } = React.useContext(TableContext)

  if (data && data.length > 0) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            {Object.keys(data[0]).map((k, thi) => (
              <th key={thi}>{k}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              {Object.keys(d).map((k, ii) => {
                if (k == 'alerts_types' && d['alerts_types'] && d['alerts_types'].length > 0) {
                  let alertTypes = d['alerts_types'].split(',')
                  return (
                    <td key={ii}>
                      {
                        alertTypes.map((at, atIndex) => {
                          return (
                            <Badge key={atIndex} pill variant="info">{at}</Badge>
                          )
                        })
                      }
                    </td>
                  )                  
                }
                else if (k == 'n_alerts') {
                  return (<td key={ii}>{d[k] || 0}</td>)
                }
                else if (k == 'actions') {
                  if (d['alerts_types'] && d['alerts_types'].length > 0)
                    return (
                      <td key={ii}>
                        <Button variant="primary" size="sm" 
                        onClick={() => history.push(`/seo-monitoring/alert-events/${siteUrl?.id}/${d['wr_crawl_day_date']}`)}>
                          Dettagli
                        </Button>
                      </td>
                    )
                }
                return (<td key={ii}>{d[k]}</td>)
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
  return null
}

function AlertEventTable() {
  const history = useHistory()
  const params = useParams()
  const [alertEvents, setAlertEvents] = useState([])
  const [siteUrl, setSiteUrl] = useState({
    url: '',
    secret_name: '',
    notification_emails: '',
    active: false,
  })
  const [loading, setLoading] = useState(true)

  const [canPreviousPage, setCanPreviousPage] = useState(false)
  const [canNextPage, setCanNextPage] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [pageIndex, setPageIndex] = useState(1)

  const getAlertEvents = (id, page = null) => {
    const urlPageParams = (page) ? '?page='+page+'&' : '?'
    const urlSiteUrlParams = `site_url_id=${id}`
    api.get(`/seo-monitoring/alert-events/summary/${urlPageParams}${urlSiteUrlParams}`)
      .then(response => {
        setCanPreviousPage(response.data?.previous != null)
        setCanNextPage(response.data?.next != null)
        setPageCount((response.data?.count) ? Math.ceil(response.data.count/pageSize) : 1)
        let responseData = (response.data?.count) ? response.data.results : response.data
        if (responseData.length > 0)
          responseData = responseData.map((obj) => {
            return {
                ...obj,
              'actions': ''
            }
          })
        setAlertEvents(responseData)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
        notify(
          err,
          'error'
        )
      })
  }

  const getSiteUrl = (id) => {
    api.get(`/seo-monitoring/site-urls/${id}/`)
      .then(response => {
        setSiteUrl(response.data)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const gotoPage = (page) => {
    setPageIndex(page)
  }
  const previousPage = () => {
    if (pageIndex > 1) {
      setPageIndex(pageIndex - 1)
    }
    else {
      setPageIndex(1)
    }
  }
  const nextPage = () => {
    setPageIndex(pageIndex+1)
  }

  useEffect(() => {
    setLoading(true)
    if (params?.siteUrlId) {
      getSiteUrl(params.siteUrlId)
      getAlertEvents(params.siteUrlId)
    }
  }, [])

  useEffect(() => {
    getAlertEvents(params.siteUrlId, pageIndex)
  }, [pageIndex])


  return (
    <>
    {(params?.siteUrlId) ? (
    <Row style={{marginBottom: "100px"}}>
      <div className="col pb-5">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
          <Row style={{ marginLeft: "0px" }}>
            <Col md={10}>
              <h1 className="mb-0">Alert Events {siteUrl?.url || ''}</h1>
            </Col>
          </Row>
          </CardHeader>
          <CardBody>
            { (loading) ? (
              <Loading />
            ) : (
            <TableContext.Provider
              value={{
                data: alertEvents,
                history: history,
                siteUrl: siteUrl
              }}
            >
              <PageContext.Provider
                value={{
                  gotoPage,
                  previousPage,
                  nextPage,
                  canPreviousPage,
                  canNextPage,
                  pageCount,
                  pageIndex,
                }}
              >
                <PageSelector context={PageContext} />
              </PageContext.Provider>

              <TableAlerts />
              
            </TableContext.Provider>
            )}
          </CardBody>
        </Card>
      </div>
    </Row>
    )
    : null}
  </>)
}
export default AlertEventTable