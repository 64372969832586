import React, { useState, Fragment, useEffect } from 'react'
import { Form, FormText } from 'react-bootstrap'
import {Row, Col, Button} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/fontawesome-free-solid'
import {
  prefix,
  loginRedirect,
  notify,
} from '../../../utils/index_tagging_tool'
import {
  api,

} from '../../../utils/index'

// Componente isolato per la modifica del nome di una dimensione, che modifica solo il nome e NON IL DISPLAY NAME (causerebbe problemi con BQ)
function DimensionName({ dimension }) {
  // Rende visibile il textbox per cambiare il nome
  const [edit, setEdit] = useState(false)

  // Mantiene salvate le modifiche dell'utente
  const [name, setName] = useState(dimension.name)

  // Salva a db le modifiche quando indicato dall'utente
  const saveNome = () => {
    dimension.name = name

    api.put(`${prefix}custom-dimensions/${dimension.id}/`, dimension)
      .then(loginRedirect)
      .then((data) => {
        if (typeof data === 'string') {
          notify('Esiste già una dimensione con questo nome', 'warning')
          return
        }
        console.log(data)
        setEdit(false)
      })
      .catch((e) => {
        notify('La comunicazione con il server è stata interrotta', 'error')
      })
  }

  useEffect(() => {
    // Aggiorna il nome quando cambia la lista (e quindi la dimensione di questa riga)
    setName(dimension.name)
  }, [dimension.name])

  return (
    <>
      {edit ? (
        <>
          <input
            type="text"
            className="form-control"
            style={{ width: '80%', display: 'inline' }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />{' '}
          <span
            style={{
              color: 'green',
              cursor: 'pointer',
            }}
            onClick={() => saveNome()}
          >
            ✔
          </span>
        </>
      ) : (
        <span>
          <a
            href={`/reporting/custom-dimensions/${dimension.id}`}
            target="_blank"
          >
            {name}
          </a>
          {
            // Su richiesta di gabri (toschi) mostro il display name della colonna (quello che si vedrà poi su BQ o nel simula)
            ` (${dimension.display_name}) `
          }
          <FontAwesomeIcon icon={faEdit} onClick={() => setEdit(true)} />
        </span>
      )}
    </>
  )
}

// Singolo elemento della lista, consente di operare sulla singola dimensione e modificarla/cancellarla
function DimensionRow({
  dimension,
  savedDimensions,
  reportName,
  dimensions,
  i,
  reFetch,
  seo,
}) {
  // Apre il selettore per editare il linking
  const [editLinking, setEditLinking] = useState(false)

  // Apre il selettore per modificare il tipo
  const [editType, setEditType] = useState(false)

  // Stato dell'ultima operazione effettuata
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    // Resetto tutto allo stato iniziale per evitare problemi quando cambia l'indice (per aver aggiunto o rimosso una dimensione)
    setError(false)
    setSuccess(false)
    setEditLinking(false)
    setEditType(false)
  }, [dimension])

  // Il serializer ritorna l'id della linkata, da questo recupero il nome usando la lista di saved dimensions
  const getLinkedName = () => {
    if (savedDimensions.length === 0) return ''
    const sd = savedDimensions.filter((x) => x.id === dimension.linked.id)[0]
    return sd.name
  }

  // Rendo il nome del tipo più leggibile per i seo
  const mapBqMode = (s) => {
    if (s === 'repeated') return 'Lista'
    return 'Normale'
  }

  // Salva il link/elimina un link tra custom e saved dimension, passandogli tutta la dimensione come oggetto JSON
  const saveLinkedDimension = (id) => {
    setSuccess(false)
    setError(false)

    // Aggiorno linking della dimensione
    api.put(prefix + `dimension-linking/`, dimension)
      .then(loginRedirect)
      .then((data) => {
        if (typeof data !== 'object') {
          notify(
            'Qualcosa è andato storto, ricontrollare o contattare un innovation',
            'error'
          )
          setError(true)
        }
        dimensions[i] = data // Rendo coerente la lista di dimensioni
        setSuccess(true)
      })
  }

  const handleSelect = (e) => {
    // Handler della selezione del linking
    const id = e.target.value ? Number(e.target.value) : null
    if (id) {
      dimension.linked = id
      dimensions[i].linked = id
      saveLinkedDimension(id)
    } else {
      dimension.linked = null
      dimensions[i].linked = null
      saveLinkedDimension(null)
    }
  }

  const handleSelectType = (e) => {
    // Handler della selezione della bq_mode (campo lista o non)
    dimension.bq_mode = e.target.value
    saveLinkedDimension(null)
  }

  const handleThanos = (e) => {
    // Handler del booleano thanos, per rendere la dimensione "negativa" (quindi portare alla eliminazione di keyword quando si verificano le regole)
    dimension.thanos = e.target.checked
    saveLinkedDimension(null)
  }

  const handleRemove = (e) => {
    // Rimuovo la dimensione, con popuo che si accerta che tu sia sicuro, poi aggiorna la lista coerentemente
    e.preventDefault()

    // Conferma dell'utente
    const answer = window.confirm(
      'Sei sicuro? La dimensione e tutte le sue regole (nonchè le regole di altre dimensioni che dipendono da questa) verranno rimosse definitivamente'
    )
    if (!answer) return

    let formData = new FormData()
    formData.append('displayName', dimension.display_name)
    formData.append('reportName', reportName)

    api.delete(prefix + 'custom-dimensions/', {
      data: formData,
    })
      .then(loginRedirect)
      .then((data) => {
        if (typeof data != 'number') {
          notify(
            'Qualcosa è andato storto, ricontrollare o contattare un innovation',
            'error'
          )
        } else {
          notify('Dimensione rimossa con successo', 'success')
          reFetch(dimension)
        }
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
      })
  }

  return (
    <Fragment>
      <Row>
        <Col className={'text-left'}>
          {dimension ? (
            <DimensionName dimension={dimension} reportName={reportName} />
          ) : (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </Col>
        <Col className={'text-center'}>
          {editType ? (
            <Form.Control
              className="copy-my-on-change"
              as="select"
              style={{ display: 'inline', width: '80%' }}
              defaultValue={dimension.bq_mode}
              isValid={success}
              isInvalid={error}
              onChange={handleSelectType}
            >
              {/* Opzioni hardcodate come da best practice, ovviamente */}
              <option value={'repeated'}>Lista</option>
              <option value={'nullable'}>Normale</option>
            </Form.Control>
          ) : (
            <Fragment>
              {mapBqMode(dimension.bq_mode)}{' '}
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => setEditType(true)}
              />
            </Fragment>
          )}
        </Col>
        <Col className={'text-center'}>
          <input
            type="checkbox"
            style={{ verticalAlign: 'middle' }}
            defaultChecked={dimension.thanos}
            onClick={handleThanos}
          />
        </Col>
        <Col className={'text-right'}>
          {seo && (
            <Fragment>
              {editLinking ? (
                <Form.Control
                  className="copy-my-on-change"
                  as="select"
                  style={{ display: 'inline', width: '80%' }}
                  defaultValue={
                    dimension.linked ? dimension.linked.id : 'not-linked'
                  }
                  isValid={success}
                  isInvalid={error}
                  onChange={handleSelect}
                >
                  {savedDimensions.map((sd) => (
                    <option value={sd.id} key={sd.id}>
                      {sd.name}
                    </option>
                  ))}
                  <option value={'not-linked'}>Nessuno</option>
                </Form.Control>
              ) : (
                <Fragment>
                  {dimension.linked ? getLinkedName() : 'Nessuno'}{' '}
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => setEditLinking(true)}
                  />
                </Fragment>
              )}
            </Fragment>
          )}{' '}
          <strong
            onClick={(e) => handleRemove(e)}
            style={{ cursor: 'pointer' }}
          >
            ❌
          </strong>
        </Col>
      </Row>
    </Fragment>
  )
}

// Bloccone che gestisce i linking e altre caratteristiche di una custom dim di un report, e consente anche la creazione di una nuova dim (eventualmente linkata)
function DimensionLinking({ dimensions, savedDimensions, reportName, seo }) {
  const [loading, setLoading] = useState()

  // Un bellissimo force_component_update
  const reloadSelf = React.useReducer(() => ({}), {})[1]

  // Nome dato dall'utente e relativi errori/check sulla validità per dare feedback all'utente
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [nameValid, setNameValid] = useState('')

  // Dimensione linkata selezionata
  const [selectedSaved, setSelectedSaved] = useState(null)

  const evaluateName = () => {
    // Controlla che il nome sia valido, ovvero che non sia vuoto e che non abbia il nome di un'altra dimensione
    if (name == '') setNameError('Non puoi inserire un nome vuoto')
    else if (
      dimensions
        .map((x) => x.display_name.toLowerCase())
        .includes(name.toLowerCase())
    ) {
      setNameError(`La dimensione ${name} è già presente`)
    } else {
      setNameValid('Il nome selezionato è disponibile')
    }
  }

  // Aggiunge una dimensione, in base ai parametri passati dall'utente (nome, bqMode) e dal nome del report che arriva dal padre
  const handleAdd = (e) => {
    e.preventDefault()

    if (name == '') {
      setNameError('Non puoi inserire un nome vuoto')
      return
    }

    let formData = new FormData()
    formData.append('displayName', name)
    formData.append('reportName', reportName)
    formData.append('bqMode', 'repeated')

    // Aggiungo la saved solo se selezionata
    selectedSaved && formData.append('saved', selectedSaved)

    api.post(prefix + 'custom-dimensions/', formData)
      .then(loginRedirect)
      .then((data) => {
        if (typeof data != 'object') {
          notify('Esiste già una dimensione con questo nome', 'warning')
        } else {
          notify(`${name} aggiunta con successo`, 'success')
          dimensions.push(data)
          setName('')
          setSelectedSaved(null)
          document.getElementById('da_associare').value = 'Nuova' // Resetto la select
          setNameError('')
          setNameValid('')
          reloadSelf()
        }
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
      })
  }

  const handleSelect = (event) => {
    // Il selettore consente di linkare una dimensione direttamente alla creazione, con un nome ad essa identico
    let target = event.target.value
    if (!target || target === 'Nuova') {
      setSelectedSaved(null)
      setName('')
    }
    target = JSON.parse(target)
    setSelectedSaved(target.id)
    // I nomi delle dimensioni salvate arrivano dal serializer con il nome del gruppo tra parentesi, lo tolgo prima di fare il "setname"
    target = target.name.split(` (${target.group.name}`)[0]
    setName(target)
  }

  const reFetch = (dimension) => {
    // Usato quando viene cancellata una dimensione, quindi cambia la lista di dimensioni
    dimensions.splice(dimensions.indexOf(dimension), 1)
    reloadSelf()
  }

  return (
    <div className="Content-box mb-4">
      <h2 className="pb-3">Dimensioni</h2>
      <p className="pb-3">
        Da qui è possibile gestire le dimensioni del report,
        aggiungendole/rimuovendole o modificando il loro collegamento ad una
        dimensione salvata.{' '}
        <strong>Ogni nome di dimensione e regola è un link</strong> che consente
        di accede a pagine di modifica o dettaglio dell'elemento cliccato.
      </p>
      <Row className={'pb-3'}>
        <Col  className={'text-left'}>
          <label style={{ fontSize: '1.2rem' }}>
            Name
          </label>
        </Col>
        <Col  className={'text-center'}>
          <label style={{ fontSize: '1.2rem' }}>
            BQ
          </label>
        </Col>
        <Col  className={'text-center'}>
          <label style={{ fontSize: '1.2rem' }}>
            THANOS
          </label>
        </Col>
        <Col  className={'text-right'}>
          <label style={{ fontSize: '1.2rem' }}>
            {seo ? 'Linking/Remove' : 'Remove'}
          </label>
        </Col>
      </Row>
      <Form>
        {dimensions.map((dim, i) => (
          <DimensionRow
            key={i}
            dimension={dim}
            savedDimensions={savedDimensions}
            reportName={reportName}
            reFetch={reFetch}
            dimensions={dimensions}
            i={i}
            seo={seo}
          />
        ))}
        <Row className={'mt-4'}>
          <Col xs={3}>
            <label htmlFor={'nome_nuova'}>Nome</label>
            <input
              id={'nome_nuova'}
              type="text"
              className="form-control"
              onBlur={evaluateName}
              value={name}
              onChange={(e) => {
                setNameValid('')
                setNameError('')
                setName(e.target.value)
              }}
            />
          </Col>
          <Col xs={3} className="ml-1">
            <label htmlFor={'da_associare'}>Dimensioni da associare</label>
            <Form.Control
              id={'da_associare'}
              defaultValue={null}
              as="select"
              onChange={handleSelect}
            >
              <option value={null} style={{ color: 'gray' }}>
                {'Nuova'}
              </option>
              {savedDimensions.map((dim, ix) => (
                <option key={ix} value={JSON.stringify(dim)}>{dim.name}</option>
              ))}
            </Form.Control>
          </Col>
          <Col xs={2} className="mt-auto">
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <Button
                color="warning"
                onClick={handleAdd}
                className="px-0"
                style={{ width: '100%' }}
              >
                Aggiungi
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
          {nameError !== '' && (
              <FormText
                style={{ color: 'red', fontWeight: 'bold' }}
                align={'left'}
              >
                {nameError}
              </FormText>
            )}
          {nameValid !== '' && (
              <FormText style={{ color: 'green' }} align={'left'}>
                {nameValid}
              </FormText>
          )}
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default DimensionLinking
