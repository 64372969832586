import React, { useEffect, useState, useContext } from 'react'
import {Spinner} from 'react-bootstrap'
import {
	Col,
	FormGroup,
	Form,
	FormText,
	CardBody,
	Row,
	Button,
	Card,
	CardHeader,
	Input,
	Container
} from "reactstrap";

import CharCheckModal from "../../components/molecules/CharCheckModal";
import TagsInput from '../../components/TagsInput'
import { api } from '../../utils/index'
import { ToastContainer, toast } from 'react-toastify'
import { notify } from '../../utils/notify'

const KeywordIdeasForm = () => {
	const [keywords, setKeywords] = useState([])
	const [locations, setLocations] = useState([])
	const [file, setFiles] = useState(null)
	const [mail, setMails] = useState('')
	const [location, setLocation] = useState('')
	const [loading, setLoading] = useState(false)
	
	// Modal check caratteri
	const [showCharCheck, setShowCharCheck] = useState(false)
	const [errCharList, setErrCharList] = useState([])
	const [acceptedCharCheck, setAcceptedCharCheck] = useState(false)

	const confirmEvaluation = (e) => {
		e.preventDefault()
		setShowCharCheck(false)
		setAcceptedCharCheck(true)
	}

	const handleSubmit = (event) => {
		if (event)
			event.preventDefault()

		let formData = new FormData()
		formData.append('keywords', keywords)
		formData.append('location', location)
		formData.append('mail', mail)
		formData.append('file', file)
		acceptedCharCheck && formData.append('accepted', acceptedCharCheck)

		if((mail == '') || ((file=='null' || file==null) && keywords=='')){
			notify(
				'Mail, file o keyword mancanti',
				'error'
			  )
			  return
		}
		setLoading(true)

		api
			.post('keyword-ideas/', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if (typeof response.data !== 'undefined') {
					if (response.data?.type === 'application/force-download') {
						let url = window.URL.createObjectURL(response.data)
						let a = document.createElement('a')
						a.href = url
						a.download = 'report.xlsx'
						a.click()
						notify(
							"Request sent.",
							'success'
							)
					} else {
						console.warn(response.data)
						notify(
							"Request sent.",
							'success'
							)
					}
				} else {
					notify(
						"Could not retrieve datas.",
						'error'
						)
					console.warn('Could not retrieve datas')
				}
					setLoading(false)
					setKeywords([])
					setMails([])
					setFiles(null)
			})
			.catch(err => {
				if (err?.response?.data) {
					if (err.response.data?.message.includes('ERROR Encoding keywords')) {
					  setErrCharList(err.response.data?.errors);
					  setShowCharCheck(true);
					}
					else {
						notify(err.response.data, 'error')
						setKeywords([])
						setMails([])
						setFiles(null)
					}
				}
			})
	}

		const icon = <i className="fa-solid fa-plus"></i>

	const selectedKeywords = keywords => {
		setKeywords(keywords)
	}

	const validateEmail = email => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(email)
	  }


	useEffect(() => {
		api
		.get('motori-ricerca/')
		.then(res => {
			let locations = []
			try {
				for (let i = 0; i < res.data.length; i++) {
					locations.push(res.data[i].locale)
				}
			} catch {
				console.warn('Error while parsing locations')
			}
			locations = Array.from(new Set(locations))
			setLocation('it-it')
			setLocations(locations)
		})
		.catch(err => console.error(err))
	}, [])

	useEffect(() => {
		if(acceptedCharCheck) {
			handleSubmit()
		}
	}, [acceptedCharCheck])

		return (
			<>
				<CharCheckModal show={showCharCheck} onHide={setShowCharCheck} onSubmit={confirmEvaluation} errCharList={errCharList}/>
				{loading ? (
					<Spinner animation="grow" />
				) : (
				<Row>
				<div className="col pb-5">
				<Card className="bg-secondary shadow">
					<CardHeader className="bg-white border-0">
						<Row style={{ marginLeft: "0px" }}>
							<Col md={10}>
								<h1 className="mb-0">Keyword Ideas</h1>
							</Col>
						</Row>
					</CardHeader>
					<CardBody>
						<Form onSubmit={handleSubmit} id="idea-form" action="">
							<Row>
								<Col md={6}>
									<FormGroup>
										<label htmlFor="file" style={{ display: 'block' }}>
											File
										</label>
										<Input
											className="form-control-alternative"
											id={'file'}
											type={'file'}
											style={{ border: '0' }}
											accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
											onChange={event => {
												setFiles(event.target.files[0])
											}}
										/>
										<FormText color="muted" align={'left'}>
											Carica un file contenente le tue keywords. <br />
											<b>Il file deve contenere almeno una colonna "Keyword"</b>
										</FormText>
									</FormGroup>
									<FormGroup>
										<label htmlFor="location" style={{ display: 'block' }}>
											Location
										</label>
										<Input
											type="select"
											className="form-control-alternative"
											id="location"
											value={location}
											required
											style={{ height: '44px' }}
											onChange={event => {
												setLocation(event.target.value)
											}}
										>
											{locations.map(location => {
												return (
													<option key={location} value={location}>
														{location}
													</option>
												)
											})}
										</Input>
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup required>
									<label htmlFor="keywords" className="form-control-label">
										Keywords
									</label>
									<TagsInput
										className="form-control-alternative"
										id='keywords'
										selectedTags={selectedKeywords}
										tags={keywords}
										type='text'
									/>
									<FormText color="muted" align={'left'}>
										Inserisci eventuali keywords aggiuntive. <br />
										<b>Premi invio dopo ogni nuovo inserimento.</b>
									</FormText>
									</FormGroup>
									<FormGroup>
										<label htmlFor="mail" className="form-control-label">
											Mail
										</label>
											<TagsInput
												className="form-control-alternative"
												id="email"
												selectedTags={setMails}
												validator={validateEmail}
												tags={mail}
												type="text"
											/>
									<FormText color="muted" align={'left'}>
										<b>
											Inserisci una mail a cui verrà inviato il report fiinale.
										</b>
									</FormText>
									</FormGroup>
								</Col>
							</Row>
							<Row style={{display: "flex", justifyContent: "flex-end", marginRight:"50px"}}>
							<Button color="info" size="lg" onClick={handleSubmit} >Submit Report</Button>
							</Row>
						</Form>
					</CardBody>
				</Card>
				</div>
				</Row>
				)}
				<ToastContainer />
			</>
		)
}

export default KeywordIdeasForm
