import React, { useEffect, useState, useContext } from 'react'
import {Spinner} from 'react-bootstrap'
import {
	Col,
	FormGroup,
	Form,
	FormText,
	CardBody,
	Row,
	Button,
	Card,
	CardHeader,
	Input,
	Container
} from "reactstrap";

import { ToastContainer, toast } from 'react-toastify'
import { notify } from '../../utils/notify'
import TagsInput from '../../components/TagsInput'
import { api } from '../../utils/index'

const HtmlCleanerForm = () => {
  const [file, setFiles] = useState(null)
  const [mail, setMails] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    
    if(file==null || mail==''){
      notify(
        'File o mail mancanti',
        'error'
      )     
      return
    }

    let formData = new FormData()
    for (let index = 0; index < file.length; index++) {
        formData.append("files", file[index]);
      }
    formData.append('mail', mail)

    setLoading(true)
    api
      .post('html-cleaner/', formData, {
      })
      .then((response) => {
        if (typeof response.data === 'undefined') {
          notify(
            'Could not retrieve datas.',
            'error'
          )
          console.warn('Could not retrieve datas')
        }else{
          notify(
            response.data,
            'success'
          )
        }
        setMails([])
        setFiles(null)
        setLoading(false)
      })
      .catch(err => {
        notify(
          err.response.data,
          'error'
        )
        setMails([])
        setFiles(null)
        setLoading(false)
      })
  }

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  return (
    <>
      {loading ? (
        <Spinner animation="grow" />
      ) : (
      <div className="col pb-5">
			<Card className="bg-secondary shadow">
					<CardHeader className="bg-white border-0">
						<Row style={{ marginLeft: "0px" }}>
							<Col md={10}>
								<h1 className="mb-0">Html Cleaner</h1>
							</Col>
						</Row>
					</CardHeader>
        <CardBody>
        <Form onSubmit={handleSubmit} id="idea-form" action="">
          <Row>
            <Col md={6}>
            <form
                className="uploader"
                encType="multipart/form-data"
            >
            <input 
                type="file" 
                id="file" 
                multiple 
                accept = ".html"
                onChange={event => {
                    setFiles(event.target.files)
                }}/>
            </form>
              <FormGroup style={{marginTop:"1em"}}>
                  <label htmlFor="mail" style={{ display: 'block' }}>
                    Mail
                  </label>
                  <TagsInput
                    className="form-control-alternative"
                    id="email"
                    selectedTags={setMails}
                    validator={validateEmail}
                    tags={mail}
                    type="text"
                  />
                  <FormText color="muted" align={'left'}>
                    <b>
                      Inserisci una mail a cui verrà inviato il report fiinale.
                    </b>
                  </FormText>
                </FormGroup>
            </Col>
          </Row>
          <Row style={{display: "flex", justifyContent: "flex-end", marginRight:"50px"}}>
            <Button color="info" size="lg" onClick={handleSubmit} >Submit Report</Button>
          </Row>
        </Form>
        </CardBody>
			</Card>
			</div>
      )}
      <ToastContainer />
    </>
  )
}

export default HtmlCleanerForm
