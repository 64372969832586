import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function Formula({ context }) {
  const { formula, setFormula, formulas } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Formula
      </label>
      <Input
        className="form-control-alternative"
        type="select"
        id="formula"
        value={formula}
        //required={true}
        onChange={e => setFormula(e.target.value)}
      >
        {formulas.map(m => (
          <option value={m} key={m}>
            {m}
          </option>
        ))}
      </Input>
      <FormText color="muted" align={'left'}>
        Tipo di formula per il calcolo visibility
      </FormText>
    </FormGroup>
  )
}
