import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function Validity({ context }) {
  const { validity, setValidity } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Intervallo di validità: <b>{validity}</b>
      </label>
      <Input
        type="range"
        min={0}
        max={15}
        value={validity}
        style={{ width: '200px', paddingLeft: '0px', paddingRight: '0px' }}
        onChange={(e) => setValidity(Number(e.target.value))}
      />
      <FormText color="muted" align={'left'}>
        Intervallo temporale per cui considerare validi i dati. Ad esempio, settando 7 come
        intervallolo di validità, verranno considerati dati validi quelli non più vecchi di una
        settimana rispetto alla data di caricamento dello studio kw. Minimo: 0, massimo: 15,
        Default:5
      </FormText>
    </FormGroup>
  )
}
