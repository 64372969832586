import React, { useEffect, useState } from 'react'
import {api, formatDateTime} from '../../utils'
import { notify } from '../../utils/notify'
import { Badge, Button, Table } from 'react-bootstrap'
import PageSelector from '../../components/PageSelector'
import Loading from '../../tagging_tool/components/Loading'
import { Link, useHistory } from 'react-router-dom'
import {
	Col,
	CardBody,
	Row,
	Card,
	CardHeader
} from "reactstrap";

const TableContext = React.createContext()
const PageContext = React.createContext()
const pageSize = 50

function TableSiteUrls() {
  const {
    data, history
  } = React.useContext(TableContext)

  const excluded_cols = [
    'secret_name',
    'filters',
    'notification_emails',
    'siteurl_sitemaps',
    'alert_events_active',
  ]

  const crawler_status_variant = {
    ERROR: 'danger',
    READY_TO_ANALYZE: 'primary',
    RUNNING: 'warning',
    ANALYZED: 'success'
  }

  const formatColValues = (obj, colName, index) => {
    if (colName == 'id') {
      return (<td width="20" key={index}>{obj[colName]}</td>)
    }
    else if (colName == 'name') {
      return (
        <td key={index}>
          <Link to={'/seo-monitoring/site-urls/edit/' + obj['id']}>
            <span>{obj[colName]}</span>
          </Link>
        </td>
      )
    }
    else if (colName == 'active') {
      return (
        <td key={index}>
          <Badge pill variant={(obj[colName]) ? 'success' : 'warning'}>
            {(obj[colName]) ? 'ACTIVE' : 'INACTIVE'}
          </Badge>
        </td>
      )
    }
    else if (colName == 'crawler_status') {
      return (
        <td key={index}>
          <Badge pill variant={crawler_status_variant[obj[colName]]}>{obj[colName]}</Badge>
        </td>
      )
    }
    else if (colName == 'created') {
      return (
        <td key={index}>
          {formatDateTime(obj[colName])}
        </td>
      )
    }
    else if (colName == 'actions') {
      return (
        <td key={index}>
          <Button variant="danger" 
          size="sm" 
          onClick={() => history.push(`/seo-monitoring/alert-events/${obj['id']}`)}>
            Alerts
          </Button>
        </td>
      )
    }
    
    else {
      return (<td key={index}>{obj[colName]}</td>)
    }
  }

  if (data && data.length > 0) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            {Object.keys(data[0]).map((k, thi) => 
              (excluded_cols.includes(k)) ? null : <th key={thi}>{k}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              {Object.keys(d).map((k, ii) => {
                return ((excluded_cols.includes(k)) ? null : formatColValues(d, k, ii))
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
  return null
}

function SiteUrlListTable() {
  const history = useHistory()

  const [siteUrls, setSiteUrls] = useState([])
  const [loading, setLoading] = useState(true)

  const [canPreviousPage, setCanPreviousPage] = useState(false)
  const [canNextPage, setCanNextPage] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [pageIndex, setPageIndex] = useState(1)

  const getSiteUrls = (page = null) => {
    api.get(`/seo-monitoring/site-urls/${(page) ? '?page='+page : ''}`)
      .then(response => {
        setLoading(false)
        setCanPreviousPage(response.data?.previous != null)
        setCanNextPage(response.data?.next != null)
        setPageCount((response.data?.count) ? Math.ceil(response.data.count/pageSize) : 1)
        let reponseData = (response.data?.count) ? response.data.results : response.data
        reponseData = reponseData.map((obj) => {
          return {
              ...obj,
            'actions': ''
          }
        })
        setSiteUrls(reponseData)
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
        notify(
          err,
          'error'
        )
      })
  }

  const gotoPage = (page) => {
    setPageIndex(page)
  }
  const previousPage = () => {
    if (pageIndex > 1) {
      setPageIndex(pageIndex - 1)
    }
    else {
      setPageIndex(1)
    }
  }
  const nextPage = () => {
    setPageIndex(pageIndex+1)
  }

  useEffect(() => {
    setLoading(true)
    getSiteUrls()
  }, [])

  useEffect(() => {
    getSiteUrls(pageIndex)
  }, [pageIndex])

  const icon = <i className="fa-solid fa-plus"></i>

  return (
    <Row style={{marginBottom: "100px"}}>
      <div className="col pb-5">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
          <Row style={{ marginLeft: "0px" }}>
            <Col md={8}>
              <h1 className="mb-0">Site Urls</h1>
            </Col>
            <Col md={4} style={{textAlign: "right"}}>
              <Button style={{marginRight: "14px"}} variant="primary" onClick={() => history.push('/seo-monitoring/site-urls/edit')} icon={icon}>
                + Add new
              </Button>
            </Col>
          </Row>
          </CardHeader>
          <CardBody>
            { (loading) ? (
              <Loading />
            ) : (
            <TableContext.Provider
              value={{
                data: siteUrls,
                history: history
              }}
            >
              <PageContext.Provider
                value={{
                  gotoPage,
                  previousPage,
                  nextPage,
                  canPreviousPage,
                  canNextPage,
                  pageCount,
                  pageIndex,
                }}
              >
                <PageSelector context={PageContext} />
              </PageContext.Provider>

              <TableSiteUrls />
              
            </TableContext.Provider>
            )}
          </CardBody>
        </Card>
      </div>
    </Row>
  )
}
export default SiteUrlListTable