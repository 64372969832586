import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'


import {
	Card,
	CardHeader,
	Row,
  Col
} from "reactstrap";
import {api} from "../utils";
import Loading from "../tagging_tool/components/Loading";
import SetTitle from '../utils/set_title'

export default function StudiokwEmailAlertsRead() {
  SetTitle('Ranking Studiokw')
	const params = useParams()
  const [seen, setSeen] = useState(false)
  const [loading, setLoading] = useState(false)

	const readEmail = () => {
    setLoading(true)

		api.get("/studiokw/email-alerts/"+params.id+'/')
      .then(response => {
          setLoading(false)

          if (response?.data?.seen)
            setSeen(response?.data?.seen)
      })
      .catch(err => {
        setLoading(false)
        setSeen(false)
      })
	}

  useEffect(() => {
    readEmail()
  }, [])

	return (
		<>
      <div className="bg-gradient-info secondary-page-title" >
        <div md={12} className="form-title">
          <i className="ni ni-compass-04 text-white" style={{color: "white", fontSize: "35px"}}></i>
          <h1 className="page-title">Ranking Studiokw</h1>
        </div>
      </div>
      <div className="page-description">
        <div className="header pb-4 pt-5 pt-md-8">
          <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row style={{ marginLeft: "0px" }}>
                  <Col md={10}>
                    {(loading) ? (
                      <Loading />
                    ) : (seen) ? (
                      <h1 className="mb-0">Lettura mail confermata</h1>
                    ) : (
                      <h1 className="mb-0">Errore: lettura mail non può essere confermata</h1>
                    )}
                  </Col>
                </Row>
              </CardHeader>
            </Card>
            </div>
          </Row>
        </div>
      </div>
		</>
	)
}
