import React, {useState} from "react";
import { Form } from 'react-bootstrap'
import {buildRuleStructure, isValidRegex} from "../../../utils/rules";
import {updateCustomRule} from "../../api_methods";


export function RuleRow({ rule, tagWidth= 200, regexWidth= 300, editRegex = true, editTag = false }) {
  /**
   * Rule visualizer component, used to show and eventually edit the tag and the regex of a rule
   */
  const [containsRegex, setContainsRegex] = useState(rule.rule.conditions[0]?.operand !== '~' ? rule.rule.conditions[0]?.operand : '');
  const [containsRegexErrors, setContainsRegexErrors] = useState(false);

  const [notContainsRegex, setNotContainsRegex] = useState(rule.rule.conditions[1]?.operand !== '~' ? rule.rule.conditions[1]?.operand : '');
  const [notContainsRegexErrors, setNotContainsRegexErrors] = useState(false);

    // Aggiornamento della regola, che viene salvata o creata (il salvataggio/creazione è usato da altre funzioni)
  const updateRule = () => {
    if (containsRegexErrors || notContainsRegexErrors)
      return
    // formato standard a tutte le regole SEO
    const newRule = buildRuleStructure(rule, rule.rule.value, rule.rule?.Inglese, containsRegex, notContainsRegex)

    updateCustomRule(newRule)
  }

  const handleContains = (event) => {
    setContainsRegex(event.target.value)
    if (isValidRegex(event.target.value))
      setContainsRegexErrors(false)
    else
      setContainsRegexErrors(true)
  }

  const handleNotContains = (event) => {
    setNotContainsRegex(event.target.value)
    if (isValidRegex(event.target.value))
      setNotContainsRegexErrors(false)
    else
      setNotContainsRegexErrors(true)
  }

  return (
    <div
      className="d-flex align-items-center pb-3"
    >
      <div style={{ width: `${tagWidth}px` }}>
        <fieldset className="w-seo-control-field">
          <Form.Control
            type="text"
            title={rule.rule.value}
            value={rule.rule.value}
            disabled={!editTag}
            className="form-control font-weight-bold"
            placeholder="Tag"
            onChange={() => {}}
            onBlur={() => {}}
          />
        </fieldset>
      </div>
      <div style={{ width: `${regexWidth}px` }}>
        <fieldset className="w-seo-control-field">
          <Form.Control
            type="text"
            title={containsRegex}
            value={containsRegex}
            isInvalid={containsRegexErrors}
            disabled={!editRegex}
            className="form-control"
            placeholder="Contiene"
            onChange={handleContains}
            onBlur={updateRule}
          />
        </fieldset>
      </div>
      <div style={{ width: `${regexWidth}px` }}>
        <fieldset className="w-seo-control-field">
          <Form.Control
            type="text"
            title={notContainsRegex}
            value={notContainsRegex}
            isInvalid={notContainsRegexErrors}
            disabled={!editRegex}
            className="form-control"
            placeholder="Non Contiene"
            onChange={handleNotContains}
            onBlur={updateRule}
          />
        </fieldset>
      </div>
    </div>
  )
}