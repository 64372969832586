let callbackUrl
if (process.env.REACT_APP_ENV === 'production') {
  callbackUrl = 'https://ranking.webranking.tools/callback'
} else if (process.env.REACT_APP_ENV === 'staging') {
  callbackUrl = 'https://staging.ranking.webranking.tools/callback'
} else {
  callbackUrl = 'http://localhost:3000/callback'
}

export const AUTH_CONFIG = {
  domain: 'webranking.eu.auth0.com',
  clientID: 'HeYSz9R0IiY94-z52BzvVePyBWqRvbiL',
  callbackUrl,
  apiUrl: 'https://dash.webranking.tools/'
}
