import React, { useContext } from 'react'
import {
  prefix,
  notify,
  loginRedirect,
} from '../../../../utils/index_tagging_tool'
import {
  api
} from '../../../../utils/index'

export function DeleteOperation({ context }) {
  const {
    operationsPossible,
    checkedRules,
    checked,
    setChecked,
    rules,
    removeRule,
  } = useContext(context)

  const deleteRule = async (rule) => {
    // Altrimenti rimuovo a backend, e poi rimuovo dalla lista (perchè tutto rimanga sincronizzato)
    return api.delete(`${prefix}custom-rules/${rule.id}/`)
  }

  const deleteRules = async (e) => {
    e.preventDefault()
    const answer = window.confirm(
      'Sicuro? Tutte le regole selezionate verranno cancellate definitivamente'
    )
    if (answer) {
      const ruleList = JSON.parse(JSON.stringify(checkedRules))
      await Promise.all(
        ruleList.map(async (rule) => {
          if (rule.id !== -1) {
            try {
              const response = await deleteRule(rule)
              loginRedirect(response)
            } catch (e) {
              notify(
                e.message,
                'error'
              )
            }
          } else {
            removeRule(rule.index, { rules: rules })
          }
        })
      )
      window.location.reload()
    }
  }

  return (
    <>
      {operationsPossible.includes('Elimina') && (
        <div className={'operationsDiv'} onClick={deleteRules}>
          <p>Elimina</p>
        </div>
      )}
    </>
  )
}
