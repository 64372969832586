import React, { Component } from 'react'
import { Accordion, Card, Button, Badge } from 'react-bootstrap'
import ReportCardBody from './ReportCardBody'

// Questo era qui da prima di me, se avete rimostranze mi dispiace, le avevo anch'io
export default class Report extends Component {
  constructor(props) {
    super(props)

    this.state = {
      report: props.repo,
      isRunning: props.repo.running,
    }
  }

  getRepoStatus(repo) {
    if (repo.status && repo.status == 'EXPORTED') {
      return "success"
    }
    if (repo.status && repo.status == 'ERROR') {
      return "danger"
    }
    return "warning"
  }

  render() {
    const repo = this.state.report

    return (
      <Card key={repo.id}>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" className="w-100 text-left shadow-none" eventKey={repo.id}>
            {repo.name}{' '}
            {(repo.input_options && repo.input_options.language_code) ? ' | ' + repo.input_options.language_code : ''}{' '}
            <Badge pill variant={this.getRepoStatus(repo)} className="float-right">
              {repo.status}
            </Badge>
          </Accordion.Toggle>
        </Card.Header>

        <Accordion.Collapse eventKey={repo.id} key={repo.id}>
          <ReportCardBody repo={repo} />
        </Accordion.Collapse>
      </Card>
    )
  }
}
