import React, { Fragment } from 'react'
import { Row, Col } from 'reactstrap'

// Componente per la modifica di output_options del report. Per ora non è possibile modificare nulla, viene solo mostrato il percorso in cui verrà salvato il report su BQ
export default function OutputBox({ options, seo, ratio }) {
  return (
    <Fragment>
      <h3>Output</h3>
      <hr />
      <Row className="mb-4">
        <Col xs={ratio.title}>
          <label className={'vertical'}>Run report</label>
        </Col>
        <Col xs={ratio.form} className={'text-left'}>
          {seo ? (
            <p>
              Una volta eseguito il run, il risultato sarà disponibile su{' '}
              <strong>bigquery</strong> seguendo il percorso:{' '}
              <strong>
                INN-Ranking / {options.bigquery.dataset} /{' '}
                {options.bigquery.table}
              </strong>
            </p>
          ) : (
            <p>
              Una volta eseguito il run, il risultato sarà reso disponibile via{' '}
              <strong>{Object.keys(options)}</strong>
            </p>
          )}
        </Col>
      </Row>
    </Fragment>
  )
}
