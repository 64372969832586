import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'
import { useParams } from 'react-router-dom'
import TagsInput from '../../components/TagsInput'


export default function Email({ context }) {
  const { emails, setEmails } = useContext(context)

  let { id: studioKwID } = useParams()
  
  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  return (
    <>
      <FormGroup>
        <label htmlFor="report" className="form-control-label">
          Email
        </label>
        <TagsInput
          className="form-control-alternative"
          id="email"
          selectedTags={setEmails}
          validator={validateEmail}
          tags={emails}
          type="text"
        />
        <FormText color="muted" align={'left'}>
          <b>Premere invio</b> dopo ogni nuovo inserimento
        </FormText>
      </FormGroup>
    </>
  )
}
