import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap'

// Questo era qui da prima di me, se avete rimostranze mi dispiace, le avevo anch'io
export default class ReportCardBody extends Component {
  constructor(props) {
    super(props)

    this.state = {
      report: props.repo,
      isRunning: props.repo.running,
    }
  }

  render() {
    const repo = this.state.report

    return (
      <Card.Body>
        <h5 style={{ display: repo.seo ? 'none' : 'inline-block' }}>
          Report {repo.active ? 'attivo' : 'non attivo'}
        </h5>
        <Row>
          <Col xs={12} className="mt-3">
            <Link
              to={{
                pathname: `/reporting/reports/seo/${repo.id}`,
              }}
            >
              <h3>⚙ Configura</h3>
            </Link>
          </Col>
        </Row>
        <Row className={'mt-3'}>
          <Col xs={6} className={'text-left'}>
            <h3>Custom Dimensions</h3>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {repo.custom_dimensions.map((dimension, index) => (
                <li key={index}>
                  <a
                    style={{ cursor: 'pointer' }}
                    href={`/reporting/custom-dimensions/${dimension.id}`}
                  >
                    {dimension.display_name}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={6} className={'text-left'}>
            <h3>Custom Metrics</h3>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {repo.custom_metrics.map((metric, index2) => (
                <li key={index2}>
                    {metric.display_name}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Card.Body>
    )
  }
}
