import React, {useEffect, useState} from 'react'
import {Button,} from 'react-bootstrap'
import TranslationsRow from "./TranslationsRow";
import {
  getGroupEmptyTranslations,
  getReportEmptyTranslations,
  updateGroupTranslationConfiguration,
  updateReportTranslationConfiguration
} from "../../api_methods";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/fontawesome-free-solid";
import Select from "react-select";
import {notify} from "../../../utils/index_tagging_tool";
import Loading from "../../components/Loading";
import {Link} from "react-router-dom";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";

export default function EmptyTranslations({identifier, info, group=true}) {
  /**
   * Interface for visualize and edit the rules with no translations.
   * Also configure the report/group in which the rule resides, to specify the dimensions that should not be translatable
   */
    // list of the dimensions of the group/report
  let dimensions = group ? info.dimensions : info.custom_dimensions
  // configuration field of the group/report
  let configuration_options = group ? info.configuration_options : info.input_options

  // list of the dimensions that should not be translated
  const [noTranslation, setNoTranslation] = useState(
    configuration_options.no_translation ? configuration_options.no_translation : []
  )

  // list of rules with no translation
  const [emptyRules, setEmptyRules] = useState([])

  // variables for UI
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [collapse, setCollapse] = useState(true)

  const getEmptyRuleTranslations = () => {
    /**
     * Retrieves rules with no translations
     */
    setLoaded(false)
    if(group)
      getGroupEmptyTranslations(identifier).then((data) => {setEmptyRules(data); setLoaded(true)})
    else
      getReportEmptyTranslations(identifier).then((data) => {setEmptyRules(data); setLoaded(true)})
  }

  const changeNoTranslations = (e) => {

    const values = e.map((v) => v.label)

    if (noTranslation === values) return
    setNoTranslation(values)
  }

  const updateNoTranslations = (e) => {
    e.preventDefault()

    const answer = window.confirm(
      'Per tutte le regole delle dimensioni selezionate, il valore inglese diventerà uguale a quello italiano. ' +
      'Conferma per continuare.'
    )
    if (!answer) return

    let data = {'no_translation': noTranslation}

    setLoading(true)

    // different API calls the rules are in a group or a report
    if(group)
      updateGroupTranslationConfiguration(identifier, data)
        .then(() => setLoading(false))
        .catch((error) => {
          console.log(error)
          notify(
            error.message,
            'error'
          )
          setLoading(false)
        })
    else
      updateReportTranslationConfiguration(identifier, data)
        .then(() => setLoading(false))
        .catch((error) => {
          console.log(error)
          notify(
            error.message,
            'error'
          )
          setLoading(false)
        })

    getEmptyRuleTranslations()
  }

  useEffect(() => {
    getEmptyRuleTranslations()
  }, [])

  return (
    <Card className="bg-secondary">
      <div className={collapse ? 'bg-secondary' : 'bg-secondary pb-3'}>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h2 className="mb-0">Stato sincronizzazione traduzioni</h2>
          <button
            type="button"
            className="p-2 ml-4"
            onClick={() => setCollapse(!collapse)}
          >
            <FontAwesomeIcon icon={collapse ? faChevronDown : faChevronUp} />
          </button>
        </CardHeader>
        {!collapse && (
          <CardBody>
            <Row className="ml-1">
              <label>Dimensioni da non tradurre</label>
            </Row>
            <Row>
              <Col xs={4}>
                <Select
                  inputId={`metrics`}
                  options={dimensions
                    .filter((d) => !noTranslation.includes(d.name))
                    .map((d) => {
                      return { label: d.name, value: d.name }
                    })}
                  placeholder={'Inserisci una dimensione'}
                  className="single-select"
                  classNamePrefix="react-select"
                  value={noTranslation.map((g) => {
                    return { label: g, value: g }
                  })}
                  onChange={changeNoTranslations}
                  isMulti={true}
                  isClearable={true}
                  isSearchable={true}
                />
              </Col>
              <Col xs={4}>
                {!loading ?
                  <Button color="success" onClick={updateNoTranslations}> Salva </Button> :
                  <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                }
              </Col>
            </Row>
            <Row className="ml-1">
              <label style={{ fontSize: '10px' }}>
                Cliccare il tasto per salvare la scelta
              </label>
            </Row>

            <>
              <div className="mb-2 mt-4">
                <Row>
                  <label>Regole senza traduzione</label>
                </Row>

                  {emptyRules.length > 0 ?
                    <>
                      In questa sezione è presente una lista delle regole a cui manca la traduzione in inglese,
                      suddivise per dimensione.
                      <br/>
                      Per ogni dimensione elencata, è possibile modificare singolarmente le regole o tradurle tutte
                      automaticamente tramite il tasto dedicato.
                      Le traduzioni inserite nella colonna "Tag EN" di una dimensione <b>non saranno applicate finché non si premerà il bottone "Salva modifiche"</b>.

                    </> :
                    loaded && <h6 className="center-block">Tutte le regole hanno la traduzione <span role="img" aria-label="Correct">✅</span></h6>}

                  {group === false && info.groups && <>
                    <br/>
                    <br/>
                    ⚠️Il report <b>è collegato a dei gruppi di regole salvate</b>.
                    Per visualizzare e modificare le traduzioni mancanti in tali gruppi,
                    clicca <Link to="/reporting-dimension-groups"><b>qua</b> </Link>
                    e cerca il gruppo che ti interessa.
                  </>}
                <br/>
              </div>

              {loaded ?
                <>
                  <TranslationsRow dimensions={emptyRules} key={identifier}
                                   refreshRules={getEmptyRuleTranslations} group={group}/>
                  <Button onClick={() => getEmptyRuleTranslations()} title="Refresh">
                    Refresh <span role="img" aria-label="refresh">🔃</span>
                  </Button>
                </>
                : <Loading child={null}/>}
            </>
          </CardBody>
        )}
      </div>
    </Card>
  )

}
