import { toast } from 'react-toastify'

export function notify(message, kind) {
  toast.configure()
  switch (kind) {
    case 'success': 
      return toast.success(message, {
        containerId: 'notify'
      })
    case 'error':
      return toast.error(message, {
        containerId: 'notify',
        autoClose: false
      })
    case 'warning':
      return toast.warn(message, {
        containerId: 'notify'
      })
    default:
    // noop
  }
}
