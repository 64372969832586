import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function Authority({ context }) {
  const { authority , setAuthority } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Esegui su Authority
      </label>
      <div style={{marginLeft:'+1.5em'}}>
        <Input
          className="form-control-alternative"
          type="checkbox"
          id="authority"
          checked={authority}
          style={{width: 'auto'}}
          onChange={(e) => setAuthority(e.target.checked)}
        />
      <FormText color="muted" align={'left'}>
        Spunta la casella per effetuare il recupero delle SERP su AuthorityLabs anzichè DataforSEO
      </FormText>
      </div>
    </FormGroup>
  )
}
