import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  prefix,
  loginRedirect,
  notify,
} from '../../../utils/index_tagging_tool'

import { api } from '../../../utils/index'

import { saveAs } from 'file-saver'

export default function ExcelDownload({ dim, loader }) {

  const [loading, setLoading] = useState(false)

  const handleExport = async (e) => {
    e.preventDefault()
    setLoading(true)

    notify(
      "Stiamo generando l'export, potrebbe volerci qualche minuto.",
      'warning'
    )

    api(
      prefix +
        `rule-file-download/${dim}/`,
      { responseType: 'blob' }
    )
      .then((res) => {
        saveAs(res.data, `${dim}_export.xlsx`);
        notify('File generato con successo', 'success');
      }, (err) => {
        if (err.response.status < 500) {
          notify(err.response.data, 'error');
        } else {
          notify('Errore del server', 'error');
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      {loading ? (
         <div className="spinner-border mr-3" role="status">
           <span className="sr-only">Loading...</span>
         </div>
      ) : (
      <Button
        onClick={(e) => handleExport(e)}
        variant="warning"
        className={'btn btn-info mr-2'}
        disabled={loader}
      >
        Esporta
      </Button>)}
    </>
  )
}
