
import React, { Component } from 'react'
import Report from '../../components/Report'
import { Accordion, Button, Badge } from 'react-bootstrap'
import { prefix, loginRedirect } from '../../../utils/index_tagging_tool'
import { api } from '../../../utils/index'
import ReportCardBody from '../../components/ReportCardBody'
import Loading from '../../components/Loading'
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import UserHeader from "../../../components/Headers/ReportingHeader";

class Listing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [],
      seo: true,
      adv: false,
    }
    document.title = 'Tagging tool'
  }

  componentDidMount() {
    api.get(prefix + 'reports/')
      .then(loginRedirect)
      .then((data) => {
        let saved = []
        let superReports = data.filter((d) => d.is_super)
        superReports.forEach((s) => {
          saved.push(s.id)
          s.sub_reports.forEach((s_sub) => {
            saved.push(s_sub.id)
          })
        })

        data.forEach((d) => {
          if (!d.is_super && !saved.includes(d.id)) {
            superReports.push(d)
          }
        })
        this.setState({ reports: superReports })
      })
      .catch(console.log)
  }

  getReportsClients() {
    let clients = []
    if (this.state.reports.length > 0 && this.state.reports) {
      clients = this.state.reports
        .filter((x) => ((this.state.seo && x.seo) || (this.state.adv && !x.seo)) &&
          x.input_options && x.input_options.client && x.input_options.client !== ""
        )
        .map((x) => x.input_options.client)
      clients = [...new Set(clients)]
    }

    return clients.sort()
  }

  render() {
    return (
      <>
      <UserHeader/>
        {/*<PageTitle title="Reports" />*/}
        <Row style={{marginBottom: "100px"}} className="justify-content-center AppTaggingTool">
          <Col xs={8}>
            <Card className="shadow bg-secondary">
              <CardHeader className="bg-white">
                <h1 className="mb-0">Clienti</h1>
                <p className="w-subtitle pt-4 pl-2 mb-0">
                  Clicca su un cliente per visualizzare gli studikw associati
                </p>
              </CardHeader>

              <div className="mr-5 ml-5 mb-2">
                {this.state.reports.length > 0 ? (
                  this.getReportsClients().map((client) => {
                    return (
                      <Accordion defaultActiveKey="0">
                        <Card key={client}>
                          <CardHeader>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey={client}
                              className="shadow-none"
                            >
                              {client}{' '}
                            </Accordion.Toggle>
                          </CardHeader>

                          <Accordion.Collapse eventKey={client} key={client}>
                            <CardBody>
                              <Accordion defaultActiveKey="0">
                                {this.state.reports.length > 0 ? (
                                  this.state.reports
                                    .filter(
                                      (x) => ((this.state.seo && x.seo) || (this.state.adv && !x.seo)) &&
                                        x.input_options && x.input_options.client == client
                                    )
                                    .map((repo) => {
                                      if (repo.sub_reports.length > 0) {
                                        {repo.sub_reports.map((report) => (
                                          <Report repo={report} />
                                        ))}
                                        return <ReportCardBody repo={repo} />
                                      }
                                      else {
                                        return <Report key={repo.id} repo={repo} />
                                      }
                                    })
                                ) : (
                                  <Loading />
                                )}
                              </Accordion>
                            </CardBody>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    )
                  })
                ) : (
                  <Loading />
                )}
              </div>
            </Card>

            <br/>

            <Card className="shadow pb-20 bg-secondary">
              <CardHeader className="bg-white">
                <h1 className="mb-0">Studikw singoli</h1>
                <p className="w-subtitle pt-4 pl-2 mb-0">
                  Clicca su un studikw per visulizzarne i dettagli. In questa sezione sono presenti gli studikw a cui non è
                  stato associato un cliente
                </p>
              </CardHeader>

              <Accordion defaultActiveKey="0" className="mr-5 ml-5 mb-2">
                {this.state.reports.length > 0 ? (
                  this.state.reports
                    .filter(
                      (x) => ((this.state.seo && x.seo) || (this.state.adv && !x.seo)) &&
                        (x.input_options && x.input_options.client == "") ||
                        (x.input_options && !x.input_options.client)
                    )
                    .map((repo) => {
                      if (repo.sub_reports.length > 0) {
                        return (
                          <Card key={repo.id}>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey={repo.id}
                                className="shadow-none"
                              >
                                {repo.name}{' '}
                                {repo.seo ? (
                                  <Badge pill variant="success">
                                    SEO
                                  </Badge>
                                ) : (
                                  <Badge pill variant="danger">
                                    ADV
                                  </Badge>
                                )}
                              </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey={repo.id} key={repo.id}>
                              <Card.Body>
                                <Accordion defaultActiveKey="0">
                                  {repo.sub_reports.map((report) => (
                                    <Report repo={report} />
                                  ))}
                                  <ReportCardBody repo={repo} />
                                </Accordion>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        )
                      } else {
                        return <Report key={repo.id} repo={repo} />
                      }
                    })
                ) : (
                  <Loading />
                )}
              </Accordion>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default Listing
