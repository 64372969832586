import {loginRedirect, notify} from "../utils/index_tagging_tool";
import { api } from '../utils/index'

const prefix = '/tagging-tool/'

export const createDimensionGroup = (data) => {
  return api.post(prefix + 'dimension-groups/', data).then(loginRedirect)
}

export const deleteDimensionGroup = (data) => {
  return api.delete(prefix + 'dimension-groups/', {data: data})
      .then(loginRedirect)
}

export const getDimensionGroups = () => {
  return api.get(prefix + `dimension-groups/`).then(loginRedirect)
}

export const getDimensionGroupsDetail = (name) => {
  return api.get(prefix + `dimension-groups/${name}/`).then(loginRedirect)
}

export const getLanguageGroupInfo = (name) => {
  return api.get(prefix + `language-groups/info/${name}/`).then(loginRedirect)
}

export const createSavedDimension = (data) => {
  return api.post(prefix + 'saved-dimensions/', data).then(loginRedirect)
}

export const deleteSavedDimension = (data) => {
  return api.delete(prefix + 'saved-dimensions/', {data: data})
      .then(loginRedirect)
}

export const updateSavedDimension = (dimension, data) => {
  return api.put(`${prefix}saved-dimensions/${dimension.id}/`, data)
      .then(loginRedirect)
}

export const getSavedDimensions = () => {
  return api.get(prefix + `dimension-groups/`).then(loginRedirect)
}

export const updateCustomRule = (rule) => {
      api.put(`${prefix}custom-rules/${rule.id}/`, rule)
      .then(loginRedirect)
      .then((data) => {
        console.log(data)
      })
      .catch((e) => {
        notify('La comunicazione con il server è stata interrotta', 'error')
      })
}

export const addLanguageGroup = (name, data) => {
  return api.post(prefix + `language-groups/${name}/`, data).then(loginRedirect)
}

export const updateLanguageGroup = (name, data) => {
  return api.put(prefix + `language-groups/${name}/`, data).then(loginRedirect)
}

export const deleteLanguageGroup = (name, data) => {
  return api.delete(prefix + `language-groups/${name}/`, {data: data}).then(loginRedirect)
}

export const getGroupEmptyTranslations = (name) => {
  return api.get(prefix + `group-translations/${name}/`).then(loginRedirect)
}

export const updateGroupTranslationConfiguration = (name, data) => {
  return api.put(prefix + `group-translations/${name}/`, data).then(loginRedirect)
}

export const getReportEmptyTranslations = (name) => {
  return api.get(prefix + `report-translations/${name}/`).then(loginRedirect)
}

export const updateReportTranslationConfiguration = (name, data) => {
  return api.put(prefix + `report-translations/${name}/`, data).then(loginRedirect)
}

export const translateDimension = (id, saved) => {
  return api.get(prefix + `translate-dimension/${id}/?saved=${saved}`).then(loginRedirect)
}

export const saveDimensionTranslations = (id, data) => {
  return api.post(prefix + `translate-dimension/${id}/`, data).then(loginRedirect)
}

export const updateLanguageRule = (id, data, ) => {
  return api.put(`${prefix}rule-language/${id}/`, data)
  .then((res) => {
    notify('Modifiche salvate :)', 'info')
  }, (err) => {
    let res = err.response;
    if (res.status >= 400){
      notify('Errore nella comunicazione con il server', 'error')
    }
  })
}

export const getUnsavedRules = (name) => {
  return api.get(prefix + `unsaved-rules/${name}/`, { credentials: 'include' }).then(loginRedirect)
}

export const saveCustomRule = (id, data) => {
  return api.put(`${prefix}rule-saver/${id}/`, data).then(loginRedirect)
  .catch((e) => {
    notify('La comunicazione con il server è stata interrotta', 'error')
  })
}

export const cloneReport = (id, data) => {
  return api.post(prefix + `clone-report/${id}/`, data)
}

export const getReportNames = () => {
  return api.get(prefix + 'report-names/').then(loginRedirect)
}