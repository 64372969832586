import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function Repetition({ context }) {
  const { repetition, setRepetition } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="repetition" className="form-control-label">
        Intervallo di ripetizione: <b>{repetition}</b>
      </label>
      <Input
        type="range"
        min={1}
        max={6}
        value={repetition}
        style={{ width: '200px', paddingLeft: '0px', paddingRight: '0px' }}
        onChange={(e) => setRepetition(Number(e.target.value))}
      />
      <FormText color="muted" align={'left'}>
        Intervallo di ripetizione dello schedule Esempio: se lo Schedule type è 'settimanale', un
        intervallo uguale a 4 permette una ripetizione ogni 4 settimane Esempio 2: se lo Schedule
        type è 'mensile', un intervallo uguale a 2 permette una ripetizione ogni 2 mesi Minimo: 1,
        massimo: 6, default:1
      </FormText>
    </FormGroup>
  )
}
