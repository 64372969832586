import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'
import { useParams } from 'react-router-dom'
import TagsInput from '../../components/TagsInput'

export default function Domini({ context }) {
  const { domini, setDomini} = useContext(context)

  let { id: studioKwID } = useParams()

  return (
    <>
      <FormGroup>
        <label htmlFor="report" className="form-control-label">
          Domini
        </label>
        <TagsInput
          className="form-control-alternative"
          id='domini'
          selectedTags={setDomini}
          tags={domini}
          type="text"
        />
        <FormText color="muted" align={'left'}>
          <b>Premere invio</b> dopo ogni nuovo inserimento
        </FormText>
      </FormGroup>
    </>
  )
}
