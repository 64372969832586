import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function Volume({ context }) {
  const { volume, setVolume } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Volume
      </label>
      <Input
        className="form-control-alternative"
        type="text"
        id="volume"
        value={volume}
        placeholder="Inserisci volume"
        onChange={e => setVolume(e.target.value)}
        required={true}
        onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
      />
      <FormText color="muted" align={'left'}>
        Inserisci il nome della colonna volume del foglio RAW
      </FormText>
    </FormGroup>
  )
}
