import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function Country({ context }) {
  const { country, setCountry } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Country
      </label>
      <Input
        className="form-control-alternative"
        type="text"
        id="country"
        value={country ?? ''}
        maxLength={5}
        placeholder={'es: IT-IT'}
        onChange={(e) => setCountry(e.target.value)}
        onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
      />
    </FormGroup>
  )
}
