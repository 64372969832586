import React, { Fragment, useState } from 'react'
import { Form, FormText, Input, FormGroup } from 'reactstrap'
import Select from 'react-select'
import {isValidForBQ} from "../../../utils/validators";

/* 
Campi delle creazione di un report, riutilizzati anche per la modifica della configurazione (quasi tutti i parametri sono input options del report) 
Generalmente per l'update vengono mostrate scritte lievemente diverse, in base al booleano "create"
*/

export function FileSelector({ file, setFile, create }) {
  return (
    <FormGroup>
      <label htmlFor="file" style={{ display: 'block' }}>
        File
      </label>
      <Input
        className="form-control-alternative"
        id={'file'}
        type={'file'}
        required={true}
        filename={file ? file.filename : null}
        style={{ border: '0' }}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
        onChange={(e) =>
          e.target.files ? setFile(e.target.files[0]) : setFile(null)
        }
      />
      {create && (
        <FormText color="muted" align={'left'}>
          <b>Carica un file (excel o csv)</b> con le keywords
        </FormText>
      )}
    </FormGroup>
  )
}

export function ClientName({ client, setClient, create }) {
  return (
    <FormGroup>
      <label htmlFor="client" style={{ display: 'block' }}>
        Cliente
      </label>
      <Input
        className="form-control-alternative"
        type="text"
        id="client"
        value={client}
        placeholder="Inserisci cliente"
        onChange={(e) => setClient(e.target.value)}
      />
      {create && (
        <FormText color="muted" align={'left'}>
          Inserisci il nome del <b>cliente</b>
        </FormText>
      )}
    </FormGroup>
  )
}

export function LanguageCode({ languageCode, setLanguageCode, create }) {
  return (
    <FormGroup>
      <label htmlFor="language_code" style={{ display: 'block' }}>
        Codice Lingua
      </label>
      <Input
        className="form-control-alternative"
        type="text"
        id="language_code"
        maxlength="2"
        value={languageCode}
        placeholder="Inserisci il codice della lingua"
        onChange={(e) => setLanguageCode(e.target.value)}
      />
      {create && (
        <FormText color="muted" align={'left'}>
          Inserisci il <b>codice della lingua</b> (due lettere, e.s. IT)
        </FormText>
      )}
    </FormGroup>
  )
}

export function KeywordName({ keyword, setKeyword, create }) {
  return (
    <FormGroup>
      <label htmlFor="kw_column" style={{ display: 'block' }}>
        Colonna keywords
      </label>
      <Input
        className="form-control-alternative"
        type="text"
        id="keyword"
        value={keyword}
        placeholder="Inserisci nome"
        onChange={(e) => setKeyword(e.target.value)}
      />
      {create && (
        <FormText color="muted" align={'left'}>
          Inserisci il nome della <b>colonna contenente le keywords</b>{' '}
          ('Keyword' di default)
        </FormText>
      )}
    </FormGroup>
  )
}

export function VolumeName({ volume, setVolume, create }) {
  return (
    <FormGroup>
      <label htmlFor="vol_column" style={{ display: 'block' }}>
        Colonna volumi
      </label>
      <Input
        className="form-control-alternative"
        type="text"
        id="volume"
        value={volume}
        placeholder="Inserisci nome"
        onChange={(e) => setVolume(e.target.value)}
      />
      {create && (
        <FormText color="muted" align={'left'}>
          Inserisci il nome della <b>colonna contenente i volumi</b> ('Volume'
          di default)
        </FormText>
      )}
    </FormGroup>
  )
}

export function ReportName({ names=[], name, setName, create }) {
  // Stati per dare feedback all'utente sul nome del report
  const [error, setError] = useState('')
  const [valid, setValid] = useState('')

  const handleChange = (e) => {
    // Cambia il nome e resetta gli error valid, che verranno rivalutati sull'onblur
    setName(e.target.value)
    setError('')
    setValid('')
  }

  const evaluateName = () => {
    // Controlla, basandosi sui nomi degli altri reports, che non ne esista uno simile
    if (name === '') setError('Non puoi inserire un nome vuoto')
    if (name.length > 1024) setError('Non puoi inserire un nome con più di 1024 caratteri')
    if (!isValidForBQ(name))
      setError('Puoi inserire un nome composto solo di lettere (maiuscole o minuscole), numeri e trattini bassi')
    else if (names.includes(name.toLowerCase())) {
      setError('Esiste già un report con questo nome')
    } else {
      setValid('Il nome selezionato è disponibile')
    }
  }

  return (
    <FormGroup>
      <label htmlFor="report" style={{ display: 'block' }}>
        Report
      </label>
      <Input
        className="form-control-alternative"
        type="text"
        id="name"
        required={true}
        invalid={error !== ''}
        valid={valid !== ''}
        placeholder="Inserisci nome"
        onChange={handleChange}
        onBlur={evaluateName}
      />
      {create && (
        <Fragment>
          {error !== '' && (
            <FormText style={{ color: 'red' }} align={'left'}>
              {error}
            </FormText>
          )}
          {valid !== '' && (
            <FormText style={{ color: 'green' }} align={'left'}>
              {valid}
            </FormText>
          )}
          {error === valid && (
            <FormText color="muted" align={'left'}>
              Inserisci il <b>nome del report</b> da creare
            </FormText>
          )}
        </Fragment>
      )}
    </FormGroup>
  )
}

export function SelectMetrics({ options, metrics, setMetrics, create }) {
  return (
    <FormGroup>
      <label htmlFor="metrics" style={{ display: 'block' }}>
        Metriche
      </label>
      <Select
        inputId={`metrics`}
        options={options ? options : []}
        placeholder={'Seleziona una metrica'}
        className="single-select"
        classNamePrefix="react-select"
        value={metrics}
        onChange={(value) => setMetrics(value)}
        isMulti={true}
        isClearable={true}
        isSearchable={true}
      />
      {create && (
        <FormText color="muted" align={'left'}>
          Seleziona eventuali <b>metriche salvate da applicare al report</b>
        </FormText>
      )}
    </FormGroup>
  )
}

// Occhio: questa funzione è usata sia per la selezione di dimensioni da importare, sia per impostare colonne escluse dall'intervento delle metriche (la distinzione la fa il booleano excluded)
export function SelectDimensions({
  options,
  dimensions,
  setDimensions,
  groupDimensions,
  excluded,
  setExcluded,
  create,
}) {
  return (
    <FormGroup>
      <label htmlFor="dimensions" style={{ display: 'block' }}>
        {excluded ? 'Non applicate metriche a:' : 'Dimensioni'}
      </label>
      <Select
        inputId={excluded != null ? 'excluded' : `dimensions`}
        options={
          excluded != null ? dimensions.concat(groupDimensions) : options
        }
        placeholder={'Seleziona una dimensione'}
        className="single-select"
        classNamePrefix="react-select"
        value={excluded != null ? excluded : dimensions}
        onChange={(e) => {
          if (excluded != null) setExcluded(e)
          else setDimensions(e)
        }}
        isMulti={true}
        isClearable={true}
        isSearchable={true}
      />
      {create && (
        <FormText color="muted" align={'left'}>
          {excluded ? (
            <>
              Seleziona eventuali{' '}
              <b>
                dimensioni salvate da escludere quando vengono applicate le
                metriche
              </b>
            </>
          ) : (
            <>
              Seleziona eventuali{' '}
              <b>dimensioni salvate da applicare al report</b>
            </>
          )}
        </FormText>
      )}
    </FormGroup>
  )
}

export function SelectDimensionGroups({ options, groups, setGroups, create }) {
  return (
    <FormGroup>
      <label htmlFor="dimensions" style={{ display: 'block' }}>
        {'Gruppi di dimensioni'}
      </label>
      <Select
        options={options}
        placeholder={'Seleziona un gruppo'}
        className="single-select"
        classNamePrefix="react-select"
        value={groups}
        onChange={setGroups}
        isMulti={true}
        isClearable={true}
        isSearchable={true}
      />
      {create && (
        <FormText color="muted" align={'left'}>
          Seleziona eventuali{' '}
          <b>gruppi di dimensioni salvate</b> da collegare al report
        </FormText>
      )}
    </FormGroup>
  )
}

// NB: la selezione di un set non causa l'impostazione di nuovi parametri da mandare a backed di per se, ma imposta a frontend i gruppi e dimensioni contenuti nello stesso
// (così uno può anche sutomizzarli all'evenienza), e sono poi quelli che vengono mandati a backend
export function SelectDimensionSet({ options, groups, setGroups, create }) {
  return (
    <FormGroup>
      <label htmlFor="dimensions" style={{ display: 'block' }}>
        {'Set di gruppi'}
      </label>
      <Select
        options={options}
        placeholder={'Seleziona un gruppo'}
        className="single-select"
        classNamePrefix="react-select"
        value={groups}
        onChange={setGroups}
        isMulti={true}
        isClearable={true}
        isSearchable={true}
      />
      {create && (
        <FormText color="muted" align={'left'}>
          Seleziona eventuali{' '}
          <b>set di gruppi e/o dimensioni salvate da applicare al report</b>
        </FormText>
      )}
    </FormGroup>
  )
}
