import React, {useEffect, useState, useContext, useRef} from 'react'
import {Spinner} from 'react-bootstrap'
import {
  Col,
  FormGroup,
  Form,
  FormText,
  CardBody,
  Row,
  Button,
  Card,
  CardHeader,
  Input,
  Container
} from "reactstrap";

import TagsInput from '../../components/TagsInput'
import CharCheckModal from "../../components/molecules/CharCheckModal";
import { api } from '../../utils/index'
import { ToastContainer, toast } from 'react-toastify'
import { notify } from '../../utils/notify'
import { saveAs } from 'file-saver'
import Date from "yup/lib/date";
import axios from "axios";


const KeywordClusteringForm = () => {
  const [file, setFile] = useState(null)
  const [emails, setEmails] = useState([])
  const [serp, setSerp] = useState(5)
  const [similarity, setSimilarity] = useState(60)
  const [loading, setLoading] = useState(false)
  // Modal check caratteri
  const [showCharCheck, setShowCharCheck] = useState(false)
  const [errCharList, setErrCharList] = useState([])
  const [acceptedCharCheck, setAcceptedCharCheck] = useState(false)

  const projects = ['', 'inn-ranking', 'w-seo-bigquery']
  const [project, setProject] = useState('')

  const [dataset, setDataset] = useState('')
  const [datasets, setDatasets] = useState([''])
  const [datasetsLoading, setDatasetsLoading] = useState(false);

  const [table, setTable] = useState('')
  const [tables, setTables] = useState([''])
  const [tablesLoading, setTablesLoading] = useState(false);

  const [validated, setValidated] = useState(false);

  let axiosCancelToken = useRef();
  const getDatasets = async () => {
    if (project.length > 0) {
      setDatasetsLoading(true)

      //Check if there are any previous pending requests
      if (typeof axiosCancelToken.current != typeof undefined) {
        axiosCancelToken.current.cancel("Operation canceled due to new request.")
      }

      //Save the cancel token for the current request
      axiosCancelToken.current = axios.CancelToken.source()

      api
      .get(`bigquery-exporter/?project=${project}`, { cancelToken: axiosCancelToken.current.token })
      .then(res => {
        setDatasetsLoading(false)
        if (res.data && res.data.results)
          setDatasets(res.data.results)
      })
      .catch(err => {
        setDatasets([])
        setDatasetsLoading(false)
        console.error(err)
      })
    }
  }

  const getTables = () => {
    if (project.length > 0 && dataset.length > 0) {
      setTablesLoading(true)
      api
        .get(`bigquery-exporter/?project=${project}&dataset=${dataset}`)
        .then(res => {
          setTablesLoading(false)
          if (res.data && res.data.results)
            setTables(res.data.results)
        })
        .catch(err => {
          setTablesLoading(false)
          console.error(err)
        })
    }
  }

  const handleSubmit = event => {
    if (event)
      event.preventDefault()

    let formData = new FormData()
    formData.append('emails', emails)
    formData.append('project', project)
    formData.append('dataset', dataset)
    formData.append('table', table)
    //formData.append('file', file)
    formData.append('serp', serp)
    formData.append('similarity', similarity)
    acceptedCharCheck && formData.append('accepted', acceptedCharCheck)

    if(table==null){
      notify(
        'Tabella non selezionata',
        'error'
      )
      return
    }
    console.log(formData)
    setLoading(true)
    api
      .post('keyword-clustering/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(response => {
        if (typeof response.data !== 'undefined') {
          let csvData = new Blob([response.data], {type: 'text/csv'})
          let csvUrl = URL.createObjectURL(csvData);
          let link = document.createElement("a");
          link.setAttribute("href", csvUrl);
          link.setAttribute("download", `main_keyword_report.csv`);
          document.body.appendChild(link);

          link.click(); // This will download the data file named "main_keywords_report.csv".
          notify(
            "Richiesta completata con successo",
            'success'
          )
        } else notify(
          'Richiesta fallita. Qualcosa è andato storto, riprovare o contattare un Innovation',
          'error'
        )

        setLoading(false)
        setFile(null)
      })
      .catch(err => {
        if (err?.response?.data) {
          if (err.response.data?.message.includes('ERROR Encoding keywords')) {
            setErrCharList(err.response.data?.errors);
            setShowCharCheck(true);
          }
          else {
            notify(
              err.response.data,
              'error'
            )
            console.log(err.response.data)
          }
        } else {
          notify(
            'Richiesta fallita. Qualcosa è andato storto, riprovare o contattare un Innovation',
            'error'
          )
        }
        setLoading(false)
        setFile(null)
      })
  }

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const confirmEvaluation = (e) => {
    e.preventDefault()
    setShowCharCheck(false)
    setAcceptedCharCheck(true)
  }

    useEffect(() => {
    setValidated(false)
    setTable('')
    setTables([])

    setDataset('')
    getDatasets()
  }, [project])

  useEffect(() => {
    setValidated(false)

    setTable('')
    getTables()
  }, [dataset])

  useEffect(() => {
    setValidated(false)

  }, [table])

  useEffect(() => {
    if(acceptedCharCheck) {
      handleSubmit()
    }
  }, [acceptedCharCheck])

  return (
    <>
      <Row>
        <div className="col pb-5">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row style={{ marginLeft: "0px" }}>
                <Col md={10}>
                  <h1 className="mb-0">Keyword Clustering</h1>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <CharCheckModal show={showCharCheck} onHide={setShowCharCheck} onSubmit={confirmEvaluation} errCharList={errCharList} />
              {loading ? (
                <Spinner animation="grow" />
              ) : (
                <Form onSubmit={handleSubmit} id="idea-form" action="">
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <label htmlFor="project" style={{ display: 'block' }}>
                          Progetto
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="select"
                          id="project"
                          value={project}
                          onChange={e => setProject(e.target.value)}
                          required
                        >
                          {projects.map(m => (
                            <option value={m} key={m}>
                              {m}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <label htmlFor="dataset" style={{ display: 'block' }}>
                          Dataset
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="select"
                          id="dataset"
                          value={dataset}
                          onChange={e => setDataset(e.target.value)}
                          required
                          disabled={(datasetsLoading) ? 'disabled' : ''}
                        >
                          <option value={''} disabled />
                          {datasets.length > 0 && datasets.map(m => (
                            <option value={m.dataset_id} key={m.dataset_id}>
                              {m.dataset_id}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <label htmlFor="table" style={{ display: 'block' }}>
                          Tabella
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="select"
                          id="table"
                          value={table}
                          onChange={e => setTable(e.target.value)}
                          required
                          disabled={(tablesLoading) ? 'disabled' : ''}
                        >
                          <option value={''} disabled />
                          {tables.length > 0 && tables.map(m => (
                            <option value={m.table_id} key={m.table_id}>
                              {m.table_id}
                            </option>
                          ))}
                        </Input>
                        <FormText color="muted" align={'left'}>
                          Seleziona la tabella di BigQuery da cui importare i dati (tipicamente "Competitor_Export_Merged")
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="file" style={{ display: 'block' }}>
                        Posizioni SERP da considerare: {serp}
                      </label>
                      <Input
                        id="serpRange"
                        name="SERP"
                        type="range"
                        min={1}
                        max={10}
                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                        defaultValue={5}
                        value={serp}
                        onChange={event => {
                          setSerp(event.target.value)
                        }}
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="file" style={{ display: 'block' }}>
                        Percentuale di similarità: {similarity}%
                      </label>
                      <Input
                        id="similarityRange"
                        name="Similarity%"
                        type="range"
                        min={1}
                        max={10}
                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                        defaultValue={6}
                        value={similarity / 10}
                        onChange={event => {
                          setSimilarity(event.target.value * 10)
                        }}
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row style={{display: "flex", justifyContent: "flex-start", marginTop:"50px", marginBottom:"50px"}}>
                    <Col>
                      <Button color="info" size="lg" onClick={handleSubmit}>Elabora report</Button>
                    </Col>
                  </Row>
                </Form>
              )}
              <ToastContainer />
            </CardBody>
          </Card>
        </div>
      </Row>
    </>
  )

}

export default KeywordClusteringForm
