import React from 'react'
import { BASE_URL } from '../../utils/index'
import KeywordClusteringForm from "./KeywordClusteringForm";
import SetTitle from '../../utils/set_title'

export default function KeywordClustering() {
	SetTitle('Keyword Clustering')
	return (
		<>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="ni ni-atom text-white" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">
						Keyword Clustering
						{/*<a href={BASE_URL + '/docs/ranking_tools/duplicate-keywords-gpt/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>*/}
					</h1>
				</div>
			</div>
			<div className="page-description">
				Una volta selezionata la fonte dato su BigQuery e settati i parametri desiderati,
				ottiene le main keyword basandosi sulle SERP delle keyword.
				Per "main keyword" si intende la keyword con il volume massimo all'interno di un cluster.
				Un cluster viene creato mettendo insieme le keyword con SERP simili.
			</div>

			<KeywordClusteringForm/>
		</>
	)
}