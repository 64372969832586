import React, { useContext } from 'react'
import { Col, Row, Pagination, Table } from 'react-bootstrap'

export default function PageSelector({context}) {
  const {
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
  } = useContext(context)

  return (
    <Pagination>
      <Pagination.First onClick={(e) => {
          e.preventDefault()
          gotoPage(1)
        }}
        disabled={!canPreviousPage} />
      <Pagination.Prev onClick={(e) => {
        e.preventDefault()
        previousPage()
      }}
      disabled={!canPreviousPage} />

      {Array(pageCount).fill(1).map((el, pi) => (
        <Pagination.Item className={(pageIndex == pi+1) ? 'active' : null} key={pi} onClick={(e) => {
          e.preventDefault()
            gotoPage(pi + 1)
          }}>
          {pi+1}
        </Pagination.Item>
      ))}

      <Pagination.Next onClick={(e) => {
          e.preventDefault()
          nextPage()
        }}
        disabled={!canNextPage} />
      <Pagination.Last onClick={(e) => {
        e.preventDefault()
          gotoPage(pageCount)
        }}
        disabled={!canNextPage} />
    </Pagination>
  )
}