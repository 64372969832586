import React, { useState, createContext } from 'react'

const AlertsFilterDataContext = createContext([])

const FilterDataProvider = ({ children }) => {
  const [filtersParams, setFiltersParams] = useState({})

  return (
    <AlertsFilterDataContext.Provider
      value={[filtersParams, setFiltersParams]}
    >
      {children}
    </AlertsFilterDataContext.Provider>
  )
}

export { AlertsFilterDataContext, FilterDataProvider }
