import React from 'react'
import { BASE_URL } from '../../utils/index'

function PageTitle({ title, subtitle = null, link = null, linkText = null, linkdocs = null }) {
  return (
    <div className="w-page-title">
      <h1>
        {title}
        {linkdocs && (
          <a href={BASE_URL + linkdocs} target="_blank" style={{paddingLeft: "10px"}}>
            <i className="fa-solid fa-question-circle fa-xs"></i>
          </a>
        )}
      </h1>
      {subtitle && <h2>{subtitle}</h2>}
      {link && (
        <h4>
          <span role="img" aria-label="Link">🔗</span> <a href={link}>{linkText}</a>
        </h4>
      )}
    </div>
  )
}

export default PageTitle
