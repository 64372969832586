import React, { useContext } from 'react'
import {
  api,
  loginRedirect,
  csrftoken,
  notify,
} from '../../../../utils/index_tagging_tool'
import _ from 'lodash'
import {saveCustomRule} from "../../../api_methods";

export function SaveOperation({ context }) {
  const { operationsPossible, checkedRules, dimension, setLoading } =
    useContext(context)

  const saveRule = async (rule) => {
    const data = {}
    data['headers'] = {
      save: true,
      dim_pk: dimension,
    }

    saveCustomRule(rule.id, data)
  }

  const saveRules = async (e) => {
    e.preventDefault()
    setLoading(true)
    const customRulesList = checkedRules.filter((r) => r.report_id)
    const ruleList = JSON.parse(JSON.stringify(customRulesList))
    await Promise.all(
      ruleList.map(async (rule) => {
        // Scorro i selezionati e cancello tutto
        if (rule.id !== -1) {
          try {
            await saveRule(rule)
          } catch (e) {
            notify(e.message, 'error')
          }
        }
      })
    )
    window.location.reload()
  }

  return (
    <>
      {operationsPossible.includes('Salva') && (
        <div className={'operationsDiv'} onClick={saveRules}>
          Salva
        </div>
      )}
    </>
  )
}
