import React, {useState} from 'react'
import {RuleRow} from "../../DetailUtility/RuleRow";
import {Accordion, Button, Card} from 'react-bootstrap';


export default function GroupRow(props) {
  const group = props.group

  return (
    <Accordion>
      <Card key={group.name}>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={group.name + 'elems'} className="shadow-none">
          {group.name}
          </Accordion.Toggle>
        </Card.Header>

        <Accordion.Collapse eventKey={group.name + 'elems'} key={group.name + 'elems'}>
          <div className="py-2 px-3 my-4">
            {group.dimensions.map((d, i) => (
              <Accordion key={'accordion' + i}>
                <Card key={'card' + i}>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey={d.dimension + i} className="shadow-none">
                      {d.dimension}
                    </Accordion.Toggle>
                  </Card.Header>
                
                  <Accordion.Collapse eventKey={d.dimension + i} key={i}>
                    <div className="pb-2 px-3 my-4">
                      {d.rules.map((r, i) => (
                        <RuleRow rule={r} key={d.dimension + '-' + i}/>
                      ))}
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ))}
          </div>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}
