import React from 'react'
import { Modal, Image, Button } from 'react-bootstrap'

const WModal = ({ show, onHide, onSubmit, cost= {min: 0, max: 0}, monthly= false }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Image src="https://i.giphy.com/media/i7vxmJ4rjSkcE/giphy.webp" fluid />
      <Modal.Header>
        <Modal.Title className="d-flex justify-content-center align-items-center w-100">
          <b className="text-center w-100">Sicuro di continuare?</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <h4 className="text-center">
          Costo stimato{monthly ? ' mensile' : ''}:
          <br/>
          {cost.min} ÷ {cost.max}
        </h4>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center align-items-center">
        <Button variant="primary" onClick={onSubmit}>
          Sì, continua
        </Button>
        <Button variant="danger" onClick={() => onHide(false)}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default WModal
