import React, { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Modal, Button, Accordion, Card } from 'react-bootstrap'
import { Row, Col, FormText, FormGroup, Input} from 'reactstrap'
import { filterGscDimensions, filterGscOperators, filterGscHelpers, iso3166_1_Alpha3 } from '../../utils/constants'

const FiltersGSCModal = ({ show, onHide, onSubmit, filters, setFilters }) => {
  const validationSchema = Yup.object().shape({
    dimension: Yup.string()
      .max(255, "Dimensione troppo lunga, non può contenere più di 255 caratteri")
      .required("E' necessario selezionare una dimensione"),
    operator: Yup.string()
      .max(255, "Operatore troppo lungo, non può contenere più di 255 caratteri")
      .required("E' necessario selezionare un operatore"),
    expression: Yup.string()
      .trim()
      .min(1, "Valore troppo corto, non può contenere meno di 1 carattere")
      // Max length 4096 characters, ma togliamo i caratteri di dimension e operator
      .max(4060, "Valore troppo lungo, non può contenere più di 4060 caratteri") 
      .required("E' necessario indicare un valore")
      .when('dimension', (dim) => {
        if (dim == "device") {
          return Yup.string().matches(/^(DESKTOP|MOBILE|TABLET)/, "Per la dimensione device è necessario usare uno di questi valori: DESKTOP, MOBILE, TABLET")
        }
        else if (dim == "country") {
          // 3-letter country code
          return Yup.string().oneOf(iso3166_1_Alpha3, "Per la dimensione country è necessario inserire un country code di 3 lettere (ISO 3166-1 alpha-3)")
        }
      })
  })

  const getDescription = (arr, val) => {
    const els = arr.filter((el) => el.value === val)
    if (els.length > 0) {
      return els[0].description
    }
    return ""
  }

  const addToFilters = (values) => {
    const newFilterIndex = {
      dimension: values.dimension,
      operator: values.operator,
      expression: values.expression
    }
    const filtersTemp = filters
    filtersTemp[0].filters.push(newFilterIndex)
    setFilters([...filtersTemp])
  }

  const confirmation = (values) => {
    addToFilters(values)
    onSubmit()
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        initialValues={{
          dimension: filterGscDimensions[0].value,
          operator: filterGscOperators[0].value,
          expression: ""
        }}
        onSubmit={confirmation}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            <Modal.Header>
              <Modal.Title className="d-flex justify-content-center align-items-center w-100">
                <h2 className="text-center w-100">Imposta il filtro che vuoi aggiungere</h2>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-right align-items-right flex-column">
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <label htmlFor="dimension" className="form-control-label">
                      Dimensione
                    </label>
                    <FormText color="muted" align={'left'}>
                      Seleziona una dimensione
                    </FormText>
                    <Input
                      className="form-control-alternative"
                      type="select"
                      id="dimension"
                      value={values.dimension}
                      onChange={event => {
                        handleChange(event)
                        setFieldValue('dimension', event.target.value)
                      }}
                    >
                      {filterGscDimensions.map(m => (
                        <option value={m.value} key={m.value}>
                          {m.value}
                        </option>
                      ))}
                    </Input>
                    <FormText color="muted" align={'left'}>
                      {getDescription(filterGscDimensions, values.dimension)}
                    </FormText>
                    {(errors.dimension) ? <FormText color="danger" align={'left'}> {errors.dimension} </FormText> : null}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label htmlFor="operator" className="form-control-label">
                      Operatore
                    </label>
                    <FormText color="muted" align={'left'}>
                      Seleziona un operatore
                    </FormText>
                    <Input
                      className="form-control-alternative"
                      type="select"
                      id="operator"
                      value={values.operator}
                      onChange={event => {
                        handleChange(event)
                        setFieldValue('operator', event.target.value)
                      }}
                    >
                      {filterGscOperators.map(m => (
                        <option value={m.value} key={m.value}>
                          {m.value}
                        </option>
                      ))}
                    </Input>
                    <FormText color="muted" align={'left'}>
                      {getDescription(filterGscOperators, values.operator)}
                    </FormText>
                    {(errors.operator) ? <FormText color="danger" align={'left'}> {errors.operator} </FormText> : null}
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <label htmlFor="expression" className="form-control-label">
                      Valore
                    </label>
                    <FormText color="muted" align={'left'}>
                      Indica un valore
                    </FormText>
                    <Input
                      className="form-control-alternative"
                      id="expression"
                      type={'text'}
                      value={values.expression}
                      onChange={event => {
                        handleChange(event)
                        setFieldValue('expression', event.target.value)
                      }}
                    >
                    </Input>
                    {(errors.expression) ? <FormText color="danger" align={'left'}> {errors.expression} </FormText> : null}
                  </FormGroup>
                </Col>
                
                {/* suggerimenti */}
                <Col md={12}>
                  <Accordion>
                    <Card>
                      <Accordion.Toggle as={Button} className="w-100 text-left shadow-none" eventKey={'parent'}>
                        Suggerimenti
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={'parent'}>
                        <Card.Body>
                          <Accordion>
                            {filterGscHelpers.map((el, index) => 
                              //el.suggerimento el.soluzioni
                              (
                                <Card key={'suggerimenti'+index}>
                                  <Accordion.Toggle as={Button} variant="outline-primary" className="w-100 text-left shadow-none" eventKey={'sugg'+index}>
                                    {el.suggerimento}
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={'sugg'+index}>
                                    <Card.Body>
                                      {el.soluzioni.map((soluzione, soluzioneIndex) => 
                                        (
                                          <p key={'sol'+soluzioneIndex}>{soluzione}</p>
                                        )
                                      )}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              )
                            )}
                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Row>
              
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center align-items-center">
              <Button variant="primary" onClick={handleSubmit}>
                Ok
              </Button>
              <Button variant="danger" onClick={() => onHide(false)}>
                Annulla
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default FiltersGSCModal
