import React, { useEffect, useState, Fragment } from 'react'
import { Row, Col, Container, Button, Form, FormText } from 'react-bootstrap'
import {
  prefix,
  loginRedirect,
  csrftoken,
  notify,
} from '../../../utils/index_tagging_tool'
import { api } from '../../../utils/index'
import Loading from '../../components/Loading'
import Select from 'react-select'
import _ from 'lodash'

const SetContext = React.createContext()

// Componente che consente la modifica di un set, potendo aggiungere dimensioni salvate e/o gruppi
function DimensionSetRow({ set }) {
  // Recupero di parametri dal contesto padre
  const { groups, savedDimensions, sets, setSets } =
    React.useContext(SetContext)

  // Variabili che mantengono gruppi e dim del set, e si modificano a seconda delle scelte dell'utente
  const [setGroups, setSetGroups] = useState(set.groups)
  const [setDims, setSetDims] = useState(set.dims)

  // Inserimento dei gruppi nel set a backend
  const saveGroups = (groupValues) => {
    let formData = new FormData()
    formData.append('groups', groupValues)

    api.put(prefix + 'dimension-sets/' + set.name + '/', formData)
      .then(loginRedirect)
      .then((data) => console.log(data))
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
      })
  }

  // Inserimento di saved dimensions nel set a backend
  const saveDims = (dims) => {
    let formData = new FormData()
    formData.append('savedDimensions', JSON.stringify(dims))

    api.put(prefix + 'dimension-sets/' + set.name + '/', formData)
      .then(loginRedirect)
      .then((data) => console.log(data))
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
      })
  }

  // Handled delle modifiche ai gruppi di un set
  const changeGroups = (value) => {
    const values = value.map((v) => v.value)
    if (setGroups == values) return
    setSetGroups(values)
    saveGroups(values)
  }

  // Handler delle modifiche alle dimensioni di un set
  const changeDims = (value) => {
    const pks = value.map((v) => v.value)
    const dims = savedDimensions.filter((dim) => pks.includes(dim.id))
    // Vanno testate entrambe le casistiche perchè differenceWith non funziona da entrambi i versi (sarebbe bello sapere perchè?)
    if (
      _.differenceWith(dims, setDims, _.isEqual).length === 0 &&
      _.differenceWith(setDims, dims, _.isEqual).length === 0
    )
      // Quando si verifica esco, perchè non è cambiato nulla tra quello che sto impostando e quello che c'era già
      return
    setSetDims(dims)
    saveDims(dims)
  }

  // Rimuovo a backend il set, questo NON cancella il suo contenuto (a differenza di quando cancelli un gruppo o una dimensione)
  const handleRemoveSet = (e) => {
    e.preventDefault()

    // Rimuovo il set da frontend, qui sono riuscito senza usare ForceUpdate di sorta. Progress bitch
    const setsCopy = JSON.parse(JSON.stringify(sets))
    setsCopy.splice(sets.indexOf(set), 1)
    setSets(setsCopy)

    let formData = new FormData()
    formData.append('name', set.name)

    api.delete(prefix + 'dimension-sets/', formData)
      .then(loginRedirect)
      .then((data) => {
        if (typeof data !== 'string') {
          notify(
            'Qualcosa è andato storto, ricontrollare o contattare un innovation',
            'error'
          )
        }
        console.log(data)
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
      })
  }

  return (
    <Row className={'text-left mb-3'}>
      <Col xs={2}>
        <strong>{set.name}</strong>
      </Col>
      <Col xs={4}>
        <Select
          inputId={`metrics`}
          options={groups
            .filter((g) => !setGroups.includes(g))
            .map((g) => {
              return { label: g, value: g }
            })}
          placeholder={'Seleziona una gruppo'}
          className="single-select"
          classNamePrefix="react-select"
          value={setGroups.map((g) => {
            return { label: g, value: g }
          })}
          onChange={changeGroups}
          isMulti={true}
          isClearable={true}
          isSearchable={true}
        />
      </Col>
      <Col xs={5}>
        <Select
          inputId={`metrics`}
          options={savedDimensions.map((dim) => {
            return { label: dim.name, value: dim.id }
          })}
          placeholder={'Seleziona una dimensione'}
          className="single-select"
          classNamePrefix="react-select"
          value={setDims.map((dim) => {
            return { label: dim.name, value: dim.id }
          })}
          onChange={changeDims}
          isMulti={true}
          isClearable={true}
          isSearchable={true}
        />
      </Col>
      <Col xs={1} className={'text-right'}>
        <strong onClick={handleRemoveSet} style={{ cursor: 'pointer' }}>
          ❌
        </strong>
      </Col>
    </Row>
  )
}

// Componente per la gestione dei set di gruppi (che mantengono sia gruppi che.. dimensioni..)
export default function DimensionSetListing({ groups, savedDimensions }) {
  // Set recuperati da backend
  const [sets, setSets] = useState([])

  useEffect(() => {
    // Recuperati al primo caricamento
    api.get(prefix + `dimension-sets/`)
      .then(loginRedirect)
      .then((data) => setSets(data))
  }, [])

  // Creazione di un nuovo set, con specifico nome
  const [setName, setSetName] = useState('')
  // Booleano per il loading quando si attende la risposta
  const [loadingSet, setLoadingSet] = useState(false)

  // Funzione che crea un set a backend (per ora buoto)
  const handleAddSet = (e) => {
    e.preventDefault()
    setLoadingSet(true)
    setSetName('')

    let formData = new FormData()
    formData.append('name', setName)

    api.post(prefix + 'dimension-sets/', formData)
      .then(loginRedirect)
      .then((data) => {
        if (typeof data !== 'object')
          notify('Esiste già un set con questo nome', 'warning')
        else {
          // Se pusho e risetto senza copiare lo stato si incasina, ammetto la mia inettitudine nel capire perchè
          const setsCopy = JSON.parse(JSON.stringify(sets))
          setsCopy.push(data)
          setSets(setsCopy)
        }
        setLoadingSet(false)
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
        setLoadingSet(false)
      })
  }

  return (
    <SetContext.Provider
      value={{
        sets: sets,
        setSets: setSets,
        groups: groups,
        savedDimensions: savedDimensions,
      }}
    >
      <div className="mt-5 mb-5">
        <Form>
          <Row className="mr-5 ml-5 row">
            <Col xs={12} className={'m-auto pb-3'}>
              <h4>Sets</h4>
            </Col>
          </Row>
          <Row className="mr-5 ml-5 row">
            <Col xs={12} className={'m-auto pb-3 text-left'}>
              <p>
                <strong>
                  Un set è un insieme di uno o più gruppi e/o dimensioni salvate
                </strong>
                , utilizzato per facilitare la fase di creazione. Da qui è
                possibile gestire set, aggiungendoli e rimuovendoli, e gestendo
                i gruppi al loro interno
              </p>
            </Col>
          </Row>
          <Row className="mr-5 ml-5 row">
            <Col xs={2}>
              <label className={'text-left'}>Nome</label>
            </Col>
            <Col xs={4}>
              <label className={'text-center'}>Gruppi</label>
            </Col>
            <Col xs={4}>
              <label className={'text-center'}>Dimensioni</label>
            </Col>
            <Col xs={1}>
              <label className={'text-right'}>Rimuovi</label>
            </Col>
          </Row>
          <Row className="mr-5 ml-5 row">
            <Col xs={12} className={'m-auto pb-3'}>
              {sets === [] ? (
                <Loading />
              ) : (
                <Fragment>
                  {sets.length > 0 ? (
                    <Fragment>
                      {sets.map((s, i) => (
                        <DimensionSetRow set={s} key={i} />
                      ))}
                    </Fragment>
                  ) : (
                    <p>Nessun set trovato</p>
                  )}
                </Fragment>
              )}
            </Col>
          </Row>
          <Row className="mr-5 ml-5 row">
            <Col xs={9}>
              <Form.Control
                type="text"
                value={setName}
                onChange={(e) => setSetName(e.target.value)}
              />
            </Col>
            <Col xs={3}>
              {loadingSet ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Button
                  type="submit"
                  variant="warning"
                  style={{ float: 'right' }}
                  onClick={handleAddSet}
                  disabled={setName === ''}
                >
                  Aggiungi set
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </SetContext.Provider>
  )
}
