import React from 'react'

// Questo era qui da prima di me, se avete rimostranze mi dispiace, le avevo anch'io
function FormSector({ label }) {
  return (
    <div style={{ color: 'gray', marginTop: '3%' }}>
      <label>{label}</label>
      <hr style={{ marginTop: '0.5rem' }} />
    </div>
  )
}

export default FormSector
