import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const EndDate = ({ context }) => {
  const { endDate, setEndDate } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="end-date" className="form-control-label">
        End Date:
      </label>
      <DatePicker
        className="form-control-alternative"
        required={true}
        selected={endDate ? new Date(endDate) : endDate}
        onChange={(date) => setEndDate(date)}
        showTimeSelect
        placeholderText='Inserisci una data'
        dateFormat="yyyy-MM-dd hh:mm aa"
        filterDate={(date) => {return date >= new Date();}}
        filterTime={(date) => {return date >= new Date();}}
      />
    </FormGroup>
  )
}

export default EndDate
