import React, {useEffect, useState} from 'react'
import {Button,} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/fontawesome-free-solid";
import DimensionsList from "./DimensionsList";
import Loading from "../../../components/Loading";
import {getUnsavedRules, saveCustomRule} from "../../../api_methods";
import {loginRedirect, notify} from "../../../../utils/index_tagging_tool";
import {Card, CardBody, CardHeader, Row} from "reactstrap";

export default function UnsavedRules({identifier, info}) {
  /**
   * Interface for visualize and save custom rules in linked dimensions.
   */
  // list of the dimensions of the group/report
  let dimensions = info.custom_dimensions
  // configuration field of the group/report
  let configuration_options = info.input_options

  // list of rules with no translation
  const [rules, setRules] = useState([])

  // variables for UI
  const [loaded, setLoaded] = useState(true)
  const [collapse, setCollapse] = useState(true)

  const getRules = () => {
    /**
     * Retrieves rules with no translations
     */
    setLoaded(false)
    getUnsavedRules(identifier).then(data => setRules(data)).then(() => setLoaded(true))
  }

  useEffect(() => {
    getRules()
  }, [])

  return (
    <Card className="bg-secondary">
      <div className={collapse ? '' : 'pb-3'}>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h2 className="mb-0">Stato salvataggio regole</h2>
          <button
            type="button"
            className="p-2 ml-4"
            onClick={() => setCollapse(!collapse)}
          >
            <FontAwesomeIcon icon={collapse ? faChevronDown : faChevronUp} />
          </button>
        </CardHeader>
        {!collapse && (
          <CardBody className="pt-3">
            <div className="pb-2 pt-2">
              <Row><label>Regole non salvate</label></Row>
              {rules.length > 0 ?
                <>
                  In questa sezione è presente una lista delle regole che non sono state salvate, pur appartenendo ad una dimensione linkata.
                  <br/>
                  Per ogni dimensione elencata, è possibile salvare le regole tramite il tasto dedicato.
                </> :
                loaded && <h4 className="center-block">Non è presente alcuna regola da salvare <span role="img" aria-label="Correct">✅</span></h4>}
              <br/>
            </div>

            {loaded ?
              <>
                <DimensionsList dimensions={rules} key={identifier}
                                 refreshRules={getRules} group={""}/>
                <Button onClick={() => getRules()} title="Refresh">
                  Refresh <span role="img" aria-label="refresh">🔃</span>
                </Button>
              </>
              : <Loading child={null}/>}
          </CardBody>
        )}
      </div>
    </Card>
  )

}
