import React from 'react'
import SemRushForm from './SemRushForm'
import { BASE_URL } from '../../utils/index'
import SetTitle from '../../utils/set_title'

export default function SemRush() {
	SetTitle('SemRush')
	return (
        <>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="fa fa-fire" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">
						SemRush
						<a href={BASE_URL + '/docs/ranking_tools/sem-rush/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>
					</h1>
				</div>
			</div>
			<div className="page-description">
                Una volta raccolte le keyword dal file e dal form
                verrà ritornato un report per l'endpoint selezionato. I report conterranno le seguenti metriche: volume, CPC, numero di risultati sul database della location selezionata, riguardanti le  keyword alla mail inserita.
            </div>
			<SemRushForm />
        </>
	)
}