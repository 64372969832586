import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function Motori({ context }) {
  const { motoreRicerca, setMotoreRicerca, motoriRicerca } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Motore
      </label>
      <Input
        className="form-control-alternative"
        type="select"
        id="motori"
        required={true}
        value={motoreRicerca}
        onChange={e => setMotoreRicerca(e.target.value)}
      >
        {motoriRicerca.map(m => (
          <option value={m.nome} key={m.nome}>
            {m.nome}
          </option>
        ))}
      </Input>
      <FormText color="muted" align={'left'}>
        Seleziona un motore di ricerca
      </FormText>
    </FormGroup>
  )
}
