import React, { useEffect, useState } from 'react'
import {Spinner} from 'react-bootstrap'
import {
  Col,
  FormGroup,
  Form,
  FormText,
  CardBody,
  Row,
  Button,
  Card,
  CardHeader,
  Input
} from "reactstrap";

import TagsInput from '../../components/TagsInput'
import CharCheckModal from "../../components/molecules/CharCheckModal";
import GPTEstimateModal from "../../components/molecules/GPTEstimateModal";
import { api } from '../../utils/index'
import { ToastContainer, toast } from 'react-toastify'
import { notify } from '../../utils/notify'

const DuplicateKeywordsGptForm = () => {
  const [file, setFile] = useState(null)
  const [emails, setEmails] = useState([])
  const lingue = ["it-it",  "de-de", "en-uk", "en-us",  "es-es",  "fr-fr", "en-ca", "fr-ca"]
  const [lingua, setLingua] = useState("it-it")
  const [volume, setVolume] = useState("")
  const [useGpt, setUseGpt] = useState(false)
  const [loading, setLoading] = useState(false)
  // Modal check caratteri
  const [showCharCheck, setShowCharCheck] = useState(false)
  const [errCharList, setErrCharList] = useState([])
  const [acceptedCharCheck, setAcceptedCharCheck] = useState(false)
  // Modal check gpt
  const [showGptCheck, setShowGptCheck] = useState(false)
  const [gptEstimateParams, setGptEstimateParams] = useState({
    n_keywords: -1,
    total_tokens: -1,
    estimate: -1,
  })
  const [acceptedGptCheck, setAcceptedGptCheck] = useState(false)

  const handleSubmit = event => {
    if (event)
      event.preventDefault()

    let formData = new FormData()
    formData.append('emails', emails)
    formData.append('file', file)
    formData.append('volume', volume)
    formData.append('lingua', lingua)
    formData.append('use_gpt', useGpt)
    formData.append('gpt_estimate_accepted', acceptedGptCheck)
    formData.append('keyword_error_accepted', acceptedCharCheck)

    if(file==null || lingua==''){
      notify(
        'File o lingua mancanti',
        'error'
      )
      return
    }
    setLoading(true)
    api
      .post('duplicate-keywords-gpt/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        if (typeof response.data !== 'undefined') {
          notify(
            "Richiesta mandata. Riceverai una mail al termine dell'elaborazione",
            'success'
          )
        } else {
          notify(
            'Richiesta fallita. Qualcosa è andato storto, riprovare o contattare un Innovation',
            'error'
          )
        }
        setAcceptedCharCheck(false)
        setAcceptedGptCheck(false)
        setLoading(false)
        setFile(null)
      })
      .catch(err => {
        if (err?.response?.data) {
          if (err.response.data?.message && err.response.data?.message.includes('ERROR Encoding keywords')) {
            setErrCharList(err.response.data?.errors);
            setShowCharCheck(true);
          }
          else if (err.response.data?.message && err.response.data?.message.includes('GPT Estimate')) {
            setGptEstimateParams(err.response.data?.estimates)
            setShowGptCheck(true);
          }
          else {
						notify(
              err.response.data,
              'error'
            )
            console.log(err.response.data)
            setLoading(false)
            setFile(null)
            setAcceptedCharCheck(false)
            setAcceptedGptCheck(false)
					}
        } else {
          setLoading(false)
          setFile(null)
          setAcceptedCharCheck(false)
          setAcceptedGptCheck(false)
        }
      })
  }

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const confirmEvaluation = (e) => {
    e.preventDefault()
    setShowCharCheck(false)
    setAcceptedCharCheck(true)
  }

  const cancelEvaluation = () => {
    setShowCharCheck(false)
    setAcceptedGptCheck(false)
    setLoading(false)
  }

  const confirmEstimate = (e) => {
    e.preventDefault()
    setShowGptCheck(false)
    setAcceptedGptCheck(true)
  }

  const cancelEstimate = () => {
    setShowGptCheck(false)
    setShowCharCheck(false)
    setLoading(false)
  }

  useEffect(() => {
    if(acceptedCharCheck) {
      handleSubmit()
    }
  }, [acceptedCharCheck])

  useEffect(() => {
    if(acceptedGptCheck) {
      handleSubmit()
    }
  }, [acceptedGptCheck])

  return (
    <>
      <Row>
        <div className="col pb-5">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row style={{ marginLeft: "0px" }}>
                <Col md={10}>
                  <h1 className="mb-0">Duplicate Keywords</h1>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <CharCheckModal show={showCharCheck} onHide={cancelEvaluation} onSubmit={confirmEvaluation} errCharList={errCharList} withExclusion={true} />
              <GPTEstimateModal show={showGptCheck} onHide={cancelEstimate} onSubmit={confirmEstimate} params={gptEstimateParams} />
              {loading ? (
                <Spinner animation="grow" />
              ) : (
                <Form onSubmit={handleSubmit} id="idea-form" action="">
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label htmlFor="file" style={{ display: 'block' }}>
                          File
                        </label>
                        <Input
                          className="form-control-alternative"
                          id={'file'}
                          type={'file'}
                          style={{ border: '0' }}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          required
                          onChange={event => {
                            setFile(event.target.files[0])
                          }}
                        />
                        <FormText color="muted" align={'left'}>
                          Carica un file contenente le tue keywords da verificare.{' '}
                          <br />
                          <b>
                            Il file deve contenere le colonne "Keyword" e "Volume"
                          </b>
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label htmlFor="report" className="form-control-label">
                          Email
                        </label>
                        <TagsInput
                          className="form-control-alternative"
                          id="email"
                          selectedTags={setEmails}
                          validator={validateEmail}
                          tags={emails}
                          type="text"
                        />
                        <FormText color="muted" align={'left'}>
                          <b>Premere invio</b> dopo ogni nuovo inserimento
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label htmlFor="lingua" style={{ display: 'block' }}>
                          Lingua
                        </label>
                        <Input
                          type="select"
                          className="form-control-alternative"
                          id="lingua"
                          value={lingua}
                          required
                          style={{ height: '44px' }}
                          onChange={event => {
                            setLingua(event.target.value)
                          }}
                        >
                          {lingue.map(lang => {
                            return (<option key={lang} value={lang}>{lang}</option>)
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label htmlFor="volume" className="form-control-label">
                          Volume
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          id="volume"
                          value={volume}
                          placeholder="Inserisci volume"
                          onChange={e => setVolume(e.target.value)}
                          required
                        />
                        <FormText color="muted" align={'left'}>
                          Inserisci il nome della colonna volume del foglio excel
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label htmlFor="use_gpt">
                          Main Keywords
                        </label>
                        <div style={{marginLeft:'+1.5em'}}>
                          <Input
                            className="form-control-alternative"
                            id="use_gpt"
                            type="checkbox"
                            style={{width: 'auto'}}
                            checked={useGpt}
                            onChange={() =>
                              setUseGpt(!useGpt)
                            }
                          />
                          <FormText color="muted" align={'left'}>
                            Usa <b>GPT</b> per identificare le main keywords<br/>
                          </FormText>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{display: "flex", justifyContent: "flex-end", marginRight:"50px"}}>
                    <Button color="info" size="lg" onClick={handleSubmit} >Submit Report</Button>
                  </Row>
                </Form>
              )}
              <ToastContainer />
            </CardBody>
          </Card>
        </div>
      </Row>
    </>
  )

}

export default DuplicateKeywordsGptForm
