import React from 'react'
import BigQueryExporterForm from './BigQueryExporterForm'
import { BASE_URL } from '../utils/index'
import SetTitle from '../utils/set_title'

export default function BigQueryExporter() {
	SetTitle('BigQuery Exporter')
	return (
		<>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="ni ni-folder-17 text-white" style={{ color: "white", fontSize: "35px" }}></i>
					<h1 className="page-title">
						BigQuery Exporter
						<a href={BASE_URL + '/docs/ranking_tools/bigquery-exporter/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>
					</h1>
				</div>
			</div>
			<div className="page-description">Permette di scaricare il dataset di uno studio
				keyword relativo a un progetto in un determinato periodo.
			</div>
			<BigQueryExporterForm />
		</>
	)
}