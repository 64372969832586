import React, { useState, useContext, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/fontawesome-free-solid'
import Loading from '../../../components/Loading'
import _ from 'lodash'

// Corrisponde alla riga dove nelle colonne normali ci sono i field di testo per la "ricerca" di un value/contine/non contiene
// Al posto di una ricerca, per ora ho inserito solo una serie di nomi di lingue, uno per colonna, per le lingue selezionate
// Più comprensibile se lo si vede a frontend
export function LanguageSearch({ context }) {
  const { showLanguages, selectedLanguages } = useContext(context)

  return (
    <>
      {showLanguages && (
        <>
          {selectedLanguages.map((lang) => (
            <div style={{ width: '200px' }}>
              <div className="px-1">{lang}</div>
            </div>
          ))}
        </>
      )}
    </>
  )
}
