import React from 'react'
import { Link } from 'react-router-dom'
import { WButton } from '../../atoms'

const WLinkButton = ({ label, variant, url, icon, style, className }) => {
  return (
    <Link to={url}>
      <WButton label={label} variant={variant} icon={icon} className={className}/>
    </Link>
  )
}

export default WLinkButton
