import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function VolumeStatic({ context }) {
  const { competitor, setCompetitor } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Ricerca Competitor SERP
      </label>
      <Input
        type="range"
        min={0}
        max={1}
        value={competitor ? '1' : '0'}
        style={{ width: '50px', paddingLeft: '0px', paddingRight: '0px' }}
        onChange={e => setCompetitor(e.target.value == '1' ? true : false)}
      />
      <FormText color="muted" align={'left'}>
        Aggiunge il foglio competitor all'export
      </FormText>
    </FormGroup>
  )
}
