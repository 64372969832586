import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import auth from '../auth/service'

export let BASE_URL = '' // sembra che si possa fare, anche se brutto
if (process.env.REACT_APP_ENV === 'production') {
  BASE_URL = 'https://ranking.webranking.tools'
} else if (process.env.REACT_APP_ENV === 'staging') {
  BASE_URL = 'https://staging.ranking.webranking.tools'
} else {
  BASE_URL = 'http://localhost:9998'
}

const baseApiAddress = `${BASE_URL}/api/1/`

let _api = axios.create({
  baseURL: baseApiAddress,
})

_api.interceptors.request.use(
  (config) => {
    //if (process.env.NODE_ENV === 'production') {
    if (config.baseURL === baseApiAddress && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${auth.getAccessToken()}`
    }
    //}

    return config
  },
  (error) => Promise.reject(error)
)

export const api = _api

export const GTM_ID = process.env.REACT_APP_GTM_ID

export const listingColumns = [
  {
    Header: 'Client',
    accessor: 'client',
    Cell: (item) => (
      <div className="main_column">
        <Link to={`/${item.original.slug}`}>{item.value}</Link>
      </div>
    ),
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    Cell: (item) => <div className="data_column">{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Total Media Cost',
    accessor: 'total_cost',
    Cell: (item) => <div className="data_column">{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Cost',
    accessor: 'cost',
    Cell: (item) => <div className="data_column">{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Bank Movement',
    accessor: 'bank_movement',
    Cell: (item) => <div className="data_column">{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Platform Fee',
    accessor: 'platform_fee',
    Cell: (item) => <div className="data_column">{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Fee W',
    accessor: 'w_fee',
    Cell: (item) => <div className="data_column">{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Revenue',
    accessor: 'revenue',
    Cell: (item) => <div className="data_column">{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Calculated Revenue',
    accessor: 'revenue_calc',
    Cell: (item) => <div className="data_column">{twoFractionDigitsFormatter(item.value)} €</div>,
  },
]

export const detailColumns = [
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    Cell: (item) => <div>{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Total Media Cost',
    accessor: 'total_cost',
    Cell: (item) => <div>{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Cost',
    accessor: 'cost',
    Cell: (item) => <div>{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Bank Movement',
    accessor: 'bank_movement',
    Cell: (item) => <div>{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Platform Fee',
    accessor: 'platform_fee',
    Cell: (item) => <div>{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Fee W',
    accessor: 'w_fee',
    Cell: (item) => <div>{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Revenue',
    accessor: 'revenue',
    Cell: (item) => <div>{twoFractionDigitsFormatter(item.value)} €</div>,
  },
  {
    Header: 'Calculated Revenue',
    accessor: 'revenue_calc',
    Cell: (item) => <div>{twoFractionDigitsFormatter(item.value)} €</div>,
  },
]

export const noFractionDigitsFormatter = (value) => {
  return new Intl.NumberFormat('it-IT', { maximumFractionDigits: 0 }).format(value)
}

export const getFirstDayOfThisMonth = () => {
  let date = new Date()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  return `01-${month < 10 ? `0${month}` : `${month}`}-${year}`
}

export const getTodayDate = () => {
  let date = new Date()
  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  return `${day < 10 ? `0${day}` : `${day}`}-${month < 10 ? `0${month}` : `${month}`}-${year}`
}
export const twoFractionDigitsFormatter = (value) => {
  return new Intl.NumberFormat('it-IT', { maximumFractionDigits: 2 }).format(value)
}

export const capitalize = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const transformDateFormat = (value) => {
  let chunks = value.split('-')
  return [chunks[2], chunks[1], chunks[0]].join('-')
}

export const transformDateForDefaultFilter = (value) => {
  let chunks = value.split('-')
  return [chunks[2], chunks[1], chunks[0]].join('-')
}

export const timeFormatter = (value) => {
  return new Date(value * 1000).toISOString().substr(11, 8)
}

export const fromTimeToSeconds = (value) => {
  const a = value.split(':')
  return +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
}

export const addPercentages = (data) => {
  const sum = data.reduce((acc, curr) => acc + curr.value, 0)

  return data.map((obj) => ({
    ...obj,
    percent: (obj.value / sum) * 100,
  }))
}

export const getDeltaValue = (selectedValue, compareValue, usePercentagePoint = false) =>
  usePercentagePoint
    ? selectedValue - compareValue
    : selectedValue !== 0
    ? ((selectedValue - compareValue) / compareValue) * 100
    : -100

export const dateMonthYearFromString = (s) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const splitted = s.split('-')
  return `${monthNames[Number(splitted[1]) - 1]} ${Number(splitted[0])}`
}

export const formatDateTime = data => {
  const d = new Date(data)
  const hours = d.getHours();
  let minutes = d.getMinutes();
  minutes = minutes < 10 ? '0'+minutes : minutes;
  const strTime = hours + ':' + minutes;
  return d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + "  " + strTime;
}

export const defaultFilters = [
  {
    "groupType": "and",
    "filters": [
      {
        "dimension": "page",
        "operator": "notContains",
        "expression": ".pdf"
      },
      {
        "dimension": "page",
        "operator": "notContains",
        "expression": ".doc"
      }
    ]
  }
]