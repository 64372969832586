import React, { useContext } from 'react'
import {Button, UncontrolledTooltip} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/fontawesome-free-solid'


export function LanguageShow({ context }) {
  const { showLanguages, setShowLanguages } = useContext(context)

  return (
    <div className="px-1" style={{ width: '100px' }}>
      <Button
        id="show-en"
        color="info"
        size="sm"
        onClick={() => setShowLanguages(!showLanguages)}
      >
        <span>Inglese</span>
        <FontAwesomeIcon
          icon={showLanguages ? faArrowLeft : faArrowRight}
          className="ml-2"
        />
      </Button>
      <UncontrolledTooltip target="show-en" placement='top'>
        Mostra il tag in inglese
      </UncontrolledTooltip>
    </div>
  )
}
