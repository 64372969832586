import React, { useContext } from 'react'
import {
  prefix,
  loginRedirect,
  csrftoken,
  notify,
} from '../../../../utils/index_tagging_tool'
import {
  api,
} from '../../../../utils/index'
import _ from 'lodash'

export function ThanosDisableOperation({ context }) {
  const { operationsPossible, checkedRules, dimension, setLoading } =
    useContext(context)

  const disableThanosRule = async (rule) => {
    return api.delete(`${prefix}thanos-rules/`, {data: { rule: rule.id, custom: dimension }})
  }

  const thanosRules = async (e) => {
    e.preventDefault()
    setLoading(true)
    const ruleList = JSON.parse(JSON.stringify(checkedRules))
    await Promise.all(
      ruleList.map(async (rule) => {
        // Scorro i selezionati e cancello tutto
        if (rule.id !== -1) {
          try {
            const response = await disableThanosRule(rule)
            loginRedirect(response)
          } catch (e) {
            notify(e.message, 'error')
          }
        }
      })
    )
    window.location.reload()
  }

  return (
    <>
      {operationsPossible.includes('Disabilita Thanos') && (
        <div className={'operationsDiv'} onClick={thanosRules}>
          Disabilita Thanos
        </div>
      )}
    </>
  )
}
