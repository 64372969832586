import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function Industry({ context }) {
  const { industry, setIndustry } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Industry
      </label>
      <Input
        className="form-control-alternative"
        id="industry"
        value={industry ? industry : ''}
        onChange={(e) => setIndustry(e.target.value)}
        type="text"
        onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
      />
    </FormGroup>
  )
}
