import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function ShortReport({ context }) {
  const { short, setShort } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Short Report
      </label>
      <div style={{marginLeft:'+1.5em'}}>
        <Input
          className="form-control-alternative"
          type="checkbox"
          id="name"
          checked={short}
          style={{width: 'auto'}}
          onChange={e => setShort(e.target.checked)}
        />
      <FormText color="muted" align={'left'}>
        Spunta la casella per limitare a 20 i risultati del foglio competitor
      </FormText>
      </div>
    </FormGroup>
  )
}
