import React from 'react'
import BulkIndexingForm from './BulkIndexingForm'
import { BASE_URL } from '../../utils/index'
import SetTitle from '../../utils/set_title'

export default function BulkIndexing() {
	SetTitle('Bulk Indexing')
	return (
        <>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="fa fa-rss " style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">
						Bulk Indexing
						<a href={BASE_URL + '/docs/ranking_tools/bulk-indexing/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>
					</h1>
				</div>
			</div>
			<div className="page-description">
                Una volta raccolti gli url dal file e dal form
                verranno indicizzati dall'API di google.
            </div>
			<BulkIndexingForm />
        </>
	)
}