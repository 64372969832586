import React from 'react'

import loader from '../images/loader.gif'

// Questo era qui da prima di me, se avete rimostranze mi dispiace, le avevo anch'io
function Loading() {
  return (
    <div className="u-loader-icon">
      <img alt="loading" src={loader} />
    </div>
  )
}

export default Loading
