import React from 'react'

// Questo era qui da prima di me, se avete rimostranze mi dispiace, le avevo anch'io
function FormSector({ label }) {
  return (
    <div style={{ color: 'gray' }}>
      <label>{label}</label>
      <hr />
    </div>
  )
}

export default FormSector
