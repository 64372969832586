import React, { useContext, useState, useEffect } from 'react'
import { Form, FormText, FormError, FormGroup, Input} from 'reactstrap'

export default function ScheduleDays({ context }) {
  const { scheduleDays, setScheduleDays } = useContext(context)
  const [ errorMessages, setErrorMessages ] = useState([])

  useEffect(() => {
    if (scheduleDays.length == 0) {
      return;
    }
    const errors = new Set()
    scheduleDays.split(',').forEach((valore) => {
      if (valore <= 0) {
        errors.add('Inserire i giorni in numeri maggiori di 0')
      }
      if (valore === '' || isNaN(valore)) {
        errors.add('Inserire i giorni in soli numeri')
      }
      if (valore > 28) {
        errors.add('Inserire dei giorni minori o uguali a 28')
      }
    })
    setErrorMessages(Array.from(errors))
  }, [scheduleDays])

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Giorni di esecuzione
      </label>
      <Input
        className="form-control-alternative"
        type="text"
        id="giorni_esecuzione"
        value={scheduleDays}
        placeholder="3,4,5..."
        onChange={e => {
          const v = e.target.value
          setScheduleDays(v.trim())
        }}
        required={true}
        valid={(errorMessages.length == 0 && scheduleDays.lenght > 0)}
      />
      <FormText color="muted" align={'left'}>
        Inserisci i giorni del mese separati da virgola senza spazi (es.: 14,15,16) in cui eseguirlo
      </FormText>
      <FormText color="danger" align={'left'}>
        {errorMessages && errorMessages.length > 0 && errorMessages.map((e, i) => 
          <div key={i}><span>{e}</span><br/></div>
        )}
      </FormText>
    </FormGroup>
  )
}
