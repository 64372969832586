import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

const Description = ({ context }) => {
  const { description, setDescription } = useContext(context)

  return (
    <FormGroup className="mb-3">
      <label className="form-control-label">Description:</label>
      <Input
        type='textarea'
        rows={3}
        value={description === null ? '' : description}
        onChange={(e) => setDescription(e.target.value)}
        onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
      />
    </FormGroup>
  )
}

export default Description
