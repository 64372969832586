import React, { Component }  from 'react';
import { Button, Container, Row, Col } from "reactstrap";


const UserHeader = () => {
  return (
    <>
    	<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="fa-solid fa-house-chimney fa-xl" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">Studio Keyword</h1>
				</div>
			</div>
    </>
  );
};

export default UserHeader;
