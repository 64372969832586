import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const GPTEstimateModal = ({ show, onHide, onSubmit, params }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title className="d-flex justify-content-center align-items-center w-100">
          Stima dei costi di OpenAI ChatGPT
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center flex-column">
        <p>
          Keyword trovate nel file: {params.n_keywords} <br/>
          Tokens calcolati: circa {params.total_tokens}
        </p>
        <h4>Il costo stimato per questa esecuzione è di {params.estimate}$</h4>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center align-items-center">
        <Button variant="primary" onClick={onSubmit}>
          Ok
        </Button>
        <Button variant="danger" onClick={() => onHide(false)}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GPTEstimateModal
