import React, { useState, Fragment, useEffect } from 'react'
import { Row, Col, Button, Form, FormText } from 'react-bootstrap'
import {
  prefix,
  loginRedirect,
  notify,
} from '../../../utils/index_tagging_tool'
import {
  api
} from '../../../utils/index'
import ExpandButton from '../../components/ExpandButton'
import DimensionGroup from "./DimensionGroup";
import {createDimensionGroup, deleteDimensionGroup} from "../../api_methods";
import {DimensionRow} from "./SavedDimensionRow";

// Componente che rappresenta un gruppo, con tutte le dimensioni in esso contenute
function GroupRow({ group, groups, savedDimensions, i, reloadListing }) {
  // ForceUpdate, usato dal figlio
  const reloadGroupRow = React.useReducer(() => ({}), {})[1]
  // Mostra o meno le dimensioni di un gruppo
  const [expand, setExpand] = useState(false)

  // Contatta il backend per l'eliminazione di un gruppo (con conseguente eliminazione di tutte le saved dimensions, gestita a backend)
  const handleRemoveGroup = (e) => {
    e.preventDefault()

    const answer = window.confirm(
      'Sei sicuro? Il gruppo e tutte le sue dimensioni verranno rimosse definitivamente'
    )
    if (!answer) return

    let formData = new FormData()
    formData.append('name', group)

    deleteDimensionGroup(formData)
      .then((data) => {
        if (typeof data !== 'string') {
          notify(
            'Qualcosa è andato storto, ricontrollare o contattare un innovation',
            'error'
          )
        } else {
          notify('Gruppo rimosso con successo', 'success')
          // Devo togliere il gruppo dalla lista e "ri-renderizzarla"
          groups.splice(i, 1)
          reloadListing()
        }
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
      })
  }

  return (
    <Fragment>
      <Row>
        <Col xs={4} className={'text-left'}>
          <strong>{group}</strong>
        </Col>
        <Col xs={4} className={'text-center'}>
          <ExpandButton expand={expand} setExpand={setExpand} />
        </Col>
        <Col xs={4} className={'text-right'}>
          <strong onClick={handleRemoveGroup} style={{ cursor: 'pointer' }}>
            ❌
          </strong>
        </Col>
      </Row>
      {expand && (
        <Fragment>
          <Row className="mt-2">
            <Col xs={1} />
            <Col xs={3}>
              <label className={'text-left'}>Nome dimensione</label>
            </Col>
            <Col xs={4}>
              <label className={'text-center'}>Regole</label>
            </Col>
            <Col xs={4}>
              <label className={'text-right'}>Rimuovi dimensione</label>
            </Col>
          </Row>
          {savedDimensions
            .filter((x) => x.group.name === group)
            .map((dim, index) => (
              <DimensionRow
                key={index + i}
                dim={dim}
                k={index}
                savedDimensions={savedDimensions}
                reloadGroupRow={reloadListing}
              />
            ))}
        </Fragment>
      )}
    </Fragment>
  )
}
/**
 Componente simile alla sua controparte di listing presente nei report, con un livello in più (i gruppi) e senza la possibilità di modifica di bq_mode/linking
 Oltre a una dimensione, consente la creazione di un gruppo
 */
function DimensionGroupListing({ savedDimensions, groups, groupsData }) {
  // ForceUpdate
  const reloadListing = React.useReducer(() => ({}), {})[1]

  // Variabili di stato per la creazione di un nuovo gruppo
  const [groupName, setGroupName] = useState('')
  const [groupNameValid, setGroupNameValid] = useState('')
  const [groupNameError, setGroupNameError] = useState('')
  // Booleano per la creazione di un nuovo gruppo
  const [loadingGroup, setLoadingGroup] = useState(false)

  // Variabili di stato per la creazione di una nuova dimensione salvata
  const [selectedGroup, setSelectedGroup] = useState(groups[0])
  const [dimName, setDimName] = useState('')
  const [dimNameValid, setDimNameValid] = useState(null)
  const [dimNameError, setDimNameError] = useState(null)
  // Booleano per la creazione di una nuova dimensione salvata
  const [loadingDim, setLoadingDim] = useState(false)

  useEffect(() => {
    // Quando cambia la lunghezza dei gruppi ho fatto un'aggiunta o una rimozione, alchè devo resettare gli indicatori di correttezza dei gruppi
    setGroupName('')
    setGroupNameError('')
    setGroupNameValid('')
  }, [groups.length])

  useEffect(() => {
    // Quando cambia la lunghezza dei gruppi ho fatto un'aggiunta o una rimozione, alchè devo resettare gli indicatori di correttezza dei gruppi
    setDimName('')
    setDimNameError('')
    setDimNameValid('')
  }, [savedDimensions.length])

  const changeGroupName = (e) => {
    // Handler dell'inserimento del nome di un gruppo da parte dell'utente
    setGroupName(e.target.value)
    setGroupNameError('')
    setGroupNameValid('')
    if (groups.filter((g) => e.target.value === g).length > 0)
      setGroupNameError('Gruppo già presente')
    else if (e.target.value === '')
      setGroupNameError('Non puoi inserire un nome vuoto')
    else setGroupNameValid('Il nome inserito è valido')
  }

  const changeDimName = (e) => {
    // Handler dell'inserimento del nome di una dimensione da parte dell'utente
    setDimName(e.target.value)
    setDimNameError('')
    setDimNameValid('')
    // Controllo se esiste una dimensione salvata, associata allo stesso gruppo, il cui nome (a cui va aggiunto il gruppo tra parentesi) è identico al nome scelto
    if (
      savedDimensions
        .filter((dim) => dim.group === selectedGroup)
        .map((x) => x.display_name.toLowerCase())
        // Da fare un piccolo accrocchio dovuto sempre al fatto che le saved dimension arrivano col nome del gruppo da serializer
        .includes(`${e.target.value} (${selectedGroup})`.toLowerCase())
    ) {
      setDimNameError('Dimensione già presente nel gruppo')
    } else if (e.target.value === '')
      setDimNameError('Non puoi inserire un nome vuoto')
    else setDimNameValid('Il nome inserito è valido')
  }

  // Funzione che contatta il backend per l'inserimento di un nuovo gruppo, dando feedback all'utente con notify
  const handleAddGroup = (e) => {
    e.preventDefault()
    setLoadingGroup(true)

    let formData = new FormData()
    formData.append('name', groupName)

    createDimensionGroup(formData).then((data) => {
      if (typeof data !== 'string')
        notify('Esiste già un gruppo con questo nome', 'warning')
      else {
        notify('Gruppo aggiunto', 'success')
        groups.push(groupName)
        reloadListing()
      }
      setLoadingGroup(false)
    })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
        setLoadingGroup(false)
      })
  }

  // Funzione che contatta il backend per l'inserimento di una nuova dimensione salvata, dando feedback all'utente con notify
  const handleAddDim = (e) => {
    e.preventDefault()

    setLoadingDim(true)

    let formData = new FormData()
    formData.append('nome', dimName)
    formData.append('nomeColonna', dimName)
    formData.append('gruppo', selectedGroup)

    api.post(prefix + 'saved-dimensions/', formData)
      .then(loginRedirect)
      .then((data) => {
        if (typeof data !== 'object')
          notify('Esiste già una dimensione con questo nome', 'warning')
        else {
          notify(`Dimensione aggiunta al gruppo ${selectedGroup}`, 'success')
          savedDimensions.push(data)
        }
        setLoadingDim(false)
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
        setLoadingDim(false)
      })
  }

  return (
    <div>
        <div className='listing mr-5 ml-5'>
          {groupsData.filter((x) => x.is_sub === false).map((g) => (
            <DimensionGroup
              group={g}
              savedDimensions={savedDimensions}//.filter((x) => x.group.name === g.name)
              key={g.name}
              reloadListing={reloadListing}
            />
          ))}
        </div>
        <Form>
          <Row className={'mt-5 ml-5 mr-5'}>
            <Col xs={9}>
              <Form.Control
                type="text"
                isValid={groupNameValid}
                isInvalid={groupNameError}
                value={groupName}
                onChange={changeGroupName}
              />
              {groupNameError !== '' && (
                <FormText style={{ color: 'red' }} align={'left'}>
                  {groupNameError}
                </FormText>
              )}
              {groupNameValid !== '' && (
                <FormText style={{ color: 'green' }} align={'left'}>
                  {groupNameValid}
                </FormText>
              )}
              {groupNameError === groupNameValid && (
                <FormText style={{ color: 'white' }} align={'left'}>
                  Per mantenere lo spazio fra le righe
                </FormText>
              )}
            </Col>
            <Col xs={3}>
              {loadingGroup ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Button
                  type="submit"
                  variant="warning"
                  disabled={groupNameError || groupName === ''}
                  className="px-0"
                  style={{ width: '100%' }}
                  onClick={handleAddGroup}
                >
                  Aggiungi gruppo
                </Button>
              )}
            </Col>
          </Row>
          <Row className={'mt-5 ml-5 mr-5'}>
            <Col xs={3}>
              <Form.Control
                as="select"
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
              >
                {groups.map((g, i) => (
                  <option key={i} value={g}>
                    {g}
                  </option>
                ))}
              </Form.Control>
            </Col>
            <Col xs={6}>
              <Form.Control
                type="text"
                isValid={dimNameValid}
                isInvalid={dimNameError}
                value={dimName}
                onChange={changeDimName}
              />
              {dimNameError !== '' && (
                <FormText style={{ color: 'red' }} align={'left'}>
                  {dimNameError}
                </FormText>
              )}
              {dimNameValid !== '' && (
                <FormText style={{ color: 'green' }} align={'left'}>
                  {dimNameValid}
                </FormText>
              )}
              {dimNameError === dimNameValid && (
                <FormText style={{ color: 'white' }} align={'left'}>
                  Per mantenere lo spazio fra le righe
                </FormText>
              )}
            </Col>
            <Col xs={3}>
              {loadingDim ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Button
                  type="submit"
                  variant="warning"
                  disabled={dimNameError || dimName === ''}
                  className="px-0"
                  style={{ width: '100%' }}
                  onClick={handleAddDim}
                >
                  Aggiungi dimensione
                </Button>
              )}
            </Col>
          </Row>
        </Form>
    </div>
  )
}

export default DimensionGroupListing
