import { useState, useEffect } from 'react'
import { api } from '../../utils/index'

export const useFetch = ({ url, params = {} }) => {
  const [data, setData] = useState({
    data: [],
    info: {},
    loading: true,
    error: undefined,
  })

  useEffect(() => {
    ;(async () => {
      try {
        let res = {}

        Object.keys(params).length === 0
          ? (res = await api.get(url))
          : (res = await api.get(url, {
              params: params,
            }))

        if (res.status === 200) {
          const { data } = res

          if ('results' in data) {
            const { results, ...rest } = data
            setData({
              data: results,
              info: rest,
              loading: false,
            })
          } else {
            setData((prev) => ({
              ...prev,
              data: data,
              loading: false,
            }))
          }
        } else {
          setData((prev) => ({
            ...prev,
            loading: false,
            error: {
              status: res.status,
              message: res.statusText,
            },
          }))
          console.error(`Error! status: ${res.status}, statusText: ${res.statusText}`)
        }
      } catch (error) {
        console.error(`Error ${error}`)
      }
    })()
  }, [])

  return data
}
