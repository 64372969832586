import React, { useState } from 'react'
import { Row, Col, Form, Card } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/fontawesome-free-solid'

export default function DetailRuleList({values}) {

  const [collapse, setCollapse] = useState(true)

  return (
    <Card className="ml-5 mr-5 mt-3 mb-3 border-dark">
      <Form onSubmit={() => {}}>
        <div className={collapse ? '' : 'pb-3'}>
          <div className="d-flex align-items-center justify-content-between  ml-1">
            <h3 className="mb-0">Regole</h3>

            <button
              type="button"
              className="p-2 ml-4"
              onClick={() => setCollapse(!collapse)}
            >
              <FontAwesomeIcon icon={collapse ? faChevronDown : faChevronUp} />
            </button>
          </div>
        </div>
        {!collapse && (
          <>
            <Row className={'mb-3 text-left'}>
              {/*<h4 className="mb-2">Italiano</h4>*/}
              <Col xs={12}>
                {values.rules.map((r, i) => (
                  <i>
                    {r.rule.value}
                    {i !== values.rules.length - 1 ? '|' : ''}
                  </i>
                ))}
              </Col>
            </Row>
            <Row className={'mb-3 text-left'}>
              {/*<h4 className="mb-2">Inglese</h4>*/}
              <Col xs={12}>
                {values.rules.map((r, i) => (
                  <i>
                    {r.rule.Inglese}
                    {i !== values.rules.length - 1 && r.rule.Inglese ? '|' : ''}
                  </i>
                ))}
              </Col>
            </Row>
          </>)}
      </Form>
    </Card>
  )
}
