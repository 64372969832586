import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { prefix, loginRedirect } from '../../../utils/index_tagging_tool'
import { api} from '../../../utils/index'
import PageTitle from '../../components/PageTitle'
import Loading from '../../components/Loading'
import SimulateRule from './SimulateRule'
import UserHeader from "../../../components/Headers/ReportingHeader";
import SetTitle from '../../../utils/set_title'

// COMPONENETE VECCHIO: pensato per mostrare il "dettaglio" di una regola, non più usato ma può darsi che lo vogliano riesumare in futuro
export default function DetailRules(props) {
  SetTitle('Tagging tool - Regole custom')
  const [rule, setRule] = useState(null)
  const [id, setId] = useState(props.match.params.id)
  const saved = rule && !rule.report_id

  useEffect(() => {
    api.get(prefix + `custom-rules/${id}/`)
      .then(loginRedirect)
      .then((data) => setRule(data))
  }, [id])

  return (
    <Container>
      <UserHeader/>
      {rule ? (
        <Fragment>
          <PageTitle
            title={`${rule.id} - ${rule.rule.value} ${
              saved ? '(Salvata)' : ''
            }`}
          />
          <div className="Content-box">
            <Row className={'m-auto'}>
              <Col xs={12} className={'m-auto pb-3'}>
                <h4>Regola</h4>
              </Col>
            </Row>
            {rule.rule.conditions.map((c, i) => (
              <Fragment key={i}>
                <Row>
                  <Col xs={4} style={{ wordWrap: 'break-word' }}>
                    {c.operator}
                  </Col>
                  <Col xs={4} style={{ wordWrap: 'break-word' }}>
                    {c.operand}
                  </Col>
                  <Col xs={4}>
                    <strong>{c.next ? c.next : '/'}</strong>
                  </Col>
                </Row>
                <hr />
              </Fragment>
            ))}
          </div>
          {!saved && <SimulateRule rule={rule.rule} id={rule.report_id} />}
          {/*
                    Spostato in DetailDimensions
                    dimensions.length > 0 ?
                        <DetailDimensions dimensions={dimensions} values={dimensionValues} options={dimensionOptions} id={rule.id}/>
                        : <p>Nessuna dimensione o nessun report per la regola</p>
                    */}
        </Fragment>
      ) : (
        <Loading />
      )}
    </Container>
  )
}
