import React, { useState, useEffect } from 'react'
import {Row, Col, Form, Button, Card} from 'reactstrap'
import {
  prefix,
  loginRedirect,
  csrftoken,
  notify,
} from '../../../utils/index_tagging_tool'
import {
  api,
} from '../../../utils/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/fontawesome-free-solid'
import {getDimensionGroups} from "../../api_methods";

// Componente per gestire ils alvataggio di una dimensione custom, quindi la creazione di una dimensione salvata a partire da una custom
export default function DetailSave({ origin, name, reportName }) {
  // Parametri impostabili dall'utente da mandare a db
  // Queste porcherie di replace e trim sono perchè la dimensione custom viene di solito creata aggiungendo il nome del report in cui si trova
  // (per evitare di avere 800 dim con lo stesso nome a db). Per la versione salvata quella parte del nome è inutile
  const [nome, setNome] = useState(name.replace(` ${reportName}`, ''))
  const [nomeColonna, setNomeColonna] = useState(
    name ? name.trim().replace(` ${reportName}`, '') : null
  )
  const [groups, setGroups] = useState([])
  const [group, setGroup] = useState('Generico') // il gruppo di default è quello denerico

  // Booleani per gestire caricamento e/o apertura e chiusura del blocco (Gabri lo vuole nascondere e usare solo lui)
  const [loading, setLoading] = useState(false)
  const [collapse, setCollapse] = useState(true)

  // funzione che contatta il backend per creare la nuova dimensione
  const createSavedDimension = (e) => {
    e.preventDefault()

    setLoading(true)

    let formData = new FormData()
    formData.append('id', origin)
    formData.append('nome', nome.replace(` ${reportName}`, ''))
    formData.append('nomeColonna', nomeColonna.replace(` ${reportName}`, ''))
    formData.append('gruppo', group)

    api.post(prefix + 'saved-dimensions/', formData)
      .then(loginRedirect)
      .then((data) => {
        if (typeof data == 'object') {
          window.location.reload()
          return
        } else if (typeof data == 'string') {
          // C'è un controllo a backend per vedere di non far casino con eventuali regole dipendenti che esistono in questa dimensione
          notify(
            `La dimensione contiente regole che si riferiscono a "${data}", ma questa non
          è presente nel gruppo da te scelto. Selezionare un altro gruppo o creare una dimension ${data} in ${group}`,
            'warning'
          )
          setLoading(false)
          return
        }
        notify('Esiste già una dimensione con questo nome', 'warning')
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
        setLoading(false)
      })
  }

  // Al primo caricamento scarico tutti i gruppi, per poter avere il selettore (una dimensione va salvata in un gruppo)
  useEffect(() => {
    getDimensionGroups().then((data) => {setGroups(data)}).catch(console.log)
  }, [])

  return (
    <Card className="ml-5 mr-5 mt-3 border-dark">
      <Form onSubmit={createSavedDimension}>
        <div className={collapse ? '' : 'pb-3'}>
          <div className="d-flex align-items-center justify-content-between ml-1">
            <h4 className="mb-0">Salva dimensione (NON USARE)</h4>
            {/* Messaggio intimidatorio per l'average Lucio che fa dei pocci con questa funzionalità */}
            <button
              type="button"
              className="p-2 ml-4"
              onClick={() => setCollapse(!collapse)}
            >
              <FontAwesomeIcon icon={collapse ? faChevronDown : faChevronUp} />
            </button>
          </div>
        </div>
        {!collapse && (
          <>
            <Row className="ml-2">
              <Col xs={4} className={'pb-3'}>
                <label>Nome</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              </Col>
              <Col xs={4} className={'pb-3'}>
                <label>Nome colonna</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={nomeColonna}
                  onChange={(e) => setNomeColonna(e.target.value)}
                />
              </Col>
              <Col xs={4} className={'pb-3'}>
                <label>Gruppo</label>
                <select
                  className="form-control"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                >
                  {groups.length > 0 ? (
                    groups.map((g) => <option value={g.name}>{g.name}</option>)
                  ) : (
                    <option value="Generico">Generico</option>
                  )}
                </select>
              </Col>
            </Row>
            <Row className="ml-2">
              <Col xs={12} className={'pb-3 text-right'}>
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <Button type="submit" variant="success">
                    Salva
                  </Button>
                )}
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Card>
  )
}
