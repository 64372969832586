import React, { useState, useContext, useEffect } from 'react'
import { DeSaveOperation } from './DeSave'
import { SaveOperation } from './Save'
import { DeleteOperation } from './Delete'
import { DisableOperation } from './Disable'
import { MoveOperation } from './Move'
import { EnableOperation } from './Enable'
import { ThanosDisableOperation } from './DisableThanos'
import { ThanosEnableOperation } from './EnableThanos'
import Loading from '../../../components/Loading'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/fontawesome-free-solid'

// OPTIONS
const foo = [
  'Salva', // porta una regola da custom a linked
  'Desalva', // copia una regola saved e la scrive sulla custom, disabilita la saved per quella custom
  'Sposta', // muove una regola dalla dimensione corrente a una delle altre
  'Abilita', // versione bulk del tastino per abilitare
  'Disabilita', // versione bulk del tastino per disabilitare
  'Elimina', // versione bulk del cancella una regola
]

const OperationsContext = React.createContext()

// Componente padre che gestisce tutte le operazioni
// NB: ogni operazione ha un suo sotto-componente che chiama metodi per la singona regole N volte, dove N sono le regole selezionate nella tabella
export function OperationsManager({ context }) {
  const {
    checked,
    setChecked,
    fillChecked,
    emptyChecked,
    otherDimensions,
    rules,
    removeRule,
    dimension,
    dimensionType,
    thanosRules,
    disabledRules,
    linked,
  } = useContext(context)

  // Recupero le regole basandomi sugli id
  const checkedRules = rules.filter((r) => checked.includes(r.id))

  const [operationsPossible, setOperationsPossible] = useState([])
  const [showSelector, setShowSelector] = useState(false)
  const [loading, setLoading] = useState(false)
  const calculateOperationsPossible = () => {
    const ops = []

    // Save
    // Inclusa se c'è almeno una regola NON salvata nella selezione, se ha un id (è già stata creata) e se la dimensione è linkata
    if (
      checkedRules.filter((r) => r.report_id).length > 0 &&
      checkedRules.filter((r) => r.id === -1).length === 0 &&
      linked != null
    )
      ops.push('Salva')

    // Desave
    // Inclusa se c'è almeno una regola NON salvata nella selezione, se ha un id (è già stata creata) e se la dimensione è linkata
    if (
      checkedRules.filter((r) => !r.report_id).length > 0 &&
      checkedRules.filter((r) => r.id === -1).length === 0 &&
      linked != null
    )
      ops.push('Desalva')

    // Move
    // Inclusa sse ci sono regole con report id (custom) e senza id (quindi non ancora create) nella selezione
    if (
      checkedRules.filter((r) => r.id === -1).length === 0
    )
      ops.push('Sposta')

    // Disable
    // Inclusa sse non ci sono regole senza id (quindi non ancora create) nella selezione, e sono tutte disabilitate
    if (
      checkedRules.filter((r) => r.id === -1 || disabledRules.includes(r.id))
        .length === 0 &&
      dimensionType == 'custom-dimensions'
    )
      ops.push('Disabilita')

    // Enable
    // Inclusa sse non ci sono regole senza id (quindi non ancora create) nella selezione, e sono tutte abilitate
    if (
      checkedRules.filter((r) => r.id === -1 || !disabledRules.includes(r.id))
        .length === 0 &&
      dimensionType == 'custom-dimensions'
    )
      ops.push('Abilita')

    // Abilita Thanos
    // Inclusa sse non ci sono regole senza id (quindi non ancora create) nella selezione, e sono tutte disabilitate
    if (
      checkedRules.filter((r) => r.id === -1 || thanosRules.includes(r.id) || disabledRules.includes(r.id))
        .length === 0 &&
      dimensionType == 'custom-dimensions'
    )
      ops.push('Abilita Thanos')

    // Disabilita Thanos
    // Inclusa sse non ci sono regole senza id (quindi non ancora create) nella selezione, e sono tutte abilitate
    if (
      checkedRules.filter((r) => r.id === -1 || !thanosRules.includes(r.id))
        .length === 0 &&
      dimensionType == 'custom-dimensions'
    )
      ops.push('Disabilita Thanos')

    // Delete
    // Inclusa sempre (anche le non create si possono cancellare, solo non esistono a db quindi sono rimosse solo dall'interfaccia)
    // ops.push("Elimina"); Gabri suggerisce di toglierla

    setOperationsPossible(ops)
  }
  useEffect(() => {
    // Se sono state selezionate regole, calcolo le operazioni possibili
    checkedRules.length > 0 && calculateOperationsPossible()
    // Se in qualche momento non ho più regole selezionate, disabilito le operazioni possibili e il selettore
    checkedRules.length === 0 &&
      setOperationsPossible([]) &&
      setShowSelector(false)
  }, [checkedRules.length])

  useEffect(() => {
    // Quando vado in loading, chiudo il selettore
    loading && setShowSelector(false)
  }, [loading])

  const handleCheckBox = (e) => {
    if (e.target.checked) fillChecked()
    else emptyChecked()
  }

  return (
    <div style={{ width: '200px' }}>
      <fieldset className="w-seo-control-field d-flex align-items-center">
        <input
          type="checkbox"
          onClick={handleCheckBox}
          className="mr-2"
          style={{ width: '20px' }}
        />
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <button
            type="button"
            className="btn btn-outline-dark"
            disabled={operationsPossible.length === 0}
            onClick={(e) => setShowSelector(!showSelector)}
            style={{ flex: '1' }}
          >
            Operazioni
          </button>
        )}
        <div
          style={{ display: showSelector ? 'block' : 'none' }}
          className={'overlay'}
        >
          <div className="tiny-modal">
            <button
              type="button"
              className="tiny-modal__close"
              onClick={() => setShowSelector(false)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="dimension-selector">
              <OperationsContext.Provider
                value={{
                  checkedRules: checkedRules,
                  checked: checked,
                  setChecked: setChecked,
                  operationsPossible: operationsPossible,
                  rules: rules,
                  removeRule: removeRule,
                  dimension: dimension,
                  otherDimensions: otherDimensions,
                  setLoading,
                  dimensionType,
                }}
              >
                <DeSaveOperation context={OperationsContext} />
                <SaveOperation context={OperationsContext} />
                <EnableOperation context={OperationsContext} />
                <DisableOperation context={OperationsContext} />
                <MoveOperation context={OperationsContext} />
                <DeleteOperation context={OperationsContext} />
                <ThanosDisableOperation context={OperationsContext} />
                <ThanosEnableOperation context={OperationsContext} />
              </OperationsContext.Provider>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  )
}
