import { toast } from 'react-toastify'

let BASE_URL = ''
if (process.env.REACT_APP_ENV === 'production') {
  BASE_URL = 'https://ranking.webranking.tools'
} else if (process.env.REACT_APP_ENV === 'staging') {
  BASE_URL = 'https://staging.ranking.webranking.tools'
} else {
  BASE_URL = 'http://localhost:9998'
}

const baseApiAddress = `${BASE_URL}/api/1/tagging-tool/`

const getCookie = (name) => {
  if (!document.cookie) {
    return null
  }

  const xsrfCookies = document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith(name + '='))

  if (xsrfCookies.length === 0) {
    return null
  }
  return decodeURIComponent(xsrfCookies[0].split('=')[1])
}

export function notify(message, kind) {
  toast.configure()
  switch (kind) {
    case 'success':
      return toast.success(message, {
        containerId: 'notify',
        icon: true,
        autoClose: 5000,
      })
    case 'error':
      return toast.error(message, {
        containerId: 'notify',
        icon: true,
        autoClose: false,
      })
    case 'warning':
      return toast.warn(message, {
        containerId: 'notify',
        icon: true,
        autoClose: 5000,
      })
    case 'info':
      return toast.info(message, {
        containerId: 'notify',
        autoClose: 1000,
      })
    default:
    // noop
  }
}

export function loginRedirect(res) {
  // In produzione il redirect a BASE_URL porta a un loop, hard-codato l'url di login per risolvere
  return res.data
}

export const api = baseApiAddress
export const prefix = '/tagging-tool/'
export const csrftoken = getCookie('csrftoken')

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
