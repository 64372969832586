import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'
import { dateMonthYearFromString } from '../../utils/index'

export default function FormulaMonths({ context }) {
  const { month, setMonth, formula, formulaMonths } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Mese
      </label>
      <Input 
        className="form-control-alternative"
        type="select" 
        id="mese" 
        value={month} 
        //required={true}
        onChange={(e) => setMonth(e.target.value)}
        >
        {
          // Controllo che esista una lista di mesi per questa formula
          formulaMonths.hasOwnProperty(formula) &&
            formulaMonths[formula].map((m) => (
              <option value={m} key={m}>
                {dateMonthYearFromString(m)}
              </option>
            ))
        }
      </Input>
      <FormText color="muted" align={'left'}>
        Mese di validità della formula
      </FormText>
    </FormGroup>
  )
}
