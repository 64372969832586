import React, { useState } from 'react'
import { Row, Col, Container, Button, Form } from 'reactstrap'
import {
  prefix,
  notify,
  loginRedirect,
} from '../../../utils/index_tagging_tool'
import {
  api,
} from '../../../utils/index'

import SettingsBox from './ConfigurationBox/SettingsBox'
import InputBox from './ConfigurationBox/InputBox'
import OutputBox from './ConfigurationBox/OutputBox'
import CloneReport from "./CloneReport";

function SeoConfiguration({
  reportId,
  reportFile,
  reportClient,
  reportLanguage,
  reportLingua,
  reportKw,
  reportVol,
  reportOutput,
  reportName,
  reportGroups,
  groupOptions,
  updateReport,
  seo,
}) {
  const [nome, setNome] = useState(reportName)
  const [nomeError, setNomeError] = useState(false)
  const [gruppi, setGruppi] = useState(reportGroups)
  const [file, setFile] = useState(null)
  const [fileMode, setFileMode] = useState('a')
  const [editFile, setEditFile] = useState(false)
  const [client, setClient] = useState(reportClient)
  const [language, setLanguage] = useState(reportLanguage)
  const [lingua, setLingua] = useState(reportLingua)
  const [kw, setKw] = useState(reportKw)
  const [vol, setVol] = useState(reportVol)

  const [loading, setLoading] = useState(false)
  const [running, setRunning] = useState(false)

  const ratio = {
    title: 3,
    form: 4
  }

  // Funzione per avviare un report da interfaccia. Le date passate sono fittizie per i seo, perchè i report sono indipendenti dalle date
  const runReport = async (
    startDate = '2021-01-01',
    endDate = '2021-01-02'
  ) => {
    setRunning(true)

    api.get(
      prefix +
        `run-report/${reportId}?start_date=${startDate}&end_date=${endDate}`,
      { credentials: 'include' }
    )
      .then(loginRedirect)
      .then((data) => {
        if (data === "Report running")
          notify(
            "La run del report è partita correttamente",
            "success"
          );
        else if (data === 'Report already running')
          notify('Il report è già stato avviato da qualcun altro.', 'error')
        else
          notify(
            'Il report è andato in errore, contattare un innovation.',
            'error'
          )
        setRunning(false)
      })
      .catch(console.log)
  }

  // Funzione che contatta il backend per salvare la configurazione aggiornata
  const handleSubmit = (e) => {
    e.preventDefault()

    let erroreNome = null;
    if (nome === '') erroreNome = 'Non puoi inserire un nome vuoto'
    if (nome.length > 1024) erroreNome = 'Non puoi inserire un nome con più di 1024 caratteri'
    if (/^[ a-zA-Z_0-9]+$/gm.test(nome) === false)
      erroreNome = 'Puoi inserire un nome composto solo di lettere (maiuscole o minuscole), numeri e trattini bassi'
    if (erroreNome) {
      notify(erroreNome, 'warning')
      setNomeError(true)
      return
    }

    setLoading(true)
    let formData = new FormData()
    formData.append('nome', nome)
    formData.append('gruppi', JSON.stringify(gruppi))
    formData.append('client', client)
    formData.append('language_code', language)
    formData.append('language', lingua)
    formData.append('file', file)
    formData.append('fileMode', fileMode)
    formData.append('keyword', kw)
    formData.append('volume', vol)

    api.put(prefix + `reports/${reportId}/`, formData)
      .then(loginRedirect)
      .then((data) => {
        if (typeof data === 'object') {
          notify('Configurazione modificata con successo', 'success')
          updateReport(data)
          window.location.reload()
        } else if (data === 'Nome già presente') {
          notify('Esiste già un report con questo nome', 'warning')
          setNomeError(true)
        } else if (data === 'Errore gruppo') {
          notify(
            'Stai tentando di rimuovere un gruppo, ma hai delle dimensioni linkate a tale gruppo. Rimuovi le dimensioni o elimina il link per continuare',
            'warning'
          )
          setGruppi(reportGroups)
        } else {
          notify(
            'Qualcosa è andato storto, ricontrollare o contattare un innovation',
            'error'
          )
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
        setLoading(false)
      })
  }

  return (
    <div className="Content-box">
      <div className="d-flex justify-between pb-3">
        <h2 className="mr-auto">Configurazione</h2>
        <CloneReport id={reportId}/>
        {running ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <Button color="success" onClick={() => runReport()}>
            Run report
          </Button>
        )}
      </div>
      <Row>
        <Col xs={12} className={'m-auto pb-3 text-left'}>
          <p>
            Da qui puoi modificare la configurazione indicata al momento della
            creazione.
            <br />
            Ricorda che in caso di modifica del file, è necessario mantenere
            coerente il naming delle colonne. Puoi far girare il report
            utilizzando il pulsante in alto a destra
          </p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} className="SeoConfigurationForm">
        <SettingsBox
          nome={nome}
          setNome={setNome}
          nomeError={nomeError}
          setNomeError={setNomeError}
          gruppi={gruppi}
          gruppiOptions={groupOptions}
          setGruppi={setGruppi}
          lingua={lingua}
          setLingua={setLingua}
          ratio={ratio}
        />
        <InputBox
          file={file}
          fileMode={fileMode}
          setFile={setFile}
          setFileMode={setFileMode}
          editFile={editFile}
          setEditFile={setEditFile}
          reportFile={reportFile}
          client={client}
          setClient={setClient}
          language={language}
          setLanguage={setLanguage}
          keyword={kw}
          setKeyword={setKw}
          volume={vol}
          setVolume={setVol}
          ratio={ratio}
        />
        <OutputBox options={reportOutput} seo={seo} ratio={ratio}/>
        <div className="text-center">
          {loading ? (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <Button color="success">
              Salva modifiche
            </Button>
          )}
        </div>
      </Form>
    </div>
  )
}

export default SeoConfiguration
