import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function Mobile({ context }) {
  const { mobile, setMobile } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Mobile
      </label>
      <div style={{marginLeft:'+1.5em'}}>
        <Input
          className="form-control-alternative"
          type="checkbox"
          id="mobile"
          checked={mobile}
          style={{width: 'auto'}}
          onChange={e => setMobile(e.target.checked)}
        />
      <FormText color="muted" align={'left'}>
        Spunta la casella per richiedere i risultati di navigazione mobile
      </FormText>
      </div>
    </FormGroup>
  )
}
