import React, { useState, useContext, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGlobe, faTimes} from '@fortawesome/fontawesome-free-solid'
import _ from 'lodash'

// Selettore per decidere quali lingue visualizzare (quali colonne far apparire). Per adesso serve solo inglese ma chissà in futuro
export function LanguageSelector({ context, showSelector, setShowSelector }) {
  const { selectedLanguages, setSelectedLanguages, languageOptions } =
    useContext(context)

  const handleClick = (e) => {
    // Se l'ho checkata, la aggiungo
    if (e.target.checked) {
      let languages = JSON.parse(JSON.stringify(selectedLanguages))
      languages.push(e.target.value)
      languages = languages.sort()
      setSelectedLanguages(languages)
      return
    }
    // Almeno una ci deve essere o si spacca la tabella (se poi non la voglion vedere, chiudono col selettore)
    if (selectedLanguages.length == 1) return
    // Se non l'ho checkata, la tolgo
    const index = selectedLanguages.indexOf(e.target.value)
    let languages = JSON.parse(JSON.stringify(selectedLanguages))
    languages.splice(index, 1)
    languages = languages.sort()
    setSelectedLanguages(languages)
  }

  return (
    <div
      className="overlay"
      style={{ display: showSelector ? 'block' : 'none' }}
    >
      <div className="tiny-modal">
        <button
          type="button"
          className="tiny-modal__close"
          onClick={() => setShowSelector(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <form className={'dimension-selector'}>
          {languageOptions.map((lang, i) => (
            <label className={'dimension-selector-label'}>
              <input
                type="checkbox"
                name={'lan' + i}
                onClick={handleClick}
                checked={selectedLanguages.includes(lang)}
                value={lang}
              />{' '}
              {lang}
            </label>
          ))}
        </form>
      </div>
    </div>
  )
}

// Componente della tabella di un DetailReport, con il nome "Lingue" e un piccolo tastino per aprire il selettore di quali lingue mostrare
// (appare nella prima riga di una tabella di una dimensione)
export function LanguageName({ context }) {
  const { showLanguages, selectedLanguages } = useContext(context)

  return (
    <>
      {showLanguages && (
        <div style={{ width: '200px' }}>
          <h5>
            TAG EN{' '}
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              icon={faGlobe}
            />
          </h5>
        </div>
      )}
    </>
  )
}
