/*eslint-disable*/
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import React, { Component } from 'react'
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Collapse,
	UncontrolledDropdown,
	FormGroup,
	Form,
	Input,
	InputGroupText,
	InputGroup,
	Media,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Progress,
	Table,
	Container,
	Row,
	Col,
} from "reactstrap";
import { Height } from "@material-ui/icons";


var ps;

const Sidebar = (props) => {
	const [collapseOpen, setCollapseOpen] = useState();
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen(!dropdownOpen);

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName) => {
		return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
	};
	// toggles collapse between opened and closed (true/false)
	const toggleCollapse = () => {
		setCollapseOpen((data) => !data);
	};
	// closes the collapse
	const closeCollapse = () => {
		setCollapseOpen(false);
	};
	// creates the links that appear in the left menu / Sidebar
	const createLinks = (routes) => {
		return routes.map((prop, key) => {
			return (!prop.private || userGroup && userGroup == 'Webranking') ? (
				<div key={key}><NavItem>
					<NavLink
						{...(prop.path.includes('http://') || prop.path.includes('https://'))
						? {to: {pathname: prop.path}, target: "_blank"}
						: {to: prop.layout + prop.path}
						}
						tag={NavLinkRRD}
						onClick={closeCollapse}
						activeClassName="active"
					>
						<i className={prop.icon} />
						{prop.name}
					</NavLink>
				</NavItem>
				{(prop.name == 'Segnalazione') ? (
					<hr className="my-3" />
				) : null}
				</div>
			) : ("")
		});
	};

	const createLinks_reporting = (routes) => {
		return routes.map((prop, key) => {
			return (
				<NavItem key={key} style={{marginLeft: "40px", marginBottom: "-15px"}}>
					{prop.name == 'Guida' ? (
					<NavLink
						href={prop.path}
						onClick={closeCollapse}
						activeClassName="active"
					>
						{prop.name}
					</NavLink>
					) : (
					<NavLink
					to={prop.layout + prop.path}
					tag={NavLinkRRD}
					onClick={closeCollapse}
					activeClassName="active"
					>
						{prop.name}
					</NavLink>
						)
				}
				</NavItem>
			);
		});
	};

	const { bgColor, routes, logo, onLogout, userGroup} = props;
	const reporting_routes = routes.filter(route => {
		return route.path === 'reporting';
		})[0].childrens

	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: "_blank"
		};
	}

	return (
		<Navbar
			className="navbar-vertical fixed-left navbar-light bg-white"
			expand="md"
			id="sidenav-main"
		>
			{/* Toggler */}
			<button
				className="navbar-toggler"
				type="button"
				onClick={toggleCollapse}
			>
				<span className="navbar-toggler-icon" />
			</button>
			{/* Brand */}
			{logo ? (
				<NavbarBrand className="pt-4" {...navbarBrandProps}>
					<img
						width="80%"
						height="80%"
						alt={logo.imgAlt}
						className="navbar-brand-img"
						src={logo.imgSrc}
					/>
				</NavbarBrand>
			) : null}
			{/* Collapse */}
			<Collapse navbar isOpen={collapseOpen}>
				{/* Collapse header */}
				<div className="navbar-collapse-header d-md-none">
					<Row>
						{logo ? (
							<Col className="collapse-brand" xs="6">
								{logo.innerLink ? (
									<Link to={logo.innerLink}>
										<img alt={logo.imgAlt} src={logo.imgSrc} />
									</Link>
								) : (
									<a href={logo.outterLink}>
										<img alt={logo.imgAlt} src={logo.imgSrc} />
									</a>
								)}
							</Col>
						) : null}
						<Col className="collapse-close" xs="6">
							<button
								className="navbar-toggler"
								type="button"
								onClick={toggleCollapse}
							>
								<span />
								<span />
							</button>
						</Col>
					</Row>
				</div>
				{/* Form */}
				<Form className="mt-4 mb-3 d-md-none">
					<InputGroup className="input-group-rounded input-group-merge">
						<Input
							aria-label="Search"
							className="form-control-rounded form-control-prepended"
							placeholder="Search"
							type="search"
						/>
					</InputGroup>
				</Form>
				{/* Navigation */}
				<Nav navbar>{createLinks(routes)}</Nav>
				{window.location.pathname.includes('/reporting') ? (
					<Nav navbar>{createLinks_reporting(reporting_routes)}</Nav>
				) : ('')
				}
				<Nav className="mb-md-3" navbar>
					<NavItem className="logout-button">
						<Button onClick={onLogout} outline color="info">Logout</Button>
					</NavItem>
				</Nav>
			</Collapse>
		</Navbar>
	);
};

Sidebar.defaultProps = {
	routes: [{}]
};

Sidebar.propTypes = {
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo
		imgSrc: PropTypes.string.isRequired,
		// the alt for the img
		imgAlt: PropTypes.string.isRequired
	})
};

export default Sidebar;
