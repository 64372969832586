import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const FirstRun = ({ context }) => {
  const { firstRun, setFirstRun } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="first-run" className="form-control-label">
        First Run:
      </label>
      <DatePicker
        className="form-control-alternative"
        required={true}
        selected={!!firstRun ? new Date(firstRun) : firstRun}
        onChange={(date) => setFirstRun(date)}
        showTimeSelect
        placeholderText='Inserisci una data'
        dateFormat="yyyy-MM-dd hh:mm aa"
        filterDate={(date) => {return date >= new Date();}}
        filterTime={(date) => {return date >= new Date();}}
      />
    </FormGroup>
  )
}

export default FirstRun
