import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { BASE_URL } from '../utils'
import SetTitle from '../utils/set_title'

export default function BigQueryExporterFile() {
	SetTitle('BigQuery Exporter')
	const params = useParams()

	useEffect(() => {
    	downloadData()
  	}, [])

	const downloadData = () => {
		if (params?.id) {
			setTimeout(() => {
				window.location.href = BASE_URL+'/api/1/get-bigquery-exporter/'+params.id
				setTimeout(() => {
					window.location.href = '/bigquery-exporter'
				}, 5000)
			}, 1000)
		}
		else {
			setTimeout(() => {
				window.location.href = '/bigquery-exporter'
			}, 1000)
		}
	}

	return (
		<>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="ni ni-folder-17 text-white" style={{ color: "white", fontSize: "35px" }}></i>
					<h1 className="page-title">BigQuery Exporter</h1>
				</div>
			</div>
			<div className="page-description">Download in corso...</div>
		</>
	)
}
