import React, {useEffect, useState} from 'react'
import PageTitle from '../../../components/PageTitle'
import {Button, Container} from 'react-bootstrap'
import Loading from '../../../components/Loading'
import {getDimensionGroupsDetail, getLanguageGroupInfo} from "../../../api_methods";
import GroupRow from "./GroupRow";
import AddSubGroup from "../AddSubGroup";
import DimensionsInfo from "./DimensionsInfo";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import EmptyTranslations from "../../TranslationUtility/EmptyTranslations";
import UserHeader from "../../../../components/Headers/ReportingHeader";

export default function DimensionGroupInfo(props) {
  const groupName = props.match.params.name
  const [emptyRules, setEmptyRules] = useState([])
  const [groupInfo, setGroupInfo] = useState([])
  const [loaded, setLoaded] = useState(false)

  const getEmptyRules = () => {
    setLoaded(false)
    getLanguageGroupInfo(groupName).then((data) => {setEmptyRules(data); setLoaded(true)})
  }

  const getGroup = () => {
    getDimensionGroupsDetail(groupName).then((data) => {setGroupInfo(data)})
  }

  useEffect(() => {
    getGroup()
    getEmptyRules()
  }, [])

  return (
    <div style={{marginBottom: "100px"}}>
      <UserHeader/>
      <Card className="shadow bg-secondary ml-5 mr-5">
        <CardHeader className="bg-white">
          <PageTitle
            title={groupName + ' - Status gruppo'}
          />
        </CardHeader>
        <CardBody className="ml-5 mr-5">
          <Row>
            <Col xs={6}> <AddSubGroup name={groupName}/> </Col>
            {/* Blocco per la gestione delle dimensioni del gruppo */}
            <Col xs={6}> {groupInfo.dimensions && <DimensionsInfo groupInfo={groupInfo} refresh={getGroup}/>} </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={6}>
              {groupInfo.dimensions && <EmptyTranslations identifier={groupName} info={groupInfo}/>}
            </Col>
          </Row>
          {/*groupInfo.sub_groups && <DeleteSubGroup subgroups={groupInfo.sub_groups} refresh={getGroup}/>*/}
          {loaded ? <Card className="mt-4 mb-3">
            {/* Blocco per valutare e/o correggere lo stato delle regole del gruppo */}
            <h2 className="pb-2 ml-2 mt-2">Stato sincronizzazione regole</h2>
            <div className="pb-4 ml-2 mt-2">
              {emptyRules.length > 0 ?
                <>
                  Lista delle regole vuote in tutti i linguaggi collegati,
                  ovvero senza alcuna condizione nei campi "Contiene" o "Non Contiene".
                </> :
                <h6 className="center-block">Tutte le regole sono compilate <span role="img" aria-label="Correct">✅</span></h6>}
              <br/>
            </div>

            {emptyRules.map((g, i) => (
              <GroupRow group={g} key={g.name}/>
            ))}
            <Button onClick={() => getEmptyRules()}
                    title="Refresh">Refresh <span role="img" aria-label="refresh">🔃</span>
            </Button>
          </Card> : <Loading child={null}></Loading>}
        </CardBody>
      </Card>
    </div>
  )

}
