import React, { useContext, } from 'react'
import { FormText, FormGroup, Input} from 'reactstrap'

export default function LocationName({ context }) {
  const { term, setTerm } = useContext(context)
  const { locationName, setLocationName, filterLocationNames, locationFiltered } =
  useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Location name:
      </label>
      <Input
        className="form-control-alternative"
        type="text"
        id="term"
        value={term}
        placeholder="Inserisci nome"
        onChange={(e) => {
          setTerm(e.target.value)
          filterLocationNames(e.target.value)
        }}
        onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
      />
      <FormText color="muted" align={'left'}>
        Inserisci una <b>location </b> poi seleziona quella desiderata dalla lista sotto
      </FormText>
      <Input
        className="form-control-alternative"
        type="select"
        id="location_name"
        value={locationName=== "" ? '' : locationName}
        onChange={(e) => setLocationName(e.target.value)}
      >
        <option>{locationName}</option>
        {locationFiltered?.map((location, idx) => (
          <option value={location} key={`${idx}-${location}`}>
            {location}
          </option>
        ))}
      </Input>
    </FormGroup>
  )
}
