import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input} from 'reactstrap'

export default function MultiplePositioning({ context }) {
  const { multiplePositioning, setMultiplePositioning } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Multiple Positioning
      </label>
      <div style={{marginLeft:'+1.5em'}}>
        <Input
          className="form-control-alternative"
          type="checkbox"
          id="multiplePositioning"
          checked={multiplePositioning}
          style={{width: 'auto'}}
          onChange={e => setMultiplePositioning(e.target.checked)}
        />
      <FormText color="muted" align={'left'}>
        Spunta questa casella per ottenere il posizionamento della keyword per
        ognuno dei domini indicati.{' '}
      </FormText>
      </div>
    </FormGroup>
  )
}
