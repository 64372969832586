import React, { useContext } from 'react'
import { FormText, FormGroup, Row} from 'reactstrap'
import { useParams, useLocation } from 'react-router-dom'
import _ from "lodash";

export default function File({ context }) {
  const { file, setFile, fileName, setFileName} = useContext(context)
  // nome del file attualmente sul server, non deve cambiare se si carica un nuovo file
  const oldFileName = _.cloneDeep(fileName)

  let { id: studioKwID } = useParams()
  let location = useLocation()

  const handleUploadFile = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    validity.valid ? setFile(file) : setFile(null)
  }

  return (
    <FormGroup>
      <label htmlFor="file" className="form-control-label">
        File
      </label>
      {(
        <>
          <input
            className="form-control-alternative"
            id={'file'}
            type={'file'}
            required={!(file || fileName)}
            filename={fileName ?? null}
            style={{ border: '0' }}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={event => {
              setFile(event.target.files[0])
              setFileName(event.target.files[0].name)
            }}
          />
          <Row style={{marginLeft:"0.5em"}}>
          <FormText color="muted" align={'left'}>
            <b>Carica un file</b> con le keywords ed eventualmente volumi <b>nel foglio RAW</b>
          </FormText>
          </Row>
          {oldFileName && <Row style={{marginLeft:"0.5em"}}>
          <FormText color="muted" align={'left'}>
            <b>File attuale</b>: {oldFileName}
          </FormText>
          </Row>}
        </>
      )}
    </FormGroup>
  )
}
