import React from 'react'
import SiteUrlManageForm from './SiteUrlManageForm'
import SetTitle from '../../utils/set_title'

export default function SiteUrlManage() {
	SetTitle('SEO Site Manager')
	return (
    <>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="ni ni-compass-04 text-white" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">SEO Site Manager</h1>
				</div>
			</div>
			<div className="page-description">
				Il <b>Domain URL</b> può essere nella forma https://example.com o sc-domain:example.com<br/>
				Il <b>secret name</b> è necessario per la raccolta di nuovi url ogni mese, e deve essere una credenziale utile a connettersi a questo specifico dominio su Google Search Console.<br/>
			</div>
			<SiteUrlManageForm />
    </>
	)
}