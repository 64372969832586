import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {api} from '../utils/index'
import {BASE_URL} from '../utils/index'
import { ToastContainer, toast } from 'react-toastify'
import {notify} from '../utils/notify'
import Loading from '../tagging_tool/components/Loading'
import {Spinner} from 'react-bootstrap'
import {
	Col,
	FormGroup,
	Form,
	FormText,
	CardBody,
	Row,
	Button,
	Card,
	CardHeader,
	Input,
	Container
} from "reactstrap";

const validateUrls = urls => {
    const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;    
    return regex.test(urls);
  }

const LazyLoad = () => {

    const [url, setUrl] = useState([])
    const [fetched, setFetch] = useState(true)


    const handleSubmit = e => {
        e.preventDefault()
        if(url=='' || !validateUrls(url)){
            notify(
                'Url mancante o invalido',
                'error'
              )
              return
        }
        
        setFetch(false)
        const querystring = new URLSearchParams({url}).toString();
        console.log(BASE_URL + '/api/1/lazy-load/?' + querystring)
        api
            .get(BASE_URL + '/api/1/lazy-load/?' + querystring)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'output.html'); //or any other extension
                document.body.appendChild(link);
                link.click();
                setFetch(true)
                notify(
                    "Request sent.",
                    'success'
                  )
            })
            .catch(err => {
                console.error(err)
                notify(
                    err,
                    'error'
                )
                setFetch(true)
            })
    }

    return (
        <div id="LazyLoad">
        <div className="col pb-5">
            <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                        <Row style={{ marginLeft: "0px" }}>
                            <Col md={10}>
                                <h1 className="mb-0">Lazy Load</h1>
                            </Col>
                        </Row>
                    </CardHeader>
                <CardBody>
            {fetched ? (
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup >
                                    <label htmlFor="url" style={{display: 'block'}}>
                                        URL
                                    </label>
                                    <Input
                                        placeholder="Enter url"
                                        id="url"
                                        onChange={event => {
                                            setUrl(event.target.value)
                                        }}
                                        required="required"
                                        onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
                                    />
                                    <FormText color="muted" align={'left'}>
                                        Inserire l'url su cui effettuare il controllo,<b> compreso di "https://" </b>
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row style={{marginBottom: '3%', marginTop: '3%'}}>
                            <Col xs={12} md={2}>
							    <Button color="info" size="lg" onClick={handleSubmit} >Submit</Button>
                            </Col>
                        </Row>
                    </Form>)
                : (
                    <Loading/>
                )}
        	</CardBody>
            </Card>
            </div>
            <ToastContainer />
        </div>
    )
}

export default LazyLoad