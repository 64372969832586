import React from 'react'
import { Link } from 'react-router-dom'
import {Accordion, Card, Col, Row} from 'react-bootstrap'
import DimensionGroup from "./DimensionGroup";
import {DimensionRow} from "./SavedDimensionRow";
import AddSubGroup from "./AddSubGroup";
import EmptyTranslations from "../TranslationUtility/EmptyTranslations";

export default function GroupCardBody({group, dimensions, reloadListing}) {
  return (
    <Card.Body>
      <Accordion defaultActiveKey="0">
        <h5 style={{ display: 'none' }}>
          group.name
        </h5>
        <Row>
          <Col xs={12} className="mt-3">
            {group.is_super ? <Link
              to={{
                pathname: `/reporting/dimension-groups/info/${group.name}`,
              }}
            >
              <h3>⚙ Info</h3>
            </Link> : !group.is_sub && <AddSubGroup name={group.name}/>}
            {/*{!group.is_sub && !group.is_super && <EmptyTranslations*/}
            {/*  identifier={group.name}*/}
            {/*  info={{dimensions: dimensions.filter((x) => x.group.name === group.name), ...group}}*/}
            {/*  group={true}*/}
            {/*/>}*/}
          </Col>
        </Row>
        {group.sub_groups.length > 0 ? (<Row className={'mt-3'}>
          <Col xs={12} className={'text-left'}>
            <h3>Sotto gruppi</h3>
            <div className='listing'>
              {group.sub_groups.map((g) => (
                <DimensionGroup key={g.name} group={g} savedDimensions={dimensions.filter((x) => x.group.name === g.name)}/>
              ))}
            </div>
          </Col>
        </Row>) : ''}
        <Row className={'mt-3'}>
          <Col xs={12} className={'text-left'}>
            <h3>Dimensioni</h3>
            {<ul style={{ listStyleType: 'none', padding: 0 }}>
              {dimensions.filter((x) => x.group.name === group.name).map((dimension, index) => (
                <>
                  <DimensionRow
                    dim={dimension}
                    savedDimensions={dimensions}
                    reloadGroupRow={reloadListing}
                    is_sub={group.is_sub}
                    key={dimension.name}
                  />
                </>
              ))}
            </ul>}
          </Col>
        </Row>
      </Accordion>
    </Card.Body>
  )
}
