import React, {useState} from "react";
import {deleteSavedDimension} from "../../api_methods";
import {notify} from "../../../utils/index_tagging_tool";
import {Col, Row} from "react-bootstrap";
import ExpandButton from "../../components/ExpandButton";
import {DimensionName} from "./SavedDimensionName";

// Componente che rappresenta le singole dimensioni all'interno del gruppo
export function DimensionRow({ dim, k, savedDimensions, reloadGroupRow, is_sub=false }) {
  /**
   * Contatta il backend per rimuovere la dimensione, con tutte le sue regole
   */
  const handleRemoveDim = (e) => {
    e.preventDefault()

    const answer = window.confirm(
      'Sei sicuro? La dimensione e tutte le sue regole verranno rimosse definitivamente'
    )
    if (!answer) return

    let formData = new FormData()
    // Serve sia nome che gruppo, possono esserci più saved dimensions con lo stesso nome se in gruppi diversi
    formData.append('displayName', dim.display_name)
    formData.append('group', dim.group.name)

    deleteSavedDimension(formData)
      .then((data) => {
        if (typeof data !== 'number') {
          notify(
            'Qualcosa è andato storto, ricontrollare o contattare un innovation',
            'error'
          )
        } else {
          notify('Dimensione rimossa con successo', 'success')
          // Devo ricaricare la lista, per rendere il tutto coerente
          savedDimensions.splice(savedDimensions.indexOf(dim), 1)
          reloadGroupRow()
        }
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
      })
  }

  const padding = 1;
  const dimensionInfoSpace = 12 - padding * 2;
  const dimNameSpace = 6
  const deleteButtonSpace = dimensionInfoSpace - dimNameSpace
  const canDelete = !is_sub;

  return (
    <>
      <Row>
        <Col xs={padding} />
        <Col xs={dimensionInfoSpace}>
          <hr />
        </Col>
        <Col xs={padding} />
      </Row>
      <Row>
        <Col xs={padding} />
        <Col xs={dimNameSpace} className={'text-left'}>
          <DimensionName dimension={dim} is_sub={is_sub}/>
        </Col>
        <Col xs={deleteButtonSpace} className={'text-right'}>
          {canDelete ?
            <strong style={{ cursor: 'pointer' }} onClick={handleRemoveDim}>
              <span role="img" aria-label="Elimina la dimensione">❌</span>
            </strong>
            : ''}
        </Col>
        <Col xs={padding} />
      </Row>
    </>
  )
}