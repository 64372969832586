import {Spinner} from 'react-bootstrap'
import TagsInput from '../../components/TagsInput'
import CharCheckModal from "../../components/molecules/CharCheckModal";
import { Formik } from 'formik'
import React, { useEffect, useState, useContext} from 'react'
import * as Yup from 'yup'
import { api } from '../../utils/index'
import { ToastContainer, toast } from 'react-toastify'
import { notify } from '../../utils/notify'
import {
	Col,
	FormGroup,
	Form,
	FormText,
	CardBody,
	Row,
	Button,
	Card,
	CardHeader,
	Input,
	Container
} from "reactstrap";

const HistoricalReportForm = () => {
  const [domains, setDomains] = useState([])
	const [emails, setEmails] = useState([])
	const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  // Modal check caratteri
  const [showCharCheck, setShowCharCheck] = useState(false)
  const [errCharList, setErrCharList] = useState([])
  const [acceptedCharCheck, setAcceptedCharCheck] = useState(false)
  const [formValues, setFormValues] = useState(null)

/*   useEffect(() => {
    api
      .get('motori-ricerca/')
      .then(res => {
        this.setState({
          options: res.data
        })
      })
      .catch(err => console.error(err))
  }, []) */

  const selectedDomains = domains => {
    setDomains(domains)
  }

  const selectedEmails = emails => {
    setEmails(emails)
  }

  const handleSubmit = values => {
    
    if (
      values.endDate > values.startDate &&
      values.endDate < new Date().toISOString()
    ) {
      let formData = new FormData()
      formData.append('file', values.file)
      formData.append('domains', domains)
      formData.append('engine', values.engine)
      formData.append('emails', emails)
      formData.append('mobile', values.mobile)
      formData.append('volumes', values.volumes)
      formData.append('startDate', values.startDate)
      formData.append('endDate', values.endDate)
      acceptedCharCheck && formData.append('accepted', acceptedCharCheck)

      let missingFields = []
      for (let key of formData.entries()) {
        console.log(key);
        if(key[1]=='' || key[1]=='null' || key[1] == null || key[1]==undefined){
          missingFields.push(key[0])
        }
      }
      if(missingFields.length > 0){
        //altrimenti la stringa sfora le dimensioni del box
        let string = 'Campi mancanti: \n'+ missingFields.toString().replaceAll(',',',\n')
        notify(
          string,
          'error'
        )
        return
      } 
      
      setLoading(true)
      setFormValues(values)
      api
        .post('export-keyword/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(response => {
          setLoading(false)
          notify(
            "Request sent.",
            'success'
          )
        })
        .catch(err => {
          if (err?.response?.data) {
            if (err.response.data?.message.includes('ERROR Encoding keywords')) {
              setErrCharList(err.response.data?.errors);
              setShowCharCheck(true);
            }
            else {
              notify(err.response.data.message, 'error')
              setLoading(false)
            }
          }        
        })
    } else {
      if (values.endDate <= values.startDate)
      notify(
        'La data di fine deve essere maggiore di quella di inizio.',
        'error'
        )
      else notify(
        'La data di fine non può essere nel futuro.',
        'error'
        )
    }
  }

  const confirmEvaluation = (e) => {
    e.preventDefault()
    setShowCharCheck(false)
    setAcceptedCharCheck(true)
  }

  useEffect(() => {
    if(acceptedCharCheck && formValues) {
      handleSubmit(formValues)
    }
  }, [acceptedCharCheck])

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const defaultStartDate = () => {
    let date = new Date()
    date.setMonth(date.getMonth() - 1)
    return date.toISOString().substring(0, 10)
  }

    const filterSchema = Yup.object().shape({
      startDate: Yup.date().min('01-01-2018'),
      endDate: Yup.date(),
      mobile: Yup.boolean(),
      volumes: Yup.boolean()
    })
    return (
      <>
        <CharCheckModal show={showCharCheck} onHide={setShowCharCheck} onSubmit={confirmEvaluation} errCharList={errCharList}/>
        {loading ? (
          <Spinner animation="grow" />
        ) : (
          <Formik
            initialValues={{
              engine: '',
              startDate: defaultStartDate(),
              endDate: new Date().toISOString().substring(0, 10),
              email: [],
              mobile: false,
              volumes: false,
              domain: [],
              file: null
            }}
            onSubmit={handleSubmit}
            validationSchema={filterSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              defaultValue
            }) => (
            <div className="col pb-5">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
              <Row style={{ marginLeft: "0px" }}>
                <Col md={10}>
                  <h1 className="mb-0">Historical Retrieve</h1>
                </Col>
              </Row>
              </CardHeader>
                <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <label htmlFor="engine" style={{ display: 'block' }}>
                        Motore Ricerca
                      </label>
                      <Input
                      	className="form-control-alternative"
                        id="engine"
                        value={values.engine}
                        style={{ height: '44px' }}
                        onChange={event => {
                          handleChange(event)
                          setFieldValue('engine', event.target.value)
                        }}
                        onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
                      >
                        <option value={''} disabled />
                        {options.map((opt, index) => {
                          return (
                            <option key={index} value={opt.id}>
                              {opt.nome}
                            </option>
                          )
                        })}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="report" className="form-control-label">
                        Email
                      </label>
                        <TagsInput
                        className="form-control-alternative"
                        id="email"
                        selectedTags={setEmails}
                        validator={validateEmail}
                        tags={emails}
                        type="text"
                      />
                      <FormText color="muted" align={'left'}>
                        <b>Premere invio</b> dopo ogni nuovo inserimento
                      </FormText>
                    </FormGroup>
                    <FormGroup style={{marginLeft: '1.2em'}}>
                      <label
                        htmlFor="mobile"
                        style={{ textTransform: 'none', fontWeight: '1' }}
                      >
                        <Input
                        	className="form-control-alternative"
                          id={'mobile'}
                          type={'checkbox'}
                          style={{
                            width: 'auto',
                            display: 'inline',
                            height: 'auto',
                          }}
                          checked={values.mobile}
                          onChange={event => {
                            handleChange(event)
                            setFieldValue('mobile', event.target.checked)
                          }}
                          onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
                        />{' '}
                        Mobile
                      </label>
                      <FormText color="muted" style={{marginLeft:'-1.5em'}} align={'left'}>
                        Spunta questa casella per richiedere risultati mobile
                        invece che desktop.
                      </FormText>
                    </FormGroup>
                    <p />
                    <FormGroup style={{marginLeft: '1.2em'}}>
                      <label
                        htmlFor="volumes"
                        style={{ textTransform: 'none', fontWeight: '1' }}
                      >
                        <Input
                        	className="form-control-alternative"
                          id={'volumes'}
                          type={'checkbox'}
                          style={{
                            width: 'auto',
                            display: 'inline',
                            height: 'auto',
                          }}
                          checked={values.volumes}
                          onChange={event => {
                            handleChange(event)
                            setFieldValue('volumes', event.target.checked)
                          }}
                        />{' '}
                        Volumi
                      </label>
                      <FormText color="muted" style={{marginLeft:'-1.5em'}} align={'left'}>
                        Spunta questa casella per scaricare i volumi invece di
                        usare quelli nel file excel.
                      </FormText>
                    </FormGroup>
                    <p />
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label htmlFor="domain" className="form-control-label">
                        Domini
                      </label>
                        <TagsInput
                        className="form-control-alternative"
                        id={'domain'}
                        selectedTags={selectedDomains}
                        tags={domains}
                        type="text"
                      />
                      <FormText color="muted" align={'left'}>
                        Inserisci i domini di cui avere il risultato. Lascia
                        vuoto per usarli tutti.
                        <br />
                        Premi invio dopo ogni nuovo inserimento.
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="startDate" style={{ display: 'block' }}>
                        Data inizio
                      </label>
                      <Input
                        id={'startDate'}
                        type={'date'}
                        value={values.startDate}
                        onChange={event => {
                          handleChange(event)
                          setFieldValue('startDate', event.target.value)
                        }}
                        className={
                          errors.startDate && touched.startDate
                            ? 'text-input error'
                            : 'text-input'
                        }
                      />
                      {errors.startDate && touched.startDate && (
                        <div className="input-feedback">{errors.startDate}</div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="endDate" style={{ display: 'block' }}>
                        Data fine
                      </label>
                      <Input
                      
                        id={'endDate'}
                        type={'date'}
                        value={values.endDate}
                        onChange={event => {
                          handleChange(event)
                          setFieldValue('endDate', event.target.value)
                        }}
                        className={
                          errors.endDate && touched.endDate
                            ? 'text-input error'
                            : 'text-input'
                        }
                      />
                      {errors.endDate && touched.endDate && (
                        <div className="input-feedback">{errors.endDate}</div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="file" style={{ display: 'block' }}>
                        File
                      </label>
                      <Input
                      	className="form-control-alternative"
                        id={'file'}
                        type={'file'}
                        style={{ border: '0' }}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={event => {
                          handleChange(event)
                          setFieldValue('file', event.target.files[0])
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{display: "flex", justifyContent: "flex-end", marginRight:"50px"}}>
                  <Button color="info" size="lg" onClick={handleSubmit} >Submit Report</Button>
                </Row>              
              </Form>
          </CardBody>
						</Card>
					</div>
            )}
        </Formik>
        )}
        <ToastContainer />
      </>
    )
  
}

export default HistoricalReportForm
