import React, { Fragment } from 'react'
import {Row, Col, Form, Input} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/fontawesome-free-solid'

// Componente per la modifica del campo input_options del report
export default function InputBox({
                                   keyword,
                                   setKeyword,
                                   volume,
                                   setVolume,
                                   client,
                                   setClient,
                                   language,
                                   setLanguage,
                                   file,
                                   fileMode,
                                   setFile,
                                   setFileMode,
                                   editFile,
                                   setEditFile,
                                   reportFile,
                                   ratio
                                 }) {
  return (
    <Fragment>
      <h3>Input</h3>
      <hr />
      <Row className="mb-4">
        <Col xs={ratio.title}>
          <label className={'vertical'}>File</label>
        </Col>
        <Col xs={ratio.form}>
          {
            // Per editare il file è necessario cliccare sull'icona specifica
            editFile ? (
              <>
                <Row className="justify-content-center">
                  <Input
                    id={'file'}
                    type={'file'}
                    className="form-control-alternative"
                    style={{ border: '0', width: '60%' }}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) =>
                      e.target.files ? setFile(e.target.files[0]) : setFile(null)
                    }
                  />
                </Row>
                <Row className="mt-2 justify-content-center">
                  <Input
                    type="select"
                    className="form-control-alternative"
                    style={{ display: 'inline', width: '40%' }}
                    defaultValue={fileMode}
                    onChange={(e) => setFileMode(e.target.value)}
                  >
                    <option value={'w'}>Sovrascrivi</option>
                    <option value={'a'}>Integra</option>
                  </Input>
                </Row>
              </>
            ) : (
              <p className={'text-left'}>
                {reportFile.split('/').pop()}{' '}
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => setEditFile(true)}
                />
              </p>
            )
          }
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={ratio.title}>
          <label className={'vertical'}>Colonna keywords</label>
        </Col>
        <Col xs={ratio.form}>
          <Input
            type="text"
            id="keyword"
            value={keyword}
            placeholder="Inserisci nome"
            onChange={(e) => setKeyword(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={ratio.title}>
          <label className={'vertical'}>Colonna volume</label>
        </Col>
        <Col xs={ratio.form}>
          <Input
            type="text"
            id="volume"
            value={volume}
            placeholder="Inserisci nome"
            onChange={(e) => setVolume(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={ratio.title}>
          <label className={'vertical'}>Cliente</label>
        </Col>
        <Col xs={ratio.form}>
          <Input
            type="text"
            id="client"
            value={client}
            placeholder="Inserisci cliente"
            onChange={(e) => setClient(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={ratio.title}>
          <label className={'vertical'}>Codice lingua</label>
        </Col>
        <Col xs={ratio.form}>
          <Input
            type="text"
            id="language_code"
            value={language}
            placeholder="Inserisci codice lingua"
            onChange={(e) => setLanguage(e.target.value)}
          />
        </Col>
      </Row>
    </Fragment>
  )
}
