import React, { useState, useEffect } from 'react'
import {Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, ToastBody, Toast} from 'reactstrap'
import { Input } from '@material-ui/core'
import {
  prefix,
  loginRedirect, notify,
} from '../../../utils/index_tagging_tool'
import {
  api,
} from '../../../utils/index'

import excel_template from '../../resources/test_seo_rules_upload.xlsx'
import { Link } from 'react-router-dom'
import {ModalTitle} from "react-bootstrap";

// Qui sa Gabri
export default function BulkExcelUpload({ dim, loader }) {
  const [file, setFile] = useState()
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const toggle = () => {
    setShow(!show)
    setMessage('')
    setStatus('')
  };

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      &times;
    </button>
  );


  const handleFileInput = (e) => {
    setFile(e.target.files[0])
  }

  const handleUpload = (e) => {
    if (file === undefined) {
      setMessage('Selezionare un file')
      return
    }

    var formData = new FormData()
    formData.append('file', file)

    setLoading(true)
    setMessage('')

    api.post(`${prefix}rule-file-upload/${dim}/`, formData)
      .then((res) => {
        setStatus(res.status)
        return res
      })
      .then(loginRedirect)
      .then((data) => {
        setMessage(data);
        setLoading(false);
      })
      .catch((error) => {
        notify(error.response.data,'error')// works, `e` narrowed to Error
        setMessage(error.response.data);
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(false)
    if (status === 200) {
      window.location.reload()
    }
  }, [status])

  return (
    <>
      <button
        type="button"
        className="btn btn-info mr-2"
        onClick={() => setShow(true)}
        disabled={loader}
      >
        Upload Excel
      </button>
      <Modal
        isOpen={show}
        size="sm"
        centered={true}
      >
        <ModalHeader tag="h3" className="border align-items-center" close={closeBtn}>
            Excel Upload
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Input type="file" onChange={handleFileInput}>
                Carica un file Excel
              </Input>
            </Col>
            {loading && <Col><div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div></Col>}
          </Row>
          <Row className="justify-content-center pt-2">
            <Col>
              <label>{message}</label>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="border justify-content-center">
          <button
            type="button"
            className="btn btn-info mr-2"
            onClick={handleUpload}
          >
            Upload
          </button>
          <Button className="btn mr-2" variant="secondary">
            <Link to={excel_template} target="_blank" download>
              File di esempio
            </Link>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
