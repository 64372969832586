import React, { useState, createContext } from 'react'
import { api } from '../utils/index'

const StudiKwFilterDataContext = createContext([])

const FilterDataProvider = ({ children, path = 'studiokw/' }) => {
  const [filteredData, setFilteredData] = useState({})

	const [actualPage, setActualPage] = useState(1)

  const [queryParams, setQueryParams] = useState({})

  const getFilteredData = (params) => {
    setQueryParams(params)
    api
      .get(path, {
        params: params,
      })
      .then((response) => {
        setFilteredData(response.data)
      })
  }

  return (
    <StudiKwFilterDataContext.Provider
      value={[filteredData, setFilteredData, getFilteredData, queryParams, actualPage, setActualPage]}
    >
      {children}
    </StudiKwFilterDataContext.Provider>
  )
}

export { StudiKwFilterDataContext, FilterDataProvider }
