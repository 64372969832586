import React, {useEffect, useState} from "react";
import {Button, Form} from 'react-bootstrap'
import {updateCustomRule, updateLanguageRule} from "../../api_methods";
import {buildRuleStructure} from "../../../utils/rules";


export function TagRow(
  {
    rule,
    tagWidth= 200,
    badgeWidth= 40,
    editTagIT = true,
    editTagEN = true,
  }) {
  /**
   * Component used to show and edit the IT and EN Tag of the rule passed.
   * The EN Tag won't be directly saved, but it will be set in the JSON field of the rule as a "proposed translation",
   * waiting to be reviewed.
   */
  const [tagIT, setTagIT] = useState(rule.rule.value);
  const [tagEN, setTagEN] = useState(rule.rule?.proposed_translation ? rule.rule.proposed_translation : '');
  const [wrinting, setWriting] = useState(false);


  // Aggiornamento della regola, che viene salvata o creata (il salvataggio/creazione è usato da altre funzioni)
  const updateRule = () => {
    let containsRegex = rule.rule.conditions[0].operand
    let notContainsRegex = rule.rule.conditions[1]?.operand ? rule.rule.conditions[1].operand : '~'
    // formato standard a tutte le regole SEO
    const newRule = buildRuleStructure(rule, tagIT, tagEN, containsRegex, notContainsRegex)

    updateCustomRule(newRule)
  }

  const handleBlur = () => {
    // Creo un piccolo dict per mandare i dati a backend
    const data = {}
    data['proposed_translation'] = tagEN

    // Salva a backend il valore in un campo della json_rule che ha come chiave il nome della lingua, sull'onblur
    updateLanguageRule(rule.id, data).then(() => setWriting(false))
  }

  const handleTagIT = (event) => {
    setTagIT(event.target.value)
  }

  const handleTagEN = (event) => {
    setWriting(true)
    setTagEN(event.target.value)
  }

  return (
    <div
      className="d-flex align-items-center pb-3"
    >
      <div style={{ width: `${badgeWidth}px` }}>
        <Button
          variant={rule.report_id ? 'danger' : 'success'}
          title={rule.report_id ? 'Regola custom' : 'Regola salvata'}
        />
      </div>
      <div style={{ width: `${tagWidth}px` }}>
        <fieldset className="w-seo-control-field">
          <Form.Control
            type="text"
            title={tagIT}
            value={tagIT}
            disabled={!editTagIT}
            className="form-control font-weight-bold"
            placeholder="IT"
            onChange={handleTagIT}
            onBlur={updateRule}
          />
        </fieldset>
      </div>
      <div style={{ width: `${tagWidth}px` }}>
        <fieldset className="w-seo-control-field">
          <Form.Control
            type="text"
            title={tagEN}
            value={tagEN}
            disabled={!editTagEN}
            className="form-control font-weight-bold"
            placeholder="EN"
            onChange={handleTagEN}
            onBlur={handleBlur}
          />
        </fieldset>
      </div>
      <div style={{ width: `${tagWidth}px` }}>
        {wrinting && "✏️Modifica in corso..."}
      </div>
    </div>
  )
}