import React from 'react'
import KeywordIdeasForm from './KeywordIdeasForm'
import { BASE_URL } from '../../utils/index'
import SetTitle from '../../utils/set_title'

export default function KeywordIdeas() {
	SetTitle('Keyword Ideas')
	return (
        <>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="fa-solid fa-lightbulb fa-xl" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">
						Keyword Ideas
						<a href={BASE_URL + '/docs/ranking_tools/keyword-ideas/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>
					</h1>
				</div>
			</div>
			<div className="page-description">Una volta caricato il file
			viene ritornato alla mail specificata
			il report finale. Questo report contiene, 
			per ogni keyword fornita una lista di keyword suggerite 
			(ideas) con il loro relativo volume complessivo e il volume medio dei 
			mesi dell'ultimo anno (relativo a google.com)</div>
			<KeywordIdeasForm />
        </>
	)
}
