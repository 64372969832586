import React from 'react'
import DuplicateKeywordsGptForm from './DuplicateKeywordsGptForm'
import { BASE_URL } from '../../utils/index'
import SetTitle from '../../utils/set_title'

export default function DuplicateKeywordsGpt() {
	SetTitle('Duplicate Keyword GPT')
	return (
        <>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="ni ni-key-25 text-white" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">
						Duplicate Keyword GPT
						<a href={BASE_URL + '/docs/ranking_tools/duplicate-keywords-gpt/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>
					</h1>
				</div>
			</div>
			<div className="page-description">
			Una volta caricato il file, su inn-ranking.Inn_duplicati di Bigquery 
			verrà caricato il report con le keywords duplicate.
			Per duplicate si intende, parole simili o insiemi di parole simili,
			con lo stesso volume. (Esempio: bad e bed, salotto vintage e salotti
			vintage).
			</div>
			
			<DuplicateKeywordsGptForm/>
        </>
	)
}