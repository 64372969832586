import React from 'react'
import ScheduledStudiokwDetailList from './ScheduledStudiokwDetailList'
import SetTitle from '../../utils/set_title'

export default function ScheduledStudiokwDetail() {
	SetTitle('Scheduling')
	return (
        <>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="fa-solid fa-chart-line fa-xl" style={{color: "white", fontSize: "35px"}}/>
					<h1 className="page-title">Scheduling</h1>
				</div>
			</div>
			<ScheduledStudiokwDetailList />
        </>
	)
}