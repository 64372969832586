import React, { Component } from 'react'
import PageTitle from '../../components/PageTitle'
import { api } from '../../../utils/index'
import SeoConfiguration from './SeoConfiguration'
import SeoPreview from './SeoPreview'
import Loading from '../../components/Loading'
import DimensionLinking from './DimensionLinking'
import MetricLinking from './MetricLinking'
import {getDimensionGroups} from "../../api_methods";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import EmptyTranslations from "../TranslationUtility/EmptyTranslations";
import UnsavedRules from "./UnsavedRules/UnsavedRules";
import UserHeader from "../../../components/Headers/ReportingHeader";
import {loginRedirect} from "../../../utils/index_tagging_tool";

class SeoReport extends Component {
  constructor(props) {
    super(props)

    this.cardWidth = 9

    this.state = {
      reportId: props.match.params.id,
      report: null,
      savedDimensions: [],
      dimensionGroups: [],
      savedMetrics: [],
      metricGroup: []
    }
  }

  componentDidMount() {
    // Tiro giù il report con tutte le sue dimensioni
    api.get(`/tagging-tool/reports/${this.state.reportId}/`)
      .then(loginRedirect)
      .then((data) => this.setState({ report: data }))

    // Le saved dimension che mi servono nella select del linking
    api.get(`/tagging-tool/saved-dimensions/`)
      .then(loginRedirect)
      .then((data) => this.setState({ savedDimensions: data }))

    // E i gruppi di dimensioni, che mi servono per associare i gruppi al report
    getDimensionGroups().then((data) => this.setState({ dimensionGroups: data }))

    // Le saved metric che mi servono nella select del linking
    api.get(`/tagging-tool/saved-metrics/`)
      .then(loginRedirect)
      .then((data) => this.setState({ savedMetrics: data }))
  }

  render() {
    const updateReport = (report) => {
      this.setState({ report: report })
    }

    if (this.state.report != null)
      return (
        <>
          <UserHeader/>
          <Row className="justify-content-center AppTaggingTool">
            <Col className="mb-20" style={{marginBottom: '100px'}} xs={this.cardWidth}>
              <Card className="shadow bg-secondary">
                <CardHeader className="bg-white">
                  <PageTitle
                    title={this.state.reportId + ' - ' + this.state.report.name + ' - ' + this.state.report.status}
                    link={`/reporting/`}
                    linkText={'Torna alla lista dei report'}
                  />
                </CardHeader>
                <CardBody className="ml-5 mr-5">
                  {/* Blocco iniziale che consente di modificare la configurazione del report, e di esegure la run */}
                  <div className="section mt-5">
                    <SeoConfiguration
                      reportId={this.state.report.id}
                      reportFile={this.state.report.input_options.path}
                      reportClient={this.state.report.input_options.client || ''}
                      reportLanguage={
                        this.state.report.input_options.language_code || ''
                      }
                      reportLingua={this.state.report.input_options.language || ''}
                      reportKw={this.state.report.input_options.kw_column}
                      reportVol={this.state.report.input_options.vol_column}
                      reportOutput={this.state.report.output_options}
                      reportName={this.state.report.name}
                      reportGroups={this.state.report.groups}
                      groupOptions={this.state.dimensionGroups.map((d) => {
                        return { label: d.name, value: d.name }
                      })}
                      updateReport={updateReport}
                      seo={this.state.report.seo}
                    />
                  </div>
                  <div className="section mt-2">
                    {/* Blocco intermedio per la gestione delle dimensioni del report (aggiunta/eliminazione, modifica del nome/tipo/thanos */}
                    <DimensionLinking
                      dimensions={this.state.report.custom_dimensions}
                      // Filtro le dimensioni salvate
                      savedDimensions={this.state.savedDimensions.filter((d) =>
                        this.state.report.groups
                          .map((g) => g.name)
                          .includes(d.group.name)
                      )}
                      reportName={this.state.report.name}
                      seo={true}
                    />
                  </div>

                  <div className="section mt-2">
                     <MetricLinking
                        metrics={this.state.report.custom_metrics}
                        savedMetrics={this.state.savedMetrics}
                        reportName={this.state.report.name}
                        seo={true}
                      />
                  </div>

                  <div className="section">
                    <h2>Stato del report</h2>
                    <p className="mb-3">
                      In questa sezione sono presenti funzionalità per osservare lo stato di alcune caratteristiche
                      del report. All'interno ogni sottosezione si potrà trovare una spiegazione dettagliata.
                    </p>
                    <EmptyTranslations identifier={this.state.report.id} info={this.state.report} group={false}/>
                    <UnsavedRules identifier={this.state.report.id} info={this.state.report}/>
                  </div>

                  <div className="section mt-5">
                    {/* Blocco finale per la simulazione del report */}
                    <SeoPreview
                      reportId={this.state.report.id}
                      reportName={this.state.report.name}
                      dimensions={this.state.report.custom_dimensions}
                      ignoredColumns={
                        this.state.report.input_options.ignore_for_simulation
                      }
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )
    return (
      <Row className="justify-content-center">
        <Col className="mb-20" style={{marginBottom: '100px'}} xs={this.cardWidth}>
          <Card className="shadow">
            <Loading child={null}></Loading>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default SeoReport
