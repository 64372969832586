import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import DetailStats from '../DetailReport/DetailStats'

// COMPONENETE VECCHIO: pensato per simulare una regole (vedere a quante righe veniva applicato). Non l'hanno più chiesto e scala quanto mia Nonna col bastone quindi lo lascerei morto
export default function SimulateRules({ rule, id }) {
  return (
    <div className="Content-box">
      <Row className={'m-auto'}>
        <Col xs={12} className={'m-auto pb-3'}>
          <h4>Statistiche regola</h4>
        </Col>
      </Row>
      <DetailStats rule={rule} id={id} />
    </div>
  )
}
