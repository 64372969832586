import React, { useEffect, useState, Fragment } from 'react'
import PageTitle from '../../components/PageTitle'
import { prefix, loginRedirect } from '../../../utils/index_tagging_tool'
import { api } from '../../../utils/index'
import Loading from '../../components/Loading'
import DimensionGroupListing from './DimensionGroupListing'
import DimensionSetListing from './DimensionSetListing'
import _ from 'lodash'
import {getDimensionGroups} from "../../api_methods";
import {Card, CardHeader, Col, Row} from "reactstrap";
import UserHeader from "../../../components/Headers/ReportingHeader";
import SetTitle from '../../../utils/set_title'

// Componente padre della pagina di gestione gruppi
function DimensionGroups() {
  SetTitle('Tagging tool - Gestione dei Gruppi di Dimensioni Salvate')
  // Mantiene le dimensioni salvate (tutte) prese da backend
  const [savedDimensions, setSavedDimensions] = useState([])
  // Mantiene i gruppi (tutti) presi da backend
  const [dimensionGroups, setDimensionGroups] = useState([])
  const [groupsData, setGroupsData] = useState([])

  useEffect(() => {
    // Al caricamento recupero tutto da backend
    api.get(prefix + `saved-dimensions/`)
      .then(loginRedirect)
      .then((data) =>
        setSavedDimensions(
          _.orderBy(data, ['group.name', 'name'], ['asc', 'asc'])
        )
      )

    getDimensionGroups()
      .then((data) => {setDimensionGroups(data.map((x) => x.name)); setGroupsData(data)})
  }, [])

  return (
    <>
      <UserHeader/>
      {savedDimensions && dimensionGroups ? (
        <Row style={{marginBottom: "100px"}} className="justify-content-center AppTaggingTool">
          <Col xs={8}>
            <Card className="shadow bg-secondary">
              <CardHeader className="bg-white">
                <PageTitle
                  title={'Gestione dei Gruppi di Dimensioni Salvate'}
                  subtitle={<p>
                    Da qui è possibile gestire le dimensioni salvate, raccolte all'interno di gruppi.<br/>
                    <b>Il nome di ogni dimensione è un link</b> che
                    consente di accedere alla relativa pagina di modifica/dettaglio.<br/><br/>
                    Un gruppo può essere replicato in <b>sotto gruppi adibiti a ospitare
                    regole in altri linguaggi</b>. Se si effettuano
                    modifiche al tag di una regola in uno dei gruppi collegati,
                    tale operazione sarà propagata a tutti gli altri.
                  </p>}
                  linkdocs={'/docs/documentazione_seo/report-dimensioni-e-regole/'}
                />
              </CardHeader>
              <DimensionGroupListing
                savedDimensions={savedDimensions}
                groups={dimensionGroups}
                groupsData={groupsData}
              />
              <DimensionSetListing
                savedDimensions={savedDimensions}
                groups={dimensionGroups}
              />

            </Card>
          </Col>
        </Row>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default DimensionGroups
