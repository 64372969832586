import React, { useContext, useState } from 'react'
import {Modal, Form, Row, Col, Button, Badge} from 'react-bootstrap'
import {
  prefix,
  loginRedirect,
  csrftoken,
  notify,
} from '../../../../utils/index_tagging_tool'
import {
  api,
} from '../../../../utils/index'

function MoveModal({
  checkedRules,
  otherDimensions,
  showModal,
  setShowModal,
  setLoading,
  saved=false
}) {
  const [selectedDim, setSelectedDim] = useState(null)

  const handleSelect = (e) => {
    e.target.value !== '' && setSelectedDim(Number(e.target.value))
    e.target.value === '' && setSelectedDim(null)
  }

  const moveRule = async (rule, i, max) => {
    return api.post(`${prefix}move-rule/`, { rule: rule.id, dimension: selectedDim, saved: saved })
  }

  const moveRules = async (e) => {
    e.preventDefault()
    setShowModal(false)
    setLoading(true)
    const ruleList = JSON.parse(JSON.stringify(checkedRules))
    await Promise.all(
      ruleList.map(async (rule) => {
        try {
          const response = await moveRule(rule)
          loginRedirect(response)
        } catch (e) {
          notify(e.message, 'error')
        }
      })
    )
    window.location.reload()
  }

  return (
    <Modal
      backdrop="static"
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h4>Sposta una regola</h4>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={() => {}}>
          <Row>
            <Col xs={12} className={'pb-3'}>
              {saved ?
                'Seleziona una dimensione del gruppo in cui spostare la regola/le regole' :
                'Seleziona una dimensione del report in cui spostare la regola/le regole. ' +
                'In questa pagina lo spostamento delle regole influenza solamente il report, ' +
                'le regole salvate (verdi) saranno solamente copiate nella destinazione e disattivate qua'
              }
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={'pb-3'}>
              <label>Destinazione</label>
              <select
                className="form-control"
                required
                onChange={(e) => handleSelect(e)}
              >
                <option value={null}></option>
                {otherDimensions.map((d) => (
                  <option value={d.id}>{d.name}</option>
                ))}
              </select>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setShowModal(false)}>
          Chiudi
        </Button>
        <Button variant="success" disabled={!selectedDim} onClick={moveRules}>
          Sposta
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export function MoveOperation({ context }) {
  const { operationsPossible, checkedRules, otherDimensions, setLoading, dimensionType } =
    useContext(context)
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      {operationsPossible.includes('Sposta') && (
        <div className={'operationsDiv'} onClick={() => setShowModal(true)}>
          Sposta
        </div>
      )}
      <MoveModal
        key='move-modal'
        checkedRules={checkedRules}
        showModal={showModal}
        otherDimensions={otherDimensions}
        setShowModal={setShowModal}
        setLoading={setLoading}
        saved={dimensionType === 'saved-dimensions'}
      />
    </>
  )
}
