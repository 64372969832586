import React from 'react'
import { Button } from 'react-bootstrap'

const WButton = ({ label, variant, onClick, icon, className}) => {
  return (
    <Button variant={variant} onClick={onClick} className={className}>
      {icon && icon} {label}
    </Button>
  )
}

export default WButton
