import React, { useState, useContext } from 'react'
import { Form, Col } from 'react-bootstrap'
import { useFetch } from '../../hooks'
import { FormSelectFilter } from '../../components/molecules'
import { WButton } from '../../components/atoms'
import { WSection } from '../WSection'
import {
	Badge,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Button,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Media,
	Pagination,
	PaginationItem,
	PaginationLink,
	Progress,
	Table,
	Container,
	Row,
	UncontrolledTooltip
} from "reactstrap";
import { volumeReportFilterDataContext } from '../../HOC/volumeReportFiltersContext'

function VolumeFilterForm() {
	const [brand, setBrand] = useState('')
	const [locale, setLocale] = useState('')

	const brands = useFetch({ url: 'volume-report-scheduled/brands/' })

	const countries = useFetch({ url: 'volume-report-scheduled/countries/' })

	const [, , getFilteredData, , actualPage, setActualPage] = useContext(volumeReportFilterDataContext)

	const handleClearFilters = () => {
		setActualPage(1)
		setBrand('')
		setLocale('')
		getFilteredData({ page_size: 10, page: 1 })
	}

	const handleApplyFilters = () => {
		setActualPage(1)
		let params = {
			...(brand.length > 0 && { brand: brand }),
			...(locale.length > 0 && { locale: locale }),
			page_size: 10,
			page: 1,
		}
		getFilteredData(params)
	}

	return (
	<>
		{brands && countries && (
		<div className="pb-8 pt-5 pt-md-8">
			<Row>
			<div className="col">
				<Card className="shadow">
					<CardHeader className="border-0">
						<h1 className="mb-0">Filters</h1>
					</CardHeader>
					<CardBody className="pt-0">
					<Form>
							<Row className="justify-content-center" style={{marginLeft:"80px", marginRight:"80px"}}>
								<Col>
									<FormSelectFilter
										label={'Brand'}
										value={brand}
										onChange={setBrand}
										data={brands.data}
									/>
								</Col>
								<Col>
									<FormSelectFilter
										label={'Country'}
										value={locale}
										onChange={setLocale}
										data={countries.data}
									/>
								</Col>
							</Row>
						</Form>
					<Row style={{display: "flex", justifyContent: "flex-end", marginRight: "92px"}}>
							<WButton label="Clear Filters" variant="light" onClick={handleClearFilters} />
							<WButton label="Apply" variant="primary" onClick={handleApplyFilters} />
					</Row>
					</CardBody>
					</Card>
					</div>
					</Row>
				</div>
		)}
		</>
	)
}
export default VolumeFilterForm
