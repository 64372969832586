import React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

import App from './App'
import Callback from './components/Callback/Callback'

import history from './history'
import auth from './auth/service'
// TODO: problema css, quello del tagging tool importandolo qua va a sovrascrivere quello di ranking
import AppTaggingTool from './AppTaggingTool'

const queryString = require('query-string')

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    const { referrer } = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    })
    auth.handleAuthentication().then(() => history.replace(referrer))
  }
}

export const makeMainRoutes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/login"
          render={(props) => {
            let referrer = ''
            if (Object.keys(props.location.state).includes('referrer'))
              referrer = props.location.state.referrer

            auth.login(referrer)
            return null
          }}
        />
        <Route
          path="/callback"
          render={(props) => {
            handleAuthentication(props)
            return <Callback {...props} />
          }}
        />
        <Route
          path="/"
          render={(props) => {
            //if (process.env.NODE_ENV === 'production') {
            return !auth.isAuthenticated() ? (
              <Redirect
                to={{
                  pathname: '/login',
                  state: {
                    referrer: props.location.pathname + props.location.search,
                  },
                }}
              />
            ) : (
              <App {...props} />
            )
            //} else {
            //  return <App {...props} />
            //}
          }}
        />
        <Route
          path="/"
          render={(props) => { 
            return <Redirect
              to={{
                pathname: '/',
                state: {
                  referrer: props.location.pathname + props.location.search,
                },
              }}
            />
            }}
          />
        {/*<Route
          path="/reporting"
          render={props => {
            return !auth.isAuthenticated() ? (
              <Redirect
                to={{
                  pathname: '/login',
                  state: {
                    referrer: props.location.pathname + props.location.search
                  }
                }}
              />
            ) : (
              <AppTaggingTool {...props} />
            )
          }}
        />*/}
      </Switch>
    </Router>
  )
}
