import React, { Fragment } from 'react'
import {Row, Col, Form, Input} from 'reactstrap'
import Select from 'react-select'

// Componete che mira a modificare parametri direttamente campi del modello Report, quali nome e gruppi associati
export default function SettingsBox({
  nome,
  setNome,
  nomeError,
  setNomeError,
  gruppi,
  gruppiOptions,
  setGruppi,
  lingua,
  setLingua,
  ratio
}) {
  const lingue = ['Italiano', 'Inglese']

  return (
    <Fragment>
      <h3>Settings</h3>
      <hr />
      <Row className="mb-4">
        <Col xs={ratio.title}>
          <label className={'vertical'}>Nome</label>
        </Col>
        <Col xs={ratio.form}>
          <Input
            className="form-control-alternative"
            type="text"
            id="name"
            value={nome}
            isInvalid={nomeError}
            placeholder="Inserisci nome"
            onChange={(e) => {
              setNomeError(false)
              setNome(e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={ratio.title}>
          <label className={'vertical'}>Gruppi</label>
        </Col>
        <Col xs={ratio.form}>
          <Select
            options={gruppiOptions}
            placeholder={'Seleziona un gruppo'}
            className="single-select"
            classNamePrefix="react-select"
            value={
              // Per dare i gruppi in pasto alla react select, li mappo in oggetti del tipo { label, value }
              gruppi.map((g) => {
                return { label: g.name, value: g.name }
              })
            }
            onChange={(e) => {
              if (e.length === 0) setGruppi([])
              else
                setGruppi(
                  e.map((g) => {
                    return { name: g.value }
                  })
                )
            }}
            isMulti={true}
            isSearchable={true}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={ratio.title}>
          <label className={'vertical'}>Lingua Tagging</label>
        </Col>
        <Col xs={ratio.form}>
          <Select
            options={lingue.map((g) => {
              return { label: g, value: g }
            })}
            placeholder={'Seleziona una lingua'}
            className="single-select"
            classNamePrefix="react-select"
            value={
              // Per dare le lingue in pasto alla react select, li mappo in oggetti del tipo { label, value }
              { label: lingua || lingue[0], value: lingua || lingue[0] }
            }
            onChange={(e) => {
              setLingua(e.value)
            }}
            isMulti={false}
            isSearchable={false}
          />
        </Col>
      </Row>
    </Fragment>
  )
}
