import React, { useState } from 'react'
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody, CardHeader
} from 'reactstrap'
import {
  notify,
} from '../../../utils/index_tagging_tool'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/fontawesome-free-solid'
import {addLanguageGroup} from "../../api_methods";
import {Card} from "reactstrap";



export default function AddSubGroup({name}) {
  const [countryCode, setCountryCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [collapse, setCollapse] = useState(true)

  const newLanguageGroup = (e) => {
    e.preventDefault()

    const answer = window.confirm(
      'Sei sicuro/a? Verrà creato un nuovo gruppo che replicherà tutte le regole e le dimensioni di ' + name + ', ' +
      'se non serve davvero abbi pietà del database'
    )
    if (!answer) return

    setLoading(true)

    let data = {'language': countryCode}

    addLanguageGroup(name, data)
      .then(() => setLoading(false))
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
        setLoading(false)
      })
  }

  return (
    <Card className="mt-3">
        <CardHeader className={collapse ? '' : 'pb-3'}>
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="mb-0 ml-2">Aggiungi nuovo linguaggio</h2>
            {/* Messaggio intimidatorio per l'average Lucio che fa dei pocci con questa funzionalità */}
            <button
              type="button"
              className="p-2 ml-4"
              onClick={() => setCollapse(!collapse)}
            >
              <FontAwesomeIcon icon={collapse ? faChevronDown : faChevronUp} />
            </button>
          </div>
        </CardHeader>
        {!collapse && (
          <>
            <Form onSubmit={newLanguageGroup}>
              <Row>
                <Col xs={6} className={'mb-3 ml-3'}>
                  <label>Codice Paese</label>
                </Col>
              </Row>
              <Row>
                {/* Input to insert country code */}
                <Col xs={6} className={'mb-3 ml-3'}>
                  <Input
                    id="country-code-input"
                    type="text"
                    className="form-control"
                    maxLength={2}
                    placeholder="es. EN"
                    required
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                  />
                  <UncontrolledPopover
                    placement="left"
                    target="country-code-input"
                    trigger="focus"
                  >
                    <PopoverHeader>
                      Inserisci un country code da due caratteri
                    </PopoverHeader>
                    <PopoverBody>
                      Verrà utilizzato per creare il nome del nuovo gruppo: <br/>
                      Es. EN, {name}-EN
                    </PopoverBody>
                  </UncontrolledPopover>
                </Col>
                {/* Save button */}
                <Col xs={4} className={'mb-3 '}>
                  {loading ? (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <Button color="success">
                      Salva
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </>
        )}

    </Card>
  )
}
