import React, { Fragment, useState } from 'react'
import { Form } from 'react-bootstrap'
import {
  prefix,
  loginRedirect,
  csrftoken,
  notify,
} from '../../../utils/index_tagging_tool'
import {
  api
} from '../../../utils/index'

// Riga che gestisce le regole dipendenti (un selettore per ogni colonna corrispondente ad un'altra dimensione del report/gruppo)
export default function DetailSeoDependencyRow({
  dimension,
  report,
  disabled, // Può essere disabilitato, quando la regola è al momento non valida (quindi se lasciassi modificabile il selettore il backend mi farebbe ciao ciao con la manina)
  rules,
  index,
  options, // le opzioni sono i valori delle regole manual delle altre dimensioni
  valueList, // la lista di valori è una lista di valori delle regole dipendenti di QUESTA dimenisone, che recupero da backend e devo mostrare a frontend
  name,
  type,
  group,
  ratio,
}) {
  const [selected, setSelected] = useState(
    // Se ci sono elementi, prendo l'ultimo. Questo perchè le regole dipendenti custom sono passate "in fondo" dal backend, e hanno priorità
    valueList.length > 0 ? valueList[valueList.length - 1].rule.value : ''
  )

  // Do feedback all'utente colorando di rosso o verde il selettore
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  // Porto le modifiche a backend, ad ogni onChange, selezionando un gruppo o un report (uno dei due sarà vuoto, controllo a backend quale)
  const updateSelected = (e) => {
    e.preventDefault()
    setError(false)
    setSuccess(false)

    const newVal = e.target.value
    const body = {}
    body[name] = newVal

    const data = {}
    data['body'] = body
    data['headers'] = {
      group: group,
      id: report,
    }

    api.put(`${prefix}rule-dependency/${rules[index].id}/`, data)
      .then(loginRedirect)
      .then((data) => {
        setSelected(newVal)
        setSuccess(true)

        // Aggiorno le regole per evitare problemi quando vengono nascoste/rirenderizzate
        const i = dimension.rules.map((r) => r.id).indexOf(data.id)
        // Se trovo il valore, lo aggiorno
        if (i !== -1) {
          // Se è stato cancellato, cancello la regola
          if (newVal === '') dimension.rules.splice(i, 1)
          else dimension.rules[i] = data
          return
        }
        // Altrimenti la aggiungo
        dimension.rules = dimension.rules.concat([data])
      })
      .catch((e) => {
        notify(e.message, 'error')
        setError(true)
      })
  }

  return (
    <Fragment>
      <div style={{ width: ratio.dependency }}>
        <fieldset className="w-seo-control-field">
          <Form.Control
            className="w-form-control"
            as="select"
            value={selected}
            isValid={success}
            isInvalid={error}
            onChange={updateSelected}
            disabled={disabled}
          >
            <option value={''}>{}</option>
            {options &&
              options.map((o, index) => (
                <option key={index} value={o.rule.value}>
                  {o.rule.value}
                </option>
              ))}
          </Form.Control>
        </fieldset>
      </div>
    </Fragment>
  )
}
