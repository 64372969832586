import React from 'react'
import AlertEventDetailTable from './AlertEventDetailTable'
import { FilterDataProvider } from '../../HOC/alertsFiltersContext'
import { AlertEventDetailFiltersForm } from './AlertEventDetailFiltersForm'
import SetTitle from '../../utils/set_title'

export default function AlertEventsDetail() {
  SetTitle('Seo Monitoring Alerts')
	return (
    <>
      <div className="bg-gradient-info secondary-page-title" >
        <div md={12} className="form-title">
          <i className="ni ni-compass-04 text-white" style={{color: "white", fontSize: "35px"}}></i>
          <h1 className="page-title">Seo Monitoring Alerts</h1>
        </div>
      </div>
      <div className="page-description">
        Dettaglio degli alerts salvati su bigquery.
      </div>
      <FilterDataProvider>
        <AlertEventDetailFiltersForm />
        <AlertEventDetailTable />
      </FilterDataProvider>
    </>
	)
}