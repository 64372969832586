import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const CharCheckModal = ({ show, onHide, onSubmit, errCharList=[], withExclusion=false }) => {

  const title = (
    <h2 className="text-center w-100">
      {(withExclusion) ? "Attenzione: trovate keyword non valide" : "Sicuro di continuare?"}
    </h2>
  )
  const body = (
    <>
      {
        (withExclusion) ?
        <h4>Abbiamo riscontrato alcuni errori durante l'analisi delle keyword:</h4>
        : <h4 className="text-center">Sicuri di voler usare keyword non valide per Google Ads? <br/> Questi sono gli errori riscontrati nelle keyword</h4>
      }      
      <ul>
        {errCharList.map((el, elKey) => 
          (<li key={elKey}>{el}</li>)
        )}
      </ul>
      {
        (withExclusion) ? 
        <h4>
          Se scegli di continuare, le keyword non valide saranno escluse 
          dall'analisi e il processo verrà eseguito correttamente.
        </h4> : null
      }
    </>
  )

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title className="d-flex justify-content-center align-items-center w-100">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center flex-column">{body}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-center align-items-center">
        <Button variant="primary" onClick={onSubmit}>
          Sì, continua
        </Button>
        <Button variant="danger" onClick={() => onHide(false)}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CharCheckModal
